import axios from "axios";
import { authService } from "services";

class Api {
    #client;
    #refreshRequest = null;

    static get REFRESH_TOKEN_INVALID_ERROR_CODE() {
        return [8];
    }

    constructor() {
        this.#client = axios.create({
            baseURL: process.env.API_URL,
        });

        this.#client.interceptors.request.use(config => {
                if (!authService.accessToken) {
                    return config;
                }

                const newConfig = {
                    headers: {},
                    ...config
                };

                newConfig.headers.Authorization = `Bearer ${authService.accessToken}`;
                return newConfig
            },
            e => Promise.reject(e)
        );

        this.#client.interceptors.response.use(
            r => r,
            async error => {
                if (!authService.refreshToken || error.response.status !== 401 || error.config.retry) {
                    throw error;
                }

                if (!this.#refreshRequest) {
                    if (Api.REFRESH_TOKEN_INVALID_ERROR_CODE.includes(error.response.data.error.data.code)) {
                        authService.destroySession();
                        window.location.replace('/login');
                    }

                    let bodyFormData = new FormData();
                    bodyFormData.append('grant_type', 'refresh_token');
                    bodyFormData.append('refresh_token', `${authService.refreshToken}`);

                    try {

                        this.#refreshRequest = this.#client.post(`${process.env.REACT_APP_API_URL}/auth/login`, bodyFormData, {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        });

                        const { data } = await this.#refreshRequest;

                        const { access_token, refresh_token, expires_in } = data.data;
                        authService.saveInStorage(
                            access_token,
                            refresh_token,
                            expires_in,
                        );
                        authService.refreshToken = refresh_token;
                        authService.accessToken = access_token;
                        const newRequest = {
                            ...error.config,
                            retry: true,
                        };
                        this.#refreshRequest = null;
                        return this.#client(newRequest);
                    }
                    catch (error) {
                        console.error(error);
                    }


                }

            }
        )
    }

    async put(url, data) {
        try {
            const response = await this.#client.put(url, data, { headers: { 'Content-Type': 'application/json' } });
            const result = await response.data;
            if (result.statusCode === 200) {
                return result.data;
            } else {
                return Promise.reject(result);
            }
        } catch (e) {
            return Promise.reject(e.response.data);
        }

    }

    /**
     *
     * @param url
     * @param callback нужен для возврата пустого обьекта при ошибке 404
     * @returns {Promise<*>}
     */
    async get(url, callback) {

        try {
            const response = await this.#client.get(url, { headers: { 'Content-Type': 'application/json' } });
            const result = await response.data;
            if (result.statusCode === 200) {
                return result.data;
            } else {
                return Promise.reject(result);
            }
        } catch (error) {
            return !callback ? Promise.reject(error) : callback();
        }
    }


    async delete(url) {
        try {
            const response = await this.#client.delete(url, { headers: { 'Content-Type': 'application/json' } });
            const result = await response.data;
            if (result.statusCode === 200) {
                return result.data;
            } else {
                return Promise.reject(result);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }


    async download(url, data) {
        try {
            const response = await this.#client.post(url, data, {
                headers: { 'Content-Type': 'application/json;charset=utf-8', 'Accept': 'application/pdf' },
                responseType: 'blob',
            });

            if(response.status === 200) {
                const blob = await response.data;
                const filename = /filename="(.*)"/.exec(response.headers['content-disposition'])[1] || 'filename';
                return {
                    blob,
                    filename
                }
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }


    async post(url, data) {
        try {
            const response = await this.#client.post(url, data, { headers: { 'Content-Type': 'application/json' } });
            const result = await response.data;
            if (result.statusCode === 202 || result.statusCode === 201 || result.statusCode === 200) {
                return result.data;
            } else {
                return Promise.reject(result);
            }
        } catch (e) {
            return Promise.reject(e.response.data);
        }
    }

    refresh(refreshToken) {
        let bodyFormData = new FormData();
        bodyFormData.append('grant_type', 'refresh_token');
        bodyFormData.append('refresh_token', `${refreshToken}`);

        return this.#client.post(
            `${process.env.REACT_APP_API_URL}/auth/login`,
            bodyFormData,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            },
        ).then((result) => {
            return result.data;
        })
            .catch((error) => {
                return error;
            });
    }
}

const api = new Api();
export default api;
