import References from "services/References";
import uiStore from "stores/UiStore";
import { api } from "core";
import { BranchType, Location } from "models";

class BranchTypesService extends References {
    constructor() {
        super();
    }

    /**
     *
     * @returns {Promise<{items: *}>}
     */
    getPurchase = () => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/applications/purchases/types`)
            .then(result => {
                //TODO добавить модель
                return {
                    items: result.items.map((item) => item),
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }
    /**
     *
     * @returns {Promise<{items: *}>}
     */
    getApplication = () => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/applications/types`)
            .then(result => {
                //TODO добавить модель
                return {
                    items: result.items.map((item) => item),
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });

    }

    /**
     * @param {{}} data
     * @return {Promise<BranchType>}
     */
    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/company/types`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )

                return new Location(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });

    }

    /**
     * @param {int} id
     * @param {{}} data
     * @return {Promise<BranchType>}
     */
    saveEntity = (id, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/company/types/${id}`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                )

                return new Location(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });

    }

    /**
     * @param id {number}
     * @returns {Promise<BranchType>}
     */

    loadEntity = (id) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/types/${id}`)
            .then(result => {
                return new BranchType(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });

    }

    /**
     * @param {{}} filter
     * @returns {Promise<{items: BranchType[], pager: Object, sort: Object}>}
     */
    loadItems = (filter) => {
        const params = this.parseFilter(filter);

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/types?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new BranchType(item);
                    }),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });

    }
}

const branchTypesService = new BranchTypesService();
export default branchTypesService;
