import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";

import TitleH1 from "components/common/TitleH1";
import TitleH2 from "components/common/TitleH2";
import CustomizableLinkedSwitch from "../../../common/CustomizableLinkedSwitch";
import OkDropdown from "components/common/OkDropdown";
import OkInputText from "components/common/OkInputText";

/**
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {AdPlatformStore} adPlatformStore
 * @param {MainStore} mainStore
 * @param {AdPlatformTypeStore} adPlatformTypeStore
 * @return {JSX.Element}
 * @constructor
 */
const IntegrationsNewPage = ({
                                 uiStore,
                                 breadcrumbsStore,
                                 adPlatformStore,
                                 mainStore,
                                 adPlatformTypeStore,
                             }) => {
    const { clientId } = useParams();
    const history = useHistory();
    const { client } = mainStore;
    const {
        entity,
        setEntity,
        addEntity,
        resetEntity,
        addBranches,
        setAddBranchLinked,
        delAddBranchLinked,
    } = adPlatformStore;

    const [isValid, setIsValid] = useState(false);
    const [adPlatformType, setAdPlatformType] = useState([]);

    const save = () => {
        addEntity()
            .then(() => {
                history.push(`/clients/${clientId}/integrations/${adPlatformStore.entity.id}/detail`);
            })
            .catch(() => {});
    };

    useEffect(() => {
        resetEntity();
        setEntity({ clientId: clientId });

        adPlatformTypeStore.getAll().then(result => {
            setAdPlatformType(result.items)
        });

        adPlatformStore.loadAddBranches(clientId);
    }, []);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'integrations'
        });

        setIsValid((
            !!entity.type.code.length &&
            !!entity.title.length
        ));
    }, [entity]);


    useEffect(() => {
        if (client.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${clientId}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Интеграции',
                link: `/clients/${clientId}/integrations`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: 'Создание интеграции',
                link: `/clients/${clientId}/integrations/new`,
            }, 3);
        }
    }, [client])


    return (
        <div>

            <div className={cn('header-container')}>
                <TitleH1
                    ico={'chain_gradient'}
                >
                    &nbsp;
                </TitleH1>
            </div>

            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={() => {
                                    save();
                                }}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${clientId}/integrations`)
                                }}
                            />
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col', 'edit-form__col_large')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='name'
                                        name='name'
                                        type='text'
                                        value={entity.title}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="name">Название интеграции *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={entity.enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.value });
                                    }}
                                />
                                <span className={cn('switch__text')}>Активна</span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        value={entity.type.code}
                                        options={adPlatformType}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({
                                                type: {
                                                    ...entity.type,
                                                    ...{
                                                        code: e.value
                                                    },
                                                },
                                            });
                                        }}
                                        id="platform"
                                        name="platform"
                                        optionLabel="title"
                                        optionValue="code"
                                    />
                                    <label htmlFor="name">Площадка *</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col', 'edit-form__col_large')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='url'
                                        name='url'
                                        type='text'
                                        value={entity.accountUrl}
                                        onChange={(e) => {
                                            setEntity({ accountUrl: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="url">Страница на площадке</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cn('detail-container')}>
                <div className={cn('detail')}>

                    <div className={cn('table-container', 'branches')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={10}
                                color='color-lilac'
                                ico='house'
                            >
                                Филиалы / {client.title}
                            </TitleH2>
                        </div>

                        <div className={cn('buttons-container')}>
                            <Button
                                className={cn('p-button-outlined', 'color-lilac')}
                                label="Добавить филиал"
                                icon="pi pi-plus"
                                onClick={() => {
                                    history.push(`/clients/${clientId}/branches`);
                                }}
                            />
                        </div>

                        <table>
                            <thead>
                            <tr>
                                <th>Филиалы</th>
                                <th>Бренд филиала</th>
                                <th>Тип филиала</th>
                                <th>Связь с интеграцией</th>
                            </tr>
                            </thead>
                            <tbody>
                            {addBranches.map((branch, key) => (<tr key={key}>
                                <td>
                                    <Link to={`/clients/${branch.clientId}/branches/${branch.id}/detail`}>{branch.title}</Link>
                                </td>
                                <td>{branch.brand.title}</td>
                                <td>{branch.type.title}</td>
                                <td>
                                    <CustomizableLinkedSwitch
                                        initialLinked={false}
                                        item={branch}
                                        switchTextOn="Связаны"
                                        switchTextOff="Нет связи"
                                        changeHandler={(e) => {
                                            if (e.linked) {
                                                setAddBranchLinked(e.item.id);
                                            } else {
                                                delAddBranchLinked(e.item.id);
                                            }
                                        }}
                                    />
                                </td>
                            </tr>))}
                            </tbody>
                        </table>

                        <div className={cn('buttons-container', 'buttons-container__position_bottom')}>
                            <div className={cn('button-container')}>
                                <Button
                                    label="Сохранить изменения"
                                    icon="pi pi-save"
                                    onClick={() => {
                                        save();
                                    }}
                                    disabled={!isValid}
                                />
                            </div>
                            <div className={cn('button-container')}>
                                <Button
                                    className={cn('p-button-outlined')}
                                    label="Отмена"
                                    onClick={() => {
                                        history.push(`/clients/${clientId}/integrations`)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

IntegrationsNewPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    adPlatformStore: PropTypes.any,
    mainStore: PropTypes.any,
    adPlatformTypeStore: PropTypes.any,
}

export default inject(
    'uiStore',
    'breadcrumbsStore',
    'adPlatformStore',
    'mainStore',
    'adPlatformTypeStore'
)(observer(IntegrationsNewPage));
