import { api } from 'core';
import References from "services/References";
import { CompanyGroup } from "models";
import uiStore from "stores/UiStore";

class CompanyGroupsService extends References {
    constructor() {
        super();
    }

    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/company/groups`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )
                return new CompanyGroup(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    saveEntity = (id, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/company/groups/${id}`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                )
                return new CompanyGroup(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     *
     * @param id {number}
     * @returns {Promise<CompanyGroup>}
     */
    loadEntity = (id) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/groups/${id}`)
            .then(result => {
                return new CompanyGroup(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });

    }

    /**
     * @returns {Promise<{items: CompanyGroup[], pager: {}, sorting: {}}>}
     */
    loadItems = (filter) => {
        const params = this.parseFilter(filter);

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/groups?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new CompanyGroup(item);
                    }),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            })
    }
}

const companyGroupsService = new CompanyGroupsService();
export default companyGroupsService;

