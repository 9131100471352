//CompaniesEdit.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import OkInputText from "../../common/OkInputText";
import { InputSwitch } from "primereact/inputswitch";
import OkDropdown from "../../common/OkDropdown";

const CompaniesEditPage = ({ uiStore, breadcrumbsStore, companyStore, mainStore }) => {

    let history = useHistory();

    const { entity, setEntity } = companyStore;
    const { title } = entity;
    const { id } = useParams();
    const { client } = mainStore;

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'companies'
        });

        setIsValid(!!entity.title.length);

    }, [entity]);


    useEffect(() => {
        if (client.id && entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${client.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Компании',
                link: `/clients/${entity.id}/companies`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: entity.title,
                link: `/clients/${entity.clientId}/companies/${id}/detail`,
            }, 3);
            breadcrumbsStore.addItem({
                linkName: 'Редактирование',
                link: `/clients/${entity.clientId}/companies/${id}/edit`,
            }, 4);
        }
    }, [client, entity])

    useEffect(() => {
        companyStore.loadEntity(id);
    }, []);

    const save = () => {
        companyStore.saveEntity()
            .then(() => {
                history.push(`/clients/${companyStore.entity.clientId}/companies/${companyStore.entity.id}/detail`);
            })
    };

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'house2_gradient'}
                >
                    {title} / Редактирование
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={() => {
                                    save();
                                }}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${entity.clientId}/companies/${entity.id}/detail`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='name'
                                        name='name'
                                        type='text'
                                        isrequired="true"
                                        value={entity.title}
                                        onChange={(e) => {
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="name">Название компании *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={entity.enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.value })
                                    }}
                                />
                                <span className={cn('switch__text')}>Активен</span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        id='defaultApplication'
                                        name='defaultApplication'
                                        value={entity.type.code}
                                        options={[
                                            { title: 'Франшиза', code: 'franchise' },
                                        ]}
                                        optionLabel="title"
                                        optionValue="code"
                                        onChange={(e) => {
                                            setEntity({
                                                type: {
                                                    ...entity.type,
                                                    ...{
                                                        code: e.value
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                    <label htmlFor="defaultApplication">Тип</label>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


CompaniesEditPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    companyStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'companyStore', 'mainStore')(observer(CompaniesEditPage));