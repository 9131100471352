import uiStore from "stores/UiStore";
import { action, makeObservable, observable } from "mobx";
import analyticsService from "services/Analytics";

class AnalyticsStore {
    enabled = null;

    title = '';

    entity = {
        id: null,
        title: '',
        enabled: false,
    };

    feature = {
        extreview: 'disabled',
        analytics: 'disabled',
    };

    idle = {};

    pager = {
        size: 10,
        number: 1,
    }

    sort = {
        column: 'title',
        order: 'asc'
    };

    constructor() {
        makeObservable(this, {
            title: observable,
            pager: observable,
            sort: observable,
            feature: observable,

            setTitle: action,
            setPager: action,
            setSort: action,
            resetFilter: action,
        })
    }

    setTitle = (title) => {
        this.title = title || '';
    }

    setPager = (pager = {}) => {
        this.pager = {
            ...this.pager,
            ...pager,
        }
    }

    setSort = (sort) => {
        this.sort = sort || {};
    }

    resetFilter = () => {
        this.title = '';

        this.pager = {
            size: 10,
            number: 1,
        }

        this.sort = {
            column: 'title',
            order: 'asc'
        };
    }

}

class AnalyticsServicesStore extends AnalyticsStore {

    items = [];

    constructor() {
        super();

        makeObservable(this, {
            items: observable,
            setItems: action,
            setFeature: action,
        })
    }

    loadItems = () => {
        const filter = {
            pager: this.pager,
            title: this.title,
            enabled: this.enabled,
            feature: this.feature,
            sort: this.sort,
        }

        uiStore.startLoading();

        analyticsService
            .loadItems(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    setFeature = (feature, code) => {
        this.feature[code] = feature || '';
    }

    setItems = (items) => {
        this.items = items || [];
    }

}

class AnalyticsActivityStore extends AnalyticsStore {

    items = [];
    clients = [];

    constructor() {
        super();

        makeObservable(this, {
            items: observable,
            clients: observable,
            idle: observable,
            setItems: action,
            setClients: action,
            setIdle: action,
        })
    }

    loadItems = () => {
        const filter = {
            pager: this.pager,
            title: this.title,
            enabled: this.enabled,
            idle: this.idle,
            sort: this.sort,
        }

        uiStore.startLoading();

        analyticsService
            .loadClientActivity(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadClientUsers = (clientId) => {
        return analyticsService
            .loadClientUsers(clientId)
            .then((result) => {
                this.setClients(result?.items);
                return this.clients;
            });
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setClients = (clients) => {
        this.clients = clients || [];
    }

    setIdle = (idle) => {
        this.idle = idle || {};
    }


}

class AnalyticsParsingStore extends AnalyticsStore {
    items = [];
    branches = [];
    parse = {};

    enabled = 1;

    constructor() {
        super();

        makeObservable(this, {
            items: observable,
            branches: observable,
            parse: observable,
            setItems: action,
            setBranches: action,
            setParse: action,
        })
    }

    loadItems = () => {
        const filter = {
            title: this.title,
            enabled: this.enabled,
            parse: this.parse,
            sort: this.sort,
            pager: this.pager,
        }
        uiStore.startLoading();

        analyticsService
            .loadParsingData(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadClientBranches = (clientId) => {
        return analyticsService
            .loadClientBranches(clientId)
            .then((result) => {
                this.setBranches(result?.items);
                return result?.items;
            });
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setBranches = (clients) => {
        this.clients = clients || [];
    }

    setParse = (parse) => {
        this.parse = parse || {};
    }
}


const analyticsActivityStore = new AnalyticsActivityStore();
const analyticsServicesStore = new AnalyticsServicesStore();
const analyticsParsingStore = new AnalyticsParsingStore();

export {
    analyticsActivityStore,
    analyticsServicesStore,
    analyticsParsingStore,
}
