import _ from "underscore";

const serialize = (obj, prefix) => {
    let str = [],
        p;
    for (p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                //TODO self или => ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

/**
 * @param {BranchAddress|null} address
 * @return {string|null}
 */
const implodeAddress = (address) => {
    const result = [];

    if (address) {
        if (address.postcode) {
            result.push(address.postcode);
        }

        if (address.city) {
            result.push(address.city);
        }

        if (address.street) {
            result.push(address.street);
        }

        if (address.building) {
            result.push(address.building);
        }

        if (address.additional) {
            result.push(address.additional);
        }
    }

    return (result.length) ? result.join(', ') : '';
}

/**
 * @param {int} valueId
 * @param {int[]} linkArr
 * @param {int[]} delLinkArr
 * @return {int[]} new delLinkArr
 */
const setLinkedById = (valueId, linkArr, delLinkArr) => {
    if (!_.contains(linkArr, valueId)) {
        linkArr.push(valueId);
    }

    if (_.contains(delLinkArr, valueId)) {
        return _.without(
            delLinkArr,
            _.findWhere(
                delLinkArr,
                valueId,
            )
        );
    }

    return delLinkArr;
}

/**
 * @param {int} valueId
 * @param {int[]} linkArr
 * @param {int[]} delLinkArr
 * @return {int[]} new linkArr
 */
const unsetLinkedById = (valueId, linkArr, delLinkArr) => {
    if (!_.contains(delLinkArr, valueId)) {
        delLinkArr.push(valueId);
    }

    if (_.contains(linkArr, valueId)) {
        return _.without(
            linkArr,
            _.findWhere(
                linkArr,
                valueId,
            )
        );
    }

    return linkArr;
}

/**
 * @param {string} url
 * @return {boolean}
 */
const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        return false;
    }

    return true;
};

/**
 *
 * @param {string} href
 * @param {string} target
 * @returns {boolean}
 */
const linkTo = (href, target) => {
    if(!href) return false;

    let link = document.createElement("a");
    link.href = href;
    link.target = target;
    link.click()

    return true;
}

const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
}

export {
    serialize,
    implodeAddress,
    setLinkedById,
    unsetLinkedById,
    isValidUrl,
    linkTo,
    getRandomInt
};
