import React, { useEffect } from "react";
import cn from "classnames";

import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { InputSwitch } from "primereact/inputswitch";
import TitleH4 from "components/common/TitleH4";

const BlockReview = ({
                         adPlatformReviewStore,
                         integrationId = null,
                         disabled = false,
                         onSave = false,
                         active = true,
                     }) => {

    const { entity, setEntity } = adPlatformReviewStore;

    useEffect(() => {

        adPlatformReviewStore.resetEntity().then(() => {
            adPlatformReviewStore.loadEntity(integrationId);
        })

    }, []);

    useEffect(() => {
        !active && setEntity({
            connection: {
                ...entity.connection,
                ...{
                    enabled: active
                },
            },
        })
    },[active])

    useEffect(() => {
        (entity.connection.info.url.trim() && onSave) && save();
    },[onSave])


    const save = () => {
        if (entity.connection.info.type) {
            adPlatformReviewStore.saveEntity(integrationId);
        } else {
            adPlatformReviewStore.addEntity(integrationId);
        }
    }

    return (
        <>
            <div className={cn('edit-form__col', 'edit-form__col_large')}>
                <TitleH4
                    ico={'chain_gradient'}
                    showBadge={disabled}
                    active={entity?.connection.enabled}
                >
                    Функционал проактивного сбора отзывов
                </TitleH4>
                <div className={cn("p-field", 'pt-0')}>
                    <span className="p-float-label">
                        <InputText
                            id='reviewUrl'
                            name='reviewUrl'
                            type='text'
                            value={entity?.connection.info.url}
                            disabled={disabled}
                            onChange={(e) => {
                                setEntity({
                                    connection: {
                                        ...entity.connection,
                                        ...{
                                            info: {
                                                ...entity.connection.info,
                                                ...{ url: e.target.value }
                                            }
                                        },
                                    },
                                });
                            }}
                        />
                        <label htmlFor="url">Ссылка на страницу сбора отзывов</label>
                    </span>
                </div>
            </div>
            {!disabled && <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                <div className={cn('switch__wrapper')}>
                    <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                        <div className={cn('switch', 'switch-activity')}>
                            <InputSwitch
                                checked={entity?.connection.enabled}
                                disabled={!active}
                                onChange={(e) => {
                                    setEntity({
                                        connection: {
                                            ...entity.connection,
                                            ...{
                                                enabled: e.value
                                            },
                                        },
                                    })
                                }}
                            />
                            <span className={cn('switch__text', { 'disabled': !active })}
                            >{entity.connection.enabled ? 'Активен' : 'Не активeн'}</span>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}

BlockReview.propTypes = {
    adPlatformReviewStore: PropTypes.any,
    integrationId: PropTypes.any,
    disabled: PropTypes.bool,
    onSave: PropTypes.any,
    active: PropTypes.bool,
}

export default inject(
    'adPlatformReviewStore',
)(observer(BlockReview));

