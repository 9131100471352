import React, { useEffect } from "react";
import cn from "classnames";
import TitleH1 from "components/common/TitleH1";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import OkDropdown from "components/common/OkDropdown";

const BranchTypesDetailPage = ({ uiStore, breadcrumbsStore, branchTypeStore }) => {

    let history = useHistory();

    const { entity } = branchTypeStore;
    const { title, enabled, defaultPurchase, defaultApplication } = entity;
    const { id } = useParams();

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'references',
            className: 'branch-types'
        });

        breadcrumbsStore.addItem({
            linkName: 'Типы филиалов',
            link: '/references/branch-types',
        }, 0);

        breadcrumbsStore.addItem({
            linkName: title,
            link: `/references/branch-types/${entity.id}/detail`,
        }, 1);

    }, [entity]);

    useEffect(() => {
        branchTypeStore.loadEntity(id);
        branchTypeStore.getPurchase();
        branchTypeStore.getApplication();
    }, []);

    /** @namespace item.brand */
    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    active={enabled}
                    showBadge={true}
                    showInfo={false}
                >
                    {title}
                </TitleH1>
            </div>

            {entity && <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Редактировать"
                                icon="pi pi-pencil"
                                onClick={() => {
                                    history.push(`/references/branch-types/${entity.id}/edit`);
                                }}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Вернуться к списку"
                                onClick={() => {
                                    history.push(`/references/branch-types`);
                                }}
                            />
                        </div>

                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='title'
                                        name='title'
                                        type='text'
                                        value={title}
                                        disabled
                                    />
                                    <label htmlFor="name">Значение справочника</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        id='defaultPurchase'
                                        name='defaultPurchase'
                                        value={defaultPurchase}
                                        options={branchTypeStore.defaultPurchase}
                                        optionLabel="name"
                                        optionValue="code"
                                        disabled
                                    />
                                    <label htmlFor="defaultPurchase">Тип сделки *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        id='defaultApplication'
                                        name='defaultApplication'
                                        value={defaultApplication}
                                        options={branchTypeStore.defaultApplication}
                                        optionLabel="name"
                                        optionValue="code"
                                        disabled
                                    />
                                    <label htmlFor="defaultApplication">Тип заявки *</label>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};

BranchTypesDetailPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    branchTypeStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'branchTypeStore')(observer(BranchTypesDetailPage));
