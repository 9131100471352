import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

import 'styles/header/profile.scss';

const Profile = ({ authStore }) => {

    const domNode = useRef();
    const [openPlate, setOpenPlate] = useState(false);

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler);
        return () => {
            document.removeEventListener('click', clickOutsideHandler);
        }
    }, [])

    const clickOutsideHandler = (event) => {
        if (!domNode.current?.contains(event.target)) {
            setOpenPlate(false)
        }
    }

    const logout = () => {
        authStore.logout();
    }

    return (
        <div
            className={cn('profile', { active: openPlate })}
            ref={domNode}
        >
            <div
                className={cn('profile__user-block')}
                onClick={() => setOpenPlate(!openPlate)}
            >
                <div className={cn('profile__ico', 'avatar', 'avatar__black', 'ico')} />
                <div className={cn('profile__user-name')}>Администратор</div>
            </div>
            <div className={cn('profile__separator')} />
            <ul>
                <li
                    onClick={logout}
                >
                    Выход
                </li>
            </ul>
        </div>
    );
}

Profile.propTypes = {
    authStore: PropTypes.any,
}

export default inject('authStore')(observer(Profile));
