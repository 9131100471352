import moment from "moment/moment";

class ClientParsing {
    #id;
    #title;
    #enabled;
    #metrics;
    #actions;

    /**
     *
     * @param {Object} clientParsing
     */
    constructor(clientParsing = {}) {
        this.#id = clientParsing.id || null;
        this.#title = clientParsing.title || '';
        this.#enabled = clientParsing.enabled || false;
        this.#metrics = new ClientParsingMetric(clientParsing.metrics || {});
    }

    /**
     *
     * @return {Number|null}
     */
    get id() {
        return this.#id;
    }

    /**
     *
     * @returns {String}
     */
    get title() {
        return this.#title;
    }

    /**
     *
     * @returns {Boolean}
     */
    get enabled() {
        return this.#enabled;
    }

    /**
     *
     * @returns {ClientActivityMetric}
     */
    get metrics() {
        return this.#metrics;
    }

    /**
     *
     * @returns {MetricAction}
     */
    get actions() {
        return this.#actions;
    }
}
class  BranchParsingMetric {
    #sources;
    /**
     *
     * @param {Object} branchParsingMetric
     */
    constructor(branchParsingMetric = {}) {
        this.#sources = branchParsingMetric.sources.map((item) => new ParsingSources(item)) || [];
    }

    /**
     *
     * @returns {Array}
     */
    get sources() {
        return this.#sources;
    }

}
class ClientParsingMetric {
    #branches;
    #sources;
    /**
     *
     * @param {Object} clientParsingMetric
     */
    constructor(clientParsingMetric = {}) {
        this.#branches = clientParsingMetric.branches || '';
        this.#sources = clientParsingMetric.sources.map((item) => new ParsingSources(item)) || [];
    }

    /**
     *
     * @returns {string}
     */
    get branches() {
        return this.#branches;
    }

    /**
     *
     * @returns {Array}
     */
    get sources() {
        return this.#sources;
    }

}

class ParsingSources {
    #code;
    #lastSync;
    #lastParse;

    constructor(parsingSources) {
        this.#code = parsingSources.code || '';
        this.#lastSync = parsingSources.lastSync || null;
        this.#lastParse = parsingSources.lastParse || null;
    }

    /**
     *
     * @returns {string}
     */
    get code() {
        return this.#code;
    }

    /**
     *
     * @returns {string}
     */
    get lastSync() {
        return this.#lastSync ? moment(this.#lastSync)
            .format('DD.MM.YYYY')
            .toString() : '—';
    }

    /**
     *
     * @returns {string|null}
     */
    lastSyncTime() {
        return this.#lastSync ? moment(this.#lastSync)
            .format('hh:mm')
            .toString() : null;
    }

    /**
     *
     * @returns {string}
     */
    get lastParse() {
        return this.#lastParse ? moment(this.#lastParse)
            .format('DD.MM.YYYY')
            .toString() : '—';
    }

    /**
     *
     * @returns {string|null}
     */
    lastParseTime() {
        return this.#lastParse ? moment(this.#lastParse)
            .format('hh:mm')
            .toString() : null;
    }


}

class ClientParsingBranches {
    #id;
    #clientId;
    #title;
    #enabled;
    #metrics;

    constructor(clientParsingBranches) {
        this.#id = clientParsingBranches.id;
        this.#clientId = clientParsingBranches.clientId;
        this.#title = clientParsingBranches.title;
        this.#enabled = clientParsingBranches.enabled;
        this.#metrics =  new BranchParsingMetric(clientParsingBranches.metrics || {});
    }

    get id() {
        return this.#id;
    }
    get clientId() {
        return this.#clientId;
    }
    get title() {
        return this.#title;
    }
    get enabled() {
        return this.#enabled;
    }
    get metrics() {
        return this.#metrics;
    }

}

export { ClientParsingBranches, ClientParsing };
