import References from "services/References";
import uiStore from "stores/UiStore";
import { api } from "core";
import { Administrator } from "models";

class AdministratorService extends References {
    constructor() {
        super();
    }

    /**
     *
     * @param {Object} data
     * @returns {Promise<Administrator>}
     */
    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/account/admins`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )

                return new Administrator(result);
            })
            .catch(error => {
                return error
            });
    }

    /**
     * @param {number} id
     * @param {Object} data
     * @returns {Promise<Administrator>}
     */
    saveEntity = (id, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/account/admins/${id}`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                )

                return new Administrator(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     * @param {number} id
     * @returns {Promise<Administrator>}
     */
    loadEntity = (id) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/admins/${id}`)
            .then(result => {
                return new Administrator(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     *
     * @param {Object} filter
     * @returns {Promise<{items: Administrator[], pager: PagerAnswer, sort: SortAnswer}>}
     */
    loadItems = (filter) => {
        const params = this.parseFilter(filter);

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/admins?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new Administrator(item);
                    }),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }
}

const administratorService = new AdministratorService();
export default administratorService;
