import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import 'styles/titles.scss';

const TitleH1 = ({ children, count, active, ico, showInfo, showBadge, color }) => {
    return (
        <div className={cn('title-h1')}>
            {ico && <div className={cn('ico', ico)} />}
            <h1 className={cn('title-h1__text')}>{children}</h1>
            {!!count && <span className={cn('title-h1__count', `title-h1__count_${color}`)}>{count}</span>}
            {showBadge && <span className={cn('badge', `badge_active-${active}`)}>{active ? 'Активен' : 'Не активен'}</span>}
            {showInfo && <span className={cn('pi', 'pi-info-circle', 'title-h1__info')} />}
        </div>
    );
}

TitleH1.propTypes = {
    children: PropTypes.any,
    count: PropTypes.number,
    active: PropTypes.bool,
    ico: PropTypes.string,
    showInfo: PropTypes.bool,
    showBadge: PropTypes.bool,
    color: PropTypes.string
}

export default TitleH1;
