import moment from "moment";

class BranchType {

    #id;
    #title;
    #defaultPurchase;
    #defaultApplication;
    #enabled;
    #createdAt;
    #updatedAt;

    constructor(branchType = {}) {
        this.#id = branchType.id || null;
        this.#title = branchType.title || '';
        this.#defaultPurchase = branchType.defaultPurchase || '';
        this.#defaultApplication = branchType.defaultApplication || '';
        this.#enabled = branchType.enabled || false;
        this.#createdAt = branchType.createdAt || null;
        this.#updatedAt = branchType.updatedAt || null;
    }

    get id() {
        return this.#id;
    }

    set id(id) {
        this.#id = id;
    }

    get title() {
        return this.#title;
    }

    set title(title) {
        this.#title = title;
    }

    get enabled() {
        return this.#enabled;
    }

    set enabled(enabled) {
        this.#enabled = enabled;
    }


    get defaultPurchase() {
        return this.#defaultPurchase;
    }

    set defaultPurchase(defaultPurchase) {
        this.#defaultPurchase = defaultPurchase;
    }

    get defaultApplication() {
        return this.#defaultApplication;
    }

    set defaultApplication(defaultApplication) {
        this.#defaultApplication = defaultApplication;
    }


    get createdAt() {
        return this.#createdAt ? moment(this.#createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    set createdAt(createdAt) {
        this.#createdAt = createdAt;
    }

    get updatedAt() {
        return this.#updatedAt ? moment(this.#updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    set updatedAt(updatedAt) {
        this.#updatedAt = updatedAt;
    }

    getObjectFromApi() {
        return {
            id: this.id,
            title: this.title,
            enabled: this.enabled,
            defaultApplication: this.defaultApplication,
            defaultPurchase: this.defaultPurchase,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        }
    }
}

export default BranchType;
