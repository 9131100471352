import { api } from 'core';
import References from "services/References";
import { Brand } from "models";
import uiStore from "stores/UiStore";

class BrandsService extends References {
    constructor() {
        super();
    }

    /**
     * @param {{}} data
     * @return {Promise<Brand>}
     */
    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/company/brands`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )
                return new Brand(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     * @param {int} id
     * @param {{}} data
     * @return {Promise<Brand>}
     */
    saveEntity = (id, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/company/brands/${id}`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                )

                return new Brand(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     * @param id {number}
     * @returns {Promise<Brand>}
     */
    loadEntity = (id) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/brands/${id}`)
            .then(result => {
                return new Brand(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {{}} filter
     * @return {Promise<{items: Brand[], pager: PagerAnswer, sort: SortAnswer}>}
     */
    loadItems = (filter) => {
        const params = this.parseFilter(filter);

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/brands?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new Brand(item);
                    }),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            })
    }
}

const brandsService = new BrandsService();
export default brandsService;
