import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

const ReferencesPage = ({ uiStore, breadcrumbsStore }) => {

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'references',
            className: 'references'
        });

        breadcrumbsStore.addItem({
            linkName: 'Справочники',
            link: '/references',
        }, 0);

    }, []);


    return (
        <div>
            <h1>Справочники</h1>
        </div>
    )
}

ReferencesPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore')(observer(ReferencesPage));
