import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import 'styles/activity.scss';

const Activity = ({ activity }) => {
    return (
        <div
            className={cn('activity', `activity_active-${activity.toString()}`)}
            title={activity ? 'Активно' : 'Неактивно'}
        />
    );
}

Activity.propTypes = {
    activity: PropTypes.bool,
}

export default Activity;
