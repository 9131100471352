import { action, makeObservable, observable } from "mobx";
import { branchTypesService } from "services";
import uiStore from "./UiStore";
import CommonPageStore from "stores/CommonPageStore";

class BranchTypeStore extends CommonPageStore {
    enabled = null;

    items = [];
    //TODO чтобы каждый раз не грузить, засунуть в кэш
    defaultPurchase = [];
    defaultApplication = [];

    entity = {
        id: null,
        title: '',
        defaultPurchase: '',
        defaultApplication: '',
        enabled: false,
    };

    constructor() {
        super();

        makeObservable(this, {
                items: observable,
                entity: observable,
                defaultPurchase: observable,
                defaultApplication: observable,

                setItems: action,
                setEntity: action,
                resetEntity: action,
                setPurchase: action,
                setApplication: action,
            }
        );
    }

    getApplication = () => {
        return branchTypesService
            .getApplication()
            .then(result => {
                this.setApplication(
                    result?.items
                );

            })
    }

    setApplication = (defaultApplication) => {
        this.defaultApplication = defaultApplication || [];
    }

    getPurchase = () => {
        branchTypesService
            .getPurchase()
            .then(result => {
                this.setPurchase(
                    result?.items
                );

            })
    }

    setPurchase = (defaultPurchase) => {
        this.defaultPurchase = defaultPurchase || [];
    }

    addEntity = () => {
        const data = {
            title: this.entity.title,
            enabled: this.entity.enabled,
            defaultPurchase: this.entity.defaultPurchase,
            defaultApplication: this.entity.defaultApplication,
        }

        uiStore.startLoading();

        return branchTypesService
            .addEntity(data)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    saveEntity = () => {
        const data = {
            title: this.entity.title,
            enabled: this.entity.enabled,
            defaultPurchase: this.entity.defaultPurchase,
            defaultApplication: this.entity.defaultApplication,
        }

        uiStore.startLoading();

        return branchTypesService
            .saveEntity(this.entity.id, data)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadEntity = (id) => {
        uiStore.startLoading();
        branchTypesService
            .loadEntity(id)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadItems = () => {
        const filter = {
            pager: this.pager,
            sort: this.sort,
            title: this.title,
            defaultPurchase: this.entity.defaultPurchase,
            defaultApplication: this.entity.defaultApplication,
            enabled: this.enabled,
        }
        uiStore.startLoading();

        branchTypesService
            .loadItems(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
                this.setSort(result?.sort)
            })
            .finally(() => {
                uiStore.stopLoading();
            });

    }

    setItems = (items) => {
        this.items = items || [];
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    resetEntity = () => {
        this.setEntity({
            id: null,
            title: '',
            enabled: false,
            defaultPurchase: '',
            defaultApplication: '',
        });
    }
}

const branchTypesStore = new BranchTypeStore();
export default branchTypesStore;
