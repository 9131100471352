import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import OkInputText from "../../common/OkInputText";
import { InputSwitch } from "primereact/inputswitch";
import OkDropdown from "../../common/OkDropdown";

const CompaniesNewPage = ({ uiStore, breadcrumbsStore, companyStore, mainStore }) => {

    const params = useParams()
    const history = useHistory();
    const { client } = mainStore;
    const { clientId } = useParams();
    const {
        entity,
        setEntity,
        addEntity,
        resetEntity,
    } = companyStore;

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        resetEntity()

        setEntity({
            clientId: params.clientId
        })
    }, [])

    useEffect(() => {

        uiStore.setPageProperty({
            page: 'clients',
            className: 'companies'
        });

        setIsValid((
            !!entity.title
        ));

    }, [entity]);


    useEffect(() => {
        if (client.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${client.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Компании',
                link: `/clients/${entity.id}/companies`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: 'Создание компании',
                link: `/clients/${clientId}/companies/new/`,
            }, 3);
        }
    }, [client, entity])

    const save = () => {
        addEntity()
            .then(() => {
                history.push(`/clients/${clientId}/companies/${companyStore.entity.id}/detail`);
            })
            .catch(() => {
            });
    };

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'house2_gradient'}
                >
                    &nbsp;
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={() => {
                                    save();
                                }}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${clientId}/companies`);
                                }}
                            />
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='name'
                                        name='name'
                                        type='text'
                                        isrequired="true"
                                        value={entity.title}
                                        onChange={(e) => {
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="name">Название компании *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={entity.enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.value })
                                    }}
                                />
                                <span className={cn('switch__text')}>Активен</span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        id='defaultApplication'
                                        name='defaultApplication'
                                        value={entity.type.code}
                                        options={[
                                            { title: 'Франшиза', code: 'franchise' },
                                        ]}
                                        optionLabel="title"
                                        optionValue="code"
                                        onChange={(e) => {
                                            setEntity({
                                                type: {
                                                    ...entity.type,
                                                    ...{
                                                        code: e.value
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                    <label htmlFor="defaultApplication">Тип</label>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

CompaniesNewPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    companyStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'companyStore', 'mainStore')(observer(CompaniesNewPage));
