import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import TitleH1 from "components/common/TitleH1";

const AdministratorsDetailPage = ({ uiStore, breadcrumbsStore, administratorStore }) => {

    let history = useHistory();

    const { entity, setEmail } = administratorStore;
    const { firstname, lastname, enabled, email } = entity;
    const { id } = useParams();

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'administrators',
            className: 'administrators'
        });

        breadcrumbsStore.addItem({
            linkName: 'Администраторы',
            link: '/administrators',
        }, 0);

        breadcrumbsStore.addItem({
            linkName: `${firstname} ${lastname}`,
            link: `/administrators/${entity.id}/detail`,
        }, 1);

    }, [entity]);

    useEffect(() => {
        administratorStore.loadEntity(id);
    }, [])

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'avatar__green_gradient'}
                    active={enabled}
                    showBadge={true}
                >
                    {firstname} {lastname}
                </TitleH1>
            </div>

            {entity && <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Редактировать"
                                icon="pi pi-pencil"
                                onClick={() => {
                                    history.push(`/administrators/${entity.id}/edit`);
                                }}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Вернуться к списку"
                                onClick={() => {
                                    history.push(`/administrators`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='email'
                                        name='email'
                                        type='text'
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        disabled
                                    />
                                    <label htmlFor="email">E-mail</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

AdministratorsDetailPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    administratorStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'administratorStore')(observer(AdministratorsDetailPage));

