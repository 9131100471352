import React, { useEffect, useState } from "react";
import { useIsMount } from "hooks";
import PropTypes from "prop-types";
import cn from "classnames";
import { InputSwitch } from "primereact/inputswitch";

/**
 * @param {boolean} initialLinked
 * @param {{}} item
 * @param {Function} changeHandler
 * @param {string} switchTextOn
 * @param {string} switchTextOff
 * @param {boolean} disabled
 * @return {JSX.Element}
 * @constructor
 */
const CustomizableLinkedSwitch = ({ item, initialLinked,  changeHandler, switchTextOn, switchTextOff, disabled }) => {
    const [linked, setLinked] = useState(initialLinked);
    const isMount = useIsMount();

    useEffect(() => {
        !isMount && changeHandler({
            item: item,
            linked: linked,
        });
    }, [linked]);

    return (
        <div className={cn('switch', 'switch-activity')}>
            <InputSwitch
                id='enabled'
                name='enabled'
                checked={linked}
                onChange={(e) => {
                    setLinked(e.target.value)
                }}
                disabled={disabled}
            />
            <span className={cn('switch__text', (disabled) ? '_text-muted' : null)}>{linked ? switchTextOn : switchTextOff}</span>
        </div>
    );
};

CustomizableLinkedSwitch.defaultProps = {
    disabled: false,
};

CustomizableLinkedSwitch.propTypes = {
    item: PropTypes.any,
    initialLinked: PropTypes.bool,
    changeHandler: PropTypes.func,
    switchTextOn: PropTypes.string,
    switchTextOff: PropTypes.string,
    disabled: PropTypes.bool,
};

export default CustomizableLinkedSwitch;
