import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import OkInputText from "../../common/OkInputText";
import TagLine from "./common/TagLine";
import TitleH2 from "../../common/TitleH2";
import { Link } from "react-router-dom";

const TagsDetailPage = ({ uiStore, breadcrumbsStore, tagStore }) => {

    let history = useHistory();

    const { entity, setEntity } = tagStore;
    const [fields, setFields] = useState({});
    const { title } = entity;
    const { id } = useParams();

    useEffect(() => {
        tagStore.loadEntity(id)
            .then(() => {
                setFields(tagStore.prepareForForm())
            });
    }, []);


    useEffect(() => {
        uiStore.setPageProperty({
            page: 'references',
            className: 'locations'
        });

        breadcrumbsStore.addItem({
            linkName: 'Правила автотегов',
            link: '/references/tags',
        }, 0);

        breadcrumbsStore.addItem({
            linkName: title,
            link: `/references/tags/${fields.id}/detail`,
        }, 1);

    }, [entity]);

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    active={fields.enabled}
                    showBadge={true}
                >
                    {title}
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                {!!fields.id && <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Редактировать"
                                icon="pi pi-pencil"
                                onClick={() => {
                                    history.push(`/references/tags/${fields.id}/edit`);
                                }}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Вернуться к списку"
                                onClick={() => {
                                    history.push(`/references/tags`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='title'
                                        name='title'
                                        type='text'
                                        value={title}
                                        isrequired="true"
                                        disabled={true}
                                    />
                                    <label htmlFor="title">Название правила</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    {fields.tags?.length > 0 && fields.tags.map((tag, key) => {
                        return <TagLine key={key}
                                        item={tag}
                                        index={key}
                                        disabled={true}
                                        onChange={()=>{}}
                                        removeTag={()=>{}}
                                        addKeywords={()=>{}}
                        />
                    })}

{/*
                    {fields.tags?.length > 0 && fields.tags.map((tag, key) => (
                        <TagLine
                            key={key}
                            item={tag}
                            index={key}
                            onChange={handlerChange}
                            removeTag={handlerRemove}
                            addKeywords={handlerAddKeywords}
                        />
                    ))}
*/}

                </div>}
            </div>
            {fields.clients?.length > 0 && <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'users')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={fields.clients.length}
                                color='color-blue'
                                ico='house_gradient'
                            >
                                Связь правила с клиентами
                            </TitleH2>
                            <table>
                                <thead>
                                <tr>
                                    <th>Клиенты</th>
                                    <th>Связь с правилом</th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.clients.map((client, key) => (<tr key={`${client.id}_${key}`}>
                                    <td>
                                        <Link to={`/clients/${client.id}/detail`}>{client.title}</Link>
                                    </td>
                                    <td>
                                        <span className={cn('badge', 'badge_linked-true')}>
                                            {('Подключено')}
                                        </span>
                                    </td>
                                </tr>))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>}
        </div>
    )
}

TagsDetailPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    tagStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'tagStore', 'mainStore')(observer(TagsDetailPage));
