import moment from "moment";

class ClientActivityUserMetric {
    #notifications;
    #lastLogin;
    #lastAction;
    #idle;
    #actions;

    /**
     *
     * @param {Object} clientActivityUserMetric
     */
    constructor(clientActivityUserMetric = {}) {
        this.#notifications = clientActivityUserMetric.notifications || {};
        this.#lastLogin = clientActivityUserMetric.lastLogin || null;
        this.#lastAction = clientActivityUserMetric.lastAction || null;
        this.#idle = clientActivityUserMetric.idle || 0;
        this.#actions = new MetricAction(clientActivityUserMetric.actions || {});
    }

    /**
     *
     * @returns {Object}
     */
    get notifications() {
        return this.#notifications;
    }


    /**
     *
     * @returns {string}
     */
    get lastLogin() {
        return this.#lastLogin ? moment(this.#lastLogin)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string}
     */
    get lastAction() {
        return this.#lastAction ? moment(this.#lastAction)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {Number}
     */
    get idle() {
        return this.#idle;
    }

    /**
     *
     * @returns {MetricAction}
     */
    get actions() {
        return this.#actions;
    }

}

class ClientActivityUser {
    #id;
    #clientId;
    #lastname;
    #firstname;
    #email;
    #enabled;
    #metrics;

    /**
     *
     * @param {Object} clientActivityUser
     */
    constructor(clientActivityUser = {}) {
        this.#id = clientActivityUser.id || null;
        this.#clientId = clientActivityUser.clientId || null;
        this.#lastname = clientActivityUser.lastname || '';
        this.#firstname = clientActivityUser.firstname || '';
        this.#email = clientActivityUser.email || '';
        this.#enabled = clientActivityUser.enabled || false;
        this.#metrics = new ClientActivityUserMetric(clientActivityUser.metrics || {});
    }

    /**
     *
     * @return {Number|null}
     */
    get id() {
        return this.#id;
    }

    /**
     *
     * @return {Number|null}
     */
    get clientId() {
        return this.#clientId;
    }

    /**
     *
     * @returns {String}
     */
    get lastname() {
        return this.#lastname;
    }

    /**
     *
     * @returns {String}
     */
    get email() {
        return this.#email;
    }

    /**
     *
     * @returns {String}
     */
    get fullName() {
        return `${this.#lastname} ${this.#firstname}`;
    }

    /**
     *
     * @returns {String}
     */
    get firstname() {
        return this.#firstname;
    }

    /**
     *
     * @returns {Boolean}
     */
    get enabled() {
        return this.#enabled;
    }

    /**
     *
     * @returns {ClientActivityUserMetric}
     */
    get metrics() {
        return this.#metrics;
    }

}

class ClientActivity {
    #id;
    #title;
    #enabled;
    #metrics;
    #actions;

    /**
     *
     * @param {Object} clientActivity
     */
    constructor(clientActivity = {}) {
        this.#id = clientActivity.id || null;
        this.#title = clientActivity.title || '';
        this.#enabled = clientActivity.enabled || false;
        this.#metrics = new ClientActivityMetric(clientActivity.metrics || {});
        this.#actions = new MetricAction(clientActivity.actions || {});
    }

    /**
     *
     * @return {Number|null}
     */
    get id() {
        return this.#id;
    }

    /**
     *
     * @returns {String}
     */
    get title() {
        return this.#title;
    }

    /**
     *
     * @returns {Boolean}
     */
    get enabled() {
        return this.#enabled;
    }

    /**
     *
     * @returns {ClientActivityMetric}
     */
    get metrics() {
        return this.#metrics;
    }

    /**
     *
     * @returns {MetricAction}
     */
    get actions() {
        return this.#actions;
    }
}

class ClientActivityMetric {
    #users;
    #notifications
    #lastLogin;
    #lastAction;
    #idle;
    #actions;

    /**
     *
     * @param {Object} ClientActivityMetric
     */
    constructor(ClientActivityMetric = {}) {
        this.#users = ClientActivityMetric.users || 0;
        this.#notifications = ClientActivityMetric.notifications || 0;
        this.#lastLogin = ClientActivityMetric.lastLogin || null;
        this.#lastAction = ClientActivityMetric.lastAction || null;
        this.#idle = ClientActivityMetric.idle || 0;
        this.#actions = new MetricAction(ClientActivityMetric.actions || {});
    }

    /**
     *
     * @returns {Number}
     */
    get users() {
        return this.#users;
    }

    /**
     *
     * @returns {Number}
     */
    get notifications() {
        return this.#notifications;
    }

    /**
     *
     * @returns {string}
     */
    get lastLogin() {
        return this.#lastLogin ? moment(this.#lastLogin)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string}
     */
    get lastAction() {
        return this.#lastAction ? moment(this.#lastAction)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {Number}
     */
    get idle() {
        return this.#idle;
    }

    /**
     *
     * @returns {MetricAction}
     */
    get actions() {
        return this.#actions;
    }

}

class MetricAction {
    #open;
    #send_application;
    #send_answer;
    #send_claim;

    constructor(MetricAction = {}) {
        this.#open = MetricAction.open || null;
        this.#send_application = MetricAction.send_application || null;
        this.#send_answer = MetricAction.send_answer || null;
        this.#send_claim = MetricAction.send_claim || null;
    }

    /**
     *
     * @returns {string}
     */
    get open() {
        return this.#open ? moment(this.#open)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string}
     */
    get send_application() {
        return this.#send_application ? moment(this.#send_application)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string}
     */
    get send_answer() {
        return this.#send_answer ? moment(this.#send_answer)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string}
     */
    get send_claim() {
        return this.#send_claim ? moment(this.#send_claim)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

}


export { ClientActivity, ClientActivityUser };
