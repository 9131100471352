import { action, makeObservable, observable } from "mobx";
import { locationsService } from "services";
import uiStore from "./UiStore";
import CommonPageStore from "stores/CommonPageStore";

class LocationStore extends CommonPageStore {
    enabled = null;

    items = [];

    entity = {
        id: null,
        title: '',
        enabled: false,
    };

    constructor() {
        super();

        makeObservable(this, {
                items: observable,
                entity: observable,

                setItems: action,
                setEntity: action,
                resetEntity: action,
            }
        );
    }

    addEntity = () => {
        const data = {
            title: this.entity.title,
            enabled: this.entity.enabled,
        }

        uiStore.startLoading();

        return locationsService
            .addEntity(data)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }
    saveEntity = () => {
        const data = {
            title: this.entity.title,
            enabled: this.entity.enabled,
        }

        uiStore.startLoading();

        return locationsService
            .saveEntity(this.entity.id, data)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadEntity = (id) => {
        uiStore.startLoading();
        locationsService
            .loadEntity(id)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadItems = () => {
        const filter = {
            pager: this.pager,
            sort: this.sort,
            title: this.title,
            enabled: this.enabled,
        }

        uiStore.startLoading();

        locationsService
            .loadItems(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
                this.setSort(result?.sort)
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    resetEntity = () => {
        this.setEntity({
            id: null,
            title: '',
            enabled: false,
        })
    }
}

const locationStore = new LocationStore();
export default locationStore;
