import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

import stores from './stores/index';
import App from './App';

import 'normalize.css';
import 'primeflex/primeflex.css';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider {...stores}>
                <App />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
reportWebVitals();
