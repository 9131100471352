import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

const Crumb = ({ link, linkName, lastItem, separator }) => {
    return (
        lastItem
            ?
            <span>
                <Link to={link}>
                    {linkName}
                </Link>
                {separator}
            </span>
            :
            <span>{linkName}</span>
    )
}

Crumb.propTypes = {
    link: PropTypes.string,
    linkName: PropTypes.string,
    lastItem: PropTypes.bool,
    separator: PropTypes.string,
}
export default Crumb;
