import { makeObservable, observable, action, toJS } from "mobx";
import {
    integrationService,
    adPlatformTypeService,
} from "../services";

class IntegrationStore {
    pager = {
        size: 10,
        number: 1,
    };
    sort = {
        column: 'created',
        order: 'desc'
    };
    items = [];
    fType = -1;
    clientId = -1;
    fEnabled = -1;
    query = '';
    itemsBranches = {};

    constructor() {
        makeObservable(
            this,
            {
                items: observable,
                fType: observable,
                clientId: observable,
                fEnabled: observable,
                query: observable,
                pager: observable,
                itemsBranches: observable,

                setItemsBranches: action,
                setSort: action,
                setPager: action,
                setItems: action,
                setFType: action,
                setClientId: action,
                setFEnabled: action,
                setQuery: action,
                resetFilter: action,
            }
        )
    }

    setItems = (items) => {
        this.items = items;
    }

    setFType = (typeFilter) => {
        this.fType = typeFilter;
    }

    setFEnabled = (fEnabled) => {
        this.fEnabled = fEnabled;
    }

    setClientId = (clientIdFilter) => {
        this.clientId = clientIdFilter;
    }

    setQuery = (value) => {
        this.query = value;
    }

    setSort = (sort) => {
        this.sort = sort || {};
    }

    setPager = (pager = {}) => {
        this.pager = {
            ...this.pager,
            ...pager,
        };
    }

    setItemsBranches = (itemsBranches) => {
        this.itemsBranches = itemsBranches;
    }

    resetFilter = () => {
        this.query = '';

        this.pager = {
            size: 10,
            number: 1,
        }

        this.sort = {
            column: 'created',
            order: 'desc'
        };
    }

    getParserAccountList() {
        return integrationService.getParserAccountList()
            .then(result => {
                return result?.items.map(item => {
                    return {
                        name: item.username,
                        code: item.username,
                        active: item.default,
                    }
                })
            })
            .catch(error => error);
    }

    getAdPlatformType() {
        return adPlatformTypeService.getAll().then(result => {
            return result?.items.map(item => {
                return {
                    name: item.title,
                    code: item.code,
                }
            })
        }).catch(error => error);
    }


    loadItems() {
        let filter = {
            page: toJS(this.pager),
            sort: toJS(this.sort),
            query: toJS(this.query),
        }

        if (this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }

        if (this.clientId !== -1) {
            filter = {
                ...filter,
                ...{ clientId: toJS(this.clientId) }
            }
        }

        if (this.fType !== -1) {
            filter = {
                ...filter,
                ...{ type: toJS(this.fType) }
            }
        }

        this.setItemsBranches({});
        integrationService.getIntegrations(filter)
            .then(result => {
                this.setItems(result?.items);
                this.setSort(result?.sort);
                this.setPager(result?.pager);
                return Promise.all(
                    result
                        .items
                        .map((adPlatform) => integrationService.findBranches(adPlatform.id, true))
                );

            })
            .then((linkedBranches) => {
                const itemsBranches = {};
                linkedBranches
                    .forEach((linkedBranchesData) => {
                        itemsBranches[linkedBranchesData.adPlatformId] = linkedBranchesData.items;
                    });
                this.setItemsBranches(itemsBranches);
            })
            .catch(e => e)
            .finally(() => {

            })

    }
}

const integrationStore = new IntegrationStore();
export default integrationStore;