import React, { useEffect } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

import 'styles/breadcrumbs.scss';
import Crumb from "components/breadcrumbs/Crumb";

const Breadcrumbs = ({ breadcrumbsStore }) => {
    const { breadcrumbs } = breadcrumbsStore;

    useEffect(() => {
        breadcrumbsStore.init();
    }, []);

    return (
        <div className={cn('breadcrumbs')}>
            {breadcrumbs.map((item, key) => (
                <Crumb
                    key={key}
                    link={item.link}
                    linkName={item.linkName}
                    lastItem={key + 1 < breadcrumbs.length}
                    separator=' → '
                />
            ))}
        </div>
    )
}

Breadcrumbs.propTypes = {
    breadcrumbsStore: PropTypes.any,
}

export default inject('breadcrumbsStore')(observer(Breadcrumbs));
