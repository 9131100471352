import React, { useEffect, useState } from "react";
import cn from "classnames";
import _ from "underscore";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { reaction } from "mobx";
import PropTypes from "prop-types";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";

import TitleH1 from "components/common/TitleH1";
import Activity from "components/common/Activity";
import Sortable from "components/common/Sortable";

import 'styles/pages/brands.scss';

const BrandsPage = ({ uiStore, breadcrumbsStore, brandStore }) => {

    let history = useHistory();

    const { title, setTitle, items, pager, setPager, sort, setSort, resetFilter } = brandStore;

    const [first, setFirst] = useState(0);

    useEffect(() => {
        resetFilter();

        uiStore.setPageProperty({
            page: 'references',
            className: 'brands'
        });

        breadcrumbsStore.addItem({
            linkName: 'Бренды филиалов',
            link: '/references/brands',
        }, 0);

    }, []);

    useEffect(() => {
        brandStore.loadItems()
    }, []);

//TODO все реакции обьеденить в одну
    useEffect(() => {
        const disposer = reaction(
            () => brandStore.pager.number,
            () => {
                brandStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => brandStore.title,
            (value) => {
                titleDebounceHandler(value)
            }
        );

        return () => disposer();
    }, []);

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && brandStore.loadItems(),
        800
    );

    useEffect(() => {
        const disposer = reaction(
            () => JSON.stringify(brandStore.sort),
            () => {
                brandStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    const sortHandler = (obj) => {
        setSort(obj);
    }

    /** @namespace item.brand */
    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    color={'color-red'}
                >
                    Справочники / Бренды филиалов
                </TitleH1>
            </div>
            <div className={cn('add-container')}>
                <Button
                    className={cn('p-button-outlined')}
                    label="Создать значение"
                    icon="pi pi-plus"
                    onClick={() => {
                        history.push('/references/brands/new');
                    }}
                />
            </div>
            <div className={cn('search-container')}>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Поиск по значению справочника..."
                            name='title'
                            id='title'
                            value={title}
                            onChange={(e) => {
                                // noinspection JSUnresolvedVariable
                                setTitle(e.target.value);
                            }}
                        />
                    </span>
                    </div>
                </div>
            </div>

            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='title'
                            >Значение справочника</Sortable>
                        </th>
                        <th className={cn('date-head')}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='created'
                            >Создан</Sortable>
                        </th>
                        <th className={cn('date-head')}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='updated'
                            >Изменен</Sortable>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, key) => (
                        <tr key={key}>
                            <td className={cn('id-body')}>{item.id}</td>
                            <td className={cn('activity-data')}>
                                <Activity activity={item.enabled} />
                            </td>
                            <td>
                                <Link to={`/references/brands/${item.id}/detail`}>{item.title}</Link>
                            </td>
                            <td>{item.createdAt}</td>
                            <td>{item.updatedAt}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first} /* номер элемента для отсчета страниц*/
                    rows={pager.size} /*кол-во элементов на странице*/
                    totalRecords={pager.itemCount} /*всего элементов*/
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    );
};

BrandsPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    brandStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'brandStore')(observer(BrandsPage));
