import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { Button } from "primereact/button";
import TitleH2 from "components/common/TitleH2";
import TitleH1 from "components/common/TitleH1";
import { InputSwitch } from "primereact/inputswitch";
import { useHistory, useParams } from "react-router";
import CustomizableLinkedSwitch from "../../common/CustomizableLinkedSwitch";
import OkInputText from "components/common/OkInputText";
import RulesApply from "./rules/RulesApply";
import { Dropdown } from "primereact/dropdown";

/**
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {ClientStore} clientStore
 * @param {IntegrationStore} integrationStore
 * @return {JSX.Element}
 * @constructor
 */
const ClientEditPage = ({ uiStore, breadcrumbsStore, clientStore, integrationStore }) => {

    let history = useHistory();

    const {
        entity,
        setEntity,
        features,
        enableFeature,
        disableFeature,
        mutedFeatures,
    } = clientStore;
    const { title, enabled, technicalAccount } = entity;
    const { id } = useParams();

    const [isValid, setIsValid] = useState(false);
    const [technicalAccountList, setTechnicalAccount] = useState([]);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'clients'
        });

        breadcrumbsStore.addItem({
            linkName: 'Редактирование',
            link: `/clients/${entity.id}/edit`,
        }, 2);

        setIsValid(!!entity.title.length);

    }, [entity]);

    useEffect(() => {
        clientStore.loadEntity(id);

        integrationStore.getParserAccountList()
            .then(result => {
                setTechnicalAccount(result);
            })
            .catch(e => e);

    }, []);

    const save = () => {
        clientStore
            .saveEntity()
            .then(() => {
                history.push(`/clients/${clientStore.entity.id}/detail`);
            })
    };

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'bag_gradient'}
                >
                    {title} / Редактирование
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={save}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${entity.id}/detail`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='title'
                                        name='title'
                                        type='text'
                                        value={title}
                                        isrequired="true"
                                        onChange={(e) => {
                                            // noinspection JSUnresolvedVariable
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="title">Клиент *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    id='enabled'
                                    name='enabled'
                                    checked={enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.target.value });
                                    }}
                                />
                                <span className={cn('switch__text')}>{enabled ? 'Активен' : 'Не активен'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Dropdown
                                        value={technicalAccount}
                                        options={technicalAccountList}
                                        onChange={(e) => {
                                            setEntity({ technicalAccount: e.target.value });
                                        }}
                                        id="technicalAccount"
                                        name="technicalAccount"
                                        optionLabel="name"
                                        optionValue="code"
                                        className={'p-inputwrapper-filled'}
                                    />
                                        <label htmlFor="technicalAccount">Технический аккаунт парсинга</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='reviewCollectorTemplate'
                                        name='reviewCollectorTemplate'
                                        type='text'
                                        value={entity.reviewCollectorTemplate}
                                        onChange={(e) => {
                                            setEntity({ reviewCollectorTemplate: e.target.value });
                                        }}
                                    />
                                        <label htmlFor="reviewCollectorTemplate">Название в ссылке сбора отзыва</label>
                                    </span>
                            </div>
                        </div>

                    </div>
                    <div className={cn('edit-form__row')}>
                        <TitleH2 ico={'bag_gradient'}>Опции</TitleH2>
                    </div>
                    {features.map((feature, i) => (feature.type === 'boolean' && <div key={`${feature.feature}_${i}`} className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <CustomizableLinkedSwitch
                                    initialLinked={feature.value}
                                    item={feature}
                                    switchTextOn={feature.title}
                                    switchTextOff={feature.title}
                                    disabled={mutedFeatures[feature.feature]}
                                    changeHandler={(e) => {
                                        if (e.linked) {
                                            enableFeature(e.item.feature);
                                        } else {
                                            disableFeature(e.item.feature);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>))}

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')} style={{ width: 'auto' }}>
                            {entity.id && <RulesApply
                                clientId={entity.id}
                            />}
                        </div>
                    </div>

                </div>
            </div>

            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('title-container')}>
                        <TitleH2
                            count={entity.counters.branches}
                            color='color-lilac'
                            ico='house_gradient'
                            href={`/clients/${entity.id}/branches`}
                        >
                            Филиалы
                        </TitleH2>
                    </div>
                    <div className={cn('title-container')}>
                        <TitleH2
                            count={entity.counters.integrations}
                            color='color-orange'
                            ico='chain_gradient'
                            href={`/clients/${entity.id}/integrations`}
                        >
                            Интеграции
                        </TitleH2>
                    </div>
                    <div className={cn('title-container')}>
                        <TitleH2
                            count={entity.counters.users}
                            color='color-blue-light'
                            ico='avatar__blue_gradient'
                            href={`/clients/${entity.id}/users`}
                        >
                            Пользователи
                        </TitleH2>
                    </div>
                </div>
            </div>
        </div>
    )
}

ClientEditPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    clientStore: PropTypes.any,
    integrationStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'clientStore', 'integrationStore')(observer(ClientEditPage));

