import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import OkInputText from "../../common/OkInputText";
import { InputSwitch } from "primereact/inputswitch";
import TagLine from "./common/TagLine";

const TagsNewPage = ({ uiStore, breadcrumbsStore, tagStore, mainStore }) => {

    let history = useHistory();

    const { entity, setEntity } = tagStore;

    const [isValid, setIsValid] = useState(false);
    const [fields, setFields] = useState({});

    useEffect(() => {
        setFields(tagStore.prepareForForm())
    }, []);

    useEffect(() => {

        uiStore.setPageProperty({
            page: 'references',
            className: 'locations'
        });

        breadcrumbsStore.addItem({
            linkName: 'Правила автотегов',
            link: '/references/tags',
        }, 0);
        breadcrumbsStore.addItem({
            linkName: 'Добавить значение',
            link: '/references/tags/new',
        }, 1);

        setIsValid(!!fields.title);
    }, [fields]);

    const save = () => {
        const { valid, errors, processedFields } = tagStore.checkUniqueTagName(fields);

        if(!valid) {
            setFields(processedFields);

            errors.map(error => {
                setTimeout(() => {
                    uiStore.setMessage(
                        {
                            life: 2000,
                            severity: 'error',
                            summary: 'Ошибка валидации',
                            detail: error
                        }
                    )
                }, 0)
            })
        }
        else {
            tagStore.prepareForApi(fields)
            tagStore.addEntity()
                .then((result) => {
                    if(result.id) {
                        history.push(`/references/tags/${result.id}/detail`);
                    }

                })
                .catch(error => {
                    // TODO обернуть в функцию
                    switch (error.statusCode) {
                        case 422:
                            uiStore.setMessage(
                                {
                                    severity: 'error',
                                    summary: error.error.description,
                                    detail: error.error.data.map(error=>{
                                        return error.message
                                    }),
                                }
                            );
                            break;
                        case 500:
                            uiStore.setMessage(
                                {
                                    severity: 'error',
                                    summary: 'Внимание',
                                    detail: error.error.description
                                }
                            );
                            break
                        default:
                            uiStore.setMessage(
                                {
                                    severity: 'error',
                                    summary: 'Внимание',
                                    detail: 'Неизвестная ошибка'
                                }
                            );

                    }
                })

        }
    };

    const addTag = () => {
        let tags = [...fields.tags]
        tags.push({ name: '', valid: false, keywords: [{ name: '', valid: false }] })

        setFields(() => {
            return {
                ...fields,
                ...{ tags },
            }
        })
        //tagStore.addTag(new Tag({}))
    }

    const handlerAddKeywords = (index, value) => {
        let tags = [ ...fields.tags ]
        tags[index] = {
            ...tags[index],
            ...{ keywords: value }
        }

        setFields(() => {
            return {
                ...fields,
                ...{ tags }
            }
        })

    }

    const handlerChange = (index, value) => {

        let tags = [ ...fields.tags ]

        tags[index] = {
            name: value,
            valid: !!value,
            keywords: tags[index].keywords
        };

        setFields(() => {
            return {
                ...fields,
                ...{ tags }
            }
        })
    }

    const handlerRemove = (index) => {
        let copy = { ...fields }
        copy.tags.splice(index, 1);
        setFields(copy)
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1>
                    Новое правило
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                {entity && <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={save}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/references/tags`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='title'
                                        name='title'
                                        type='text'
                                        value={fields.title || ''}
                                        isrequired="true"
                                        onChange={(e) => {
                                            // noinspection JSUnresolvedVariable
                                            setFields(() => (
                                                {
                                                    ...fields,
                                                    ...{ title: e.target.value, valid: !!e.target.value }
                                                }
                                            ))
                                            ///setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="title">Название правила *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={fields.enabled}
                                    onChange={(e) => {
                                        setFields(() => (
                                            {
                                                ...fields,
                                                ...{ enabled: e.value }
                                            }
                                        ))
                                        //setEntity({ enabled: e.value })
                                    }}
                                />
                                <span className={cn('switch__text')}>Активен</span>
                            </div>
                        </div>
                    </div>
{/*                    {entity.tags.map((tag, key) => {
                        return <TagLine key={key} item={tag} index={key}/>
                    })}*/}
                    {fields.tags?.length > 0 && fields.tags.map((tag, key) => (
                        <TagLine
                            key={key}
                            item={tag}
                            index={key}
                            onChange={handlerChange}
                            removeTag={handlerRemove}
                            addKeywords={handlerAddKeywords}
                        />
                    ))}
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <Button
                                label="Добавить тег"
                                icon="pi pi-plus"
                                onClick={addTag}
                                disabled={!fields.title?.length}
                            />
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

TagsNewPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    tagStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'tagStore', 'mainStore')(observer(TagsNewPage));
