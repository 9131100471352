import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import { ProgressSpinner } from "primereact/progressspinner";
import { inject, observer } from "mobx-react";
import 'styles/container.scss';

const Container = ({ children, className, uiStore }) => {

    const { isLoading, getPageProperty } = uiStore;

    return (
        <div className={cn(className, getPageProperty().className)}>
            {isLoading && <div className={cn('loader')}>
                    <ProgressSpinner />
                </div>}
            {children}
        </div>
    )
}

Container.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    uiStore: PropTypes.any,
}

export default inject('uiStore')(observer(Container));
