import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import TitleH1 from "components/common/TitleH1";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router";
import OkInputText from "components/common/OkInputText";

const LocationsEditPage = ({ uiStore, breadcrumbsStore, locationStore }) => {

    let history = useHistory();

    const { entity, setEntity } = locationStore;
    const { title, enabled } = entity;
    const { id } = useParams();

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'references',
            className: 'locations'
        });


        if (entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Локации',
                link: '/references/locations',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: title,
                link: `/references/locations/${entity.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Редактирование',
                link: `/references/locations/${entity.id}/edit`,
            }, 2);

        }


        setIsValid(!!entity.title.length);

    }, [entity]);

    useEffect(() => {
        locationStore.loadEntity(id);
    }, []);

    const save = () => {
        locationStore.saveEntity()
            .then(() => {
                history.push(`/references/locations/${locationStore.entity.id}/detail`);
            })
    };

    /** @namespace item.brand */
    // noinspection JSUnresolvedVariable
    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                >
                    {title} / Редактирование
                </TitleH1>
            </div>

            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={save}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/references/locations/${entity.id}/detail`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='title'
                                        name='title'
                                        type='text'
                                        value={title}
                                        isrequired="true"
                                        onChange={(e) => {
                                            // noinspection JSUnresolvedVariable
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="title">Значение справочника *</label>
                                </span>
                            </div>

                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            {/*TODO убрать в компонент*/}
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    id='enabled'
                                    name='enabled'
                                    checked={enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.target.value });
                                    }}
                                />
                                <span className={cn('switch__text')}>{enabled ? 'Активен' : 'Не активен'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

LocationsEditPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    locationStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'locationStore')(observer(LocationsEditPage));
