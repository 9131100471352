import { api, windowStorage } from "core";
import uiStore from "stores/UiStore";

class AuthService {
    /**
     * @type {{}}
     */
    #user = {};

    /**
     * @type {string}
     */
    #refreshToken;

    /**
     * @type {string}
     */
    #accessToken;

    /**
     *
     * @type {boolean}
     */
    #isAuthorized = false;

    #uiStore;


    set user(user) {
        this.#user = user;
    }

    get user() {
        return this.#user;
    }

    set refreshToken(refreshToken) {
        this.#refreshToken = refreshToken;
    }

    get refreshToken() {
        return this.#refreshToken;
    }

    set accessToken(accessToken) {
        this.#accessToken = accessToken;
    }

    get accessToken() {
        return this.#accessToken;
    }

    set isAuthorized(isAuthorized) {
        this.#isAuthorized = isAuthorized;
    }

    get isAuthorized() {
        return this.#isAuthorized;
    }

    setRemember(remember) {
        windowStorage.setStorage(remember);
    }

    constructor() {
        this.#uiStore = uiStore;
        windowStorage.checkStorageRemember(process.env.REACT_APP_STORAGE_NAME);

        window
            .addEventListener(
                'storage',
                (ev) => {
                    if (ev.key === process.env.REACT_APP_STORAGE_NAME) {
                        const data = this.getFromStorage();

                        if (data) {
                            const { refresh_token, access_token } = JSON.parse(data);

                            this.refreshToken = refresh_token;
                            this.accessToken = access_token;
                        }
                    }
                }
            );
    }

//TODO убрать в хелпер и переименовать
    jsonToFormUrl(data) {
        let formBody = [];
        for (let property in data) {
            if (Object.prototype.hasOwnProperty.call(data, property)) {
                let encodedKey = encodeURIComponent(property);
                let encodedValue = encodeURIComponent(data[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
        }
        return formBody.join("&");
    }

    authImpersonate(data) {
        const formBody = this.jsonToFormUrl(data)

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/auth/impersonate`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${authService.accessToken}`
                    },
                    body: formBody,
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    return resolve(result.data);
                })
                .catch((error) => {
                    return reject(error);
                })

        });

    }

    auth({ username, password }) {
        this.#uiStore.startLoading();

        const formBody = this.jsonToFormUrl({
            ...{ username, password },
            ...{ 'grant_type': 'password' }
        })

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/auth/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: formBody,
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    if (result.statusCode === 200) {
                        const { access_token, refresh_token, expires_in } = result.data;
                        this.saveInStorage(
                            access_token,
                            refresh_token,
                            expires_in,
                        );
                        this.refreshToken = refresh_token;
                        this.accessToken = access_token;
                        this.isAuthorized = true;
                        resolve(result);
                    }
                    reject(result);

                    this.#uiStore.stopLoading();
                })
                .catch((error) => {
                    reject(error);
                })

        });
    }

    logout() {
        this.#uiStore.startLoading();
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/auth/logout`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        'refresh_token': this.refreshToken
                    }),
                }
            )
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    if (result.statusCode === 200) {
                        resolve();
                        this.isAuthorized = false;
                        this.destroySession();
                    }

                    reject(result);
                    this.#uiStore.stopLoading();
                })
                .catch((e) => {
                    reject(e);
                })

        });
    }

    refresh() {
        return api.refresh(this.refreshToken).then((result) => {
            if (result?.statusCode === 200) {
                const { access_token, refresh_token, expires_in } = result.data;
                this.saveInStorage(
                    access_token,
                    refresh_token,
                    expires_in,
                );
                this.refreshToken = refresh_token;
                this.accessToken = access_token;
                return Promise.resolve(result);
            }
            return Promise.reject(result);
        })
    }

    saveInStorage(access_token, refresh_token, expires_in) {
        const nowTs = Math.ceil(Date.now() / 1000) + expires_in;
        windowStorage.setItem(
            process.env.REACT_APP_STORAGE_NAME,
            JSON.stringify({
                access_token,
                refresh_token,
                access_exp: nowTs,
            })
        )
    }

    getFromStorage() {
        return windowStorage.getItem(process.env.REACT_APP_STORAGE_NAME);
    }

    destroySession() {
        windowStorage.removeItemAllStorage(process.env.REACT_APP_STORAGE_NAME);
        this.refreshToken = '';
        this.accessToken = '';
        this.user = {};
    }
}

const authService = new AuthService();
export default authService;
