import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import cn from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import TitleH1 from "components/common/TitleH1";

import { InputSwitch } from "primereact/inputswitch";
import { useHistory } from "react-router";
import OkInputText from "components/common/OkInputText";

const UsersNewPage = ({ mainStore, uiStore, breadcrumbsStore, userStore }) => {

    let history = useHistory();
    const passwordInput = useRef(null);

    const { client } = mainStore;
    const { entity, setEntity, addEntity, resetEntity } = userStore;
    const { firstname, lastname, email, password, enabled } = entity;

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        resetEntity()
        setEntity({ clientId: client.id });
    }, []);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'users'
        });

        setIsValid((
            !!entity.lastname.length &&
            !!entity.firstname.length &&
            !!entity.email.length &&
            !!entity.password.length
        ));

    }, [entity]);

    useEffect(() => {
        if (client.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${client.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: `Пользователи`,
                link: `/clients/${client.id}/users`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: 'Новый пользователь',
                link: `/clients/${client.id}/users/new`,
            }, 3);
        }
    }, [client])



    const save = () => {
        addEntity()
            .then(() => {
                history.push(`/clients/${client.id}/users/${userStore.entity.id}/detail`);
            });
    }

    const setPassword = () => {
        userStore.setPassword()
            .then(() => {
                passwordInput.current.select();
                document.execCommand("copy");
            })
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'avatar__blue_gradient'}
                >
                    {(firstname || lastname) ? `${firstname} ${lastname}` : 'Новый пользователь'}
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={save}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${client.id}/users`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='firstname'
                                        name='firstname'
                                        type='text'
                                        value={firstname}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ firstname: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="firstname">Имя *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='lastname'
                                        name='lastname'
                                        type='text'
                                        value={lastname}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ lastname: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="lastname">Фамилия *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            {/*TODO убрать в компонент*/}
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    id='enabled'
                                    name='enabled'
                                    checked={enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.target.value });
                                    }}
                                />
                                <span className={cn('switch__text')}>{enabled ? 'Активен' : 'Не активен'}</span>
                            </div>
                        </div>

                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='email'
                                        name='email'
                                        type='text'
                                        value={email}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ email: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="email">E-mail *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='password'
                                        name='password'
                                        type='text'
                                        ref={passwordInput}
                                        value={password}
                                        onChange={(e) => {
                                            setEntity({ password: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="password">Пароль</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <Button
                                className={cn('btn-gen-password')}
                                icon="pi pi-cog"
                                title="Сгенерировать и скопировать"
                                onClick={setPassword}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

UsersNewPage.propTypes = {
    mainStore: PropTypes.any,
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    userStore: PropTypes.any,
}

export default inject('mainStore', 'uiStore', 'breadcrumbsStore', 'userStore' )(observer(UsersNewPage));

