import { action, makeObservable, observable } from "mobx";
import { parseAccountsService } from "../services";
import uiStore from "./UiStore";


class ParsingAccountsStore {

    items = [];

    entity = {
        id: null,
        title: '',
        username: '',
        yandex: '',
        gis: '',
        flamp: '',
        zoon: '',
        imap: '',
        enabled: false,
    };

    title = '';

    pager = {
        size: 10,
        number: 1,
    }

    sort = {
        column: 'username',
        order: 'desc'
    };

    constructor() {
        makeObservable(this, {
                items: observable,
                entity: observable,
                sort: observable,
                pager: observable,
                title: observable,

                setItems: action,
                setEntity: action,
                setSort: action,
                setPager: action,
                resetEntity: action,
                setTitle: action,

            }
        );
    }

    loadEntity(id) {
        uiStore.startLoading();

        return parseAccountsService.loadEntity(id)
            .then(result => {
                this.setEntity(
                    new ParsingAccountsModel(result)
                );
                return Promise.resolve("OK");
            })
            .catch(error => error)
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    saveEntity(id) {
        uiStore.startLoading();

        const parsingAccountsModel = new ParsingAccountsModel();
        const object = parsingAccountsModel.getApiObject(this.entity)

        return parseAccountsService.saveEntity(id, object)
            .then(result => {
                if (result.statusCode === 422) {
                    return Promise.reject(result)
                } else {
                    return result
                }
            })
            .catch(error => {
                return Promise.reject(error)
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    addEntity() {
        uiStore.startLoading();

        const parsingAccountsModel = new ParsingAccountsModel();
        const object = parsingAccountsModel.getApiObject(this.entity)

        return parseAccountsService.addEntity(object)
            .then(result => {
                if (result.statusCode === 422) {
                    return Promise.reject(result)
                } else {
                    return result
                }

            })
            .catch(error => {
                return Promise.reject(error)
            })
            .finally(() => {
                uiStore.stopLoading();
            });

    }

    loadItems() {
        uiStore.startLoading();

        const filter = {
            pager: this.pager,
            sort: this.sort,
            title: this.title,
        }

        return parseAccountsService.loadItems(filter)
            .then(result => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
                this.setSort(result?.sort)

                return Promise.resolve("OK");
            })
            .catch(error => error)
            .finally(() => {
                uiStore.stopLoading();
            });

    }

    setItems = (items) => {
        this.items = items || [];
    }

    /**
     * @param {PagerAnswer} pager
     */
    setPager = (pager = {}) => {
        this.pager = {
            ...this.pager,
            ...pager,
        };
    }

    /**
     * @param {SortAnswer} sort
     */
    setSort = (sort) => {
        this.sort = sort || {};
    }

    /**
     * @param {string} value
     */
    setTitle = (value) => {
        this.title = value;
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    resetEntity = () => {
        this.entity = {}
    }

    resetFilter = () => {
        this.title = '';

        this.pager = {
            size: 10,
            number: 1,
        }

        this.sort = {
            column: 'username',
            order: 'desc'
        };
    }


}

class ParsingAccountsModel {
    id
    title
    username
    yandex
    gis
    flamp
    zoon
    imap
    enabled

    constructor(object = {}) {
        this.id = object?.id || null;
        this.title = object?.title || '';
        this.username = object?.username || '';
        this.yandex = object?.lkYandexPassword || '';
        this.gis = object?.lk2GisPassword || '';
        this.flamp = object?.lkFlampPassword || '';
        this.zoon = object?.lkZoonPassword || '';
        this.imap = object?.imapYandexPassword || '';
        this.enabled = true
    }

    getApiObject(object) {
        return {
            "id": object.id,
            "title": object.title,
            "username": object.username,
            "lkYandexPassword": object.yandex,
            "lk2GisPassword": object.gis,
            "lkFlampPassword": object.flamp,
            "lkZoonPassword": object.zoon,
            "imapYandexPassword": object.imap,
        }
    }
}

const parsingAccountsStore = new ParsingAccountsStore();
export default parsingAccountsStore;