import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import TitleH1 from "components/common/TitleH1";
import { InputText } from "primereact/inputtext";
import Activity from "components/common/Activity";

import "styles/pages/analytics.scss"
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import _ from "underscore";
import { reaction } from "mobx";
import Sortable from "components/common/Sortable";

import analyticsService from "services/Analytics";
import { Link } from "react-router-dom";

const FEATURE = [
    { name: 'Нет', code: 'disabled' },
    { name: 'Базовый', code: 'basic' },
    { name: 'Расширенный', code: 'extended' },
];

const AnalyticsServicesPage = ({ uiStore, breadcrumbsStore, analyticsServicesStore }) => {

    const { title, setTitle, items, pager, setPager, sort, setSort, setFeature, feature, resetFilter } = analyticsServicesStore;

    const [first, setFirst] = useState(0);


    useEffect(() => {
        resetFilter();

        uiStore.setPageProperty({
            page: 'analytics',
            className: 'analytics'
        });

        breadcrumbsStore.addItem({
            linkName: 'Услуги',
            link: `/analytics/services`,
        }, 0);

    }, []);

    useEffect(() => {
        analyticsServicesStore.loadItems()
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => analyticsServicesStore.pager.number,
            () => {
                analyticsServicesStore.loadItems()
            }
        );

        return () => disposer();
    }, []);


    useEffect(() => {
        const disposer = reaction(
            () => analyticsServicesStore.feature.extreview,
            () => {
                analyticsServicesStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => analyticsServicesStore.feature.analytics,
            () => {
                analyticsServicesStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => analyticsServicesStore.title,
            (value) => {
                titleDebounceHandler(value)
            }
        );

        return () => disposer();
    }, []);

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && analyticsServicesStore.loadItems(),
        800
    );

    useEffect(() => {
        const disposer = reaction(
            () => JSON.stringify(analyticsServicesStore.sort),
            () => {
                analyticsServicesStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    const sortHandler = (obj) => {
        setSort(obj);
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    color={'color-lilac'}
                >
                    Аналитика / Услуги
                </TitleH1>
            </div>

            <div className={cn('search-container')}>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Поиск..."
                            name='title'
                            id='title'
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </span>
                    </div>
                </div>

                <div className={cn('dropdown-container')}>
                    <div className="p-field">
                        <label htmlFor="extreview">Мониторинг</label>
                        <Dropdown
                            value={feature.extreview}
                            options={FEATURE}
                            onChange={(e) => {
                                setFeature(e.value, 'extreview');
                            }}
                            id="extreview"
                            name="extreview"
                            optionLabel="name"
                            optionValue="code"
                        />
                    </div>
                </div>
                <div className={cn('dropdown-container')}>
                    <div className="p-field">
                        <label htmlFor="analytics">Аналитика</label>
                        <Dropdown
                            value={feature.analytics}
                            options={FEATURE}
                            onChange={(e) => {
                                setFeature(e.value, 'analytics');
                            }}
                            id="analytics"
                            name="analytics"
                            optionLabel="name"
                            optionValue="code"
                        />
                    </div>
                </div>
            </div>

            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='title'
                            >Клиент</Sortable>
                        </th>
                        <th>Мониторинг</th>
                        <th>Аналитика</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, key) => (
                        <Row item={item} key={key}/>
                    ))}
                    </tbody>
                </table>

            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first} /* номер элемента для отсчета страниц*/
                    rows={pager.size} /*кол-во элементов на странице*/
                    totalRecords={pager.itemCount} /*всего элементов*/
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    );
};

AnalyticsServicesPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    analyticsServicesStore: PropTypes.any,
}

const Row = ({ item }) => {

    return (
        <tr>
            <td className={cn('id-body')}>{item.id}</td>
            <td className={cn('activity-data')}>
                <Activity activity={item.enabled} />
            </td>
            <td>
                <Link to={`/clients/${item.id}/detail`}>{item.title}</Link>
            </td>
            <td className={cn('col')}>
                {analyticsService.getServiceObject(item.values, 'extreview').value !== 'disabled' && <div>
                    <Activity activity={true} />
                    <span>
                        {analyticsService.getServiceObject(item.values, 'extreview').name}
                    </span>
                </div>}
            </td>
            <td className={cn('col')}>
                {analyticsService.getServiceObject(item.values, 'analytics').value !== 'disabled' && <div>
                    <Activity activity={true} />
                    <span>{analyticsService.getServiceObject(item.values, 'analytics').name}</span>
                </div>}
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.object
}

export default inject('uiStore', 'breadcrumbsStore', 'analyticsServicesStore')(observer(AnalyticsServicesPage));
