import React, { useEffect, useState } from "react";
import cn from "classnames";
import TitleH1 from "components/common/TitleH1";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import { Paginator } from "primereact/paginator";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Activity from "components/common/Activity";
import { reaction } from "mobx";
import _ from "underscore";
import Sortable from "components/common/Sortable";

import 'styles/pages/administrators.scss';

const AdministratorsPage = ({ uiStore, breadcrumbsStore, administratorStore }) => {

    let history = useHistory();

    const { lastname, setLastname, items, pager, setPager, sort, setSort, resetFilter } = administratorStore;

    const [first, setFirst] = useState(0);


    useEffect(() => {
        resetFilter();

        uiStore.setPageProperty({
            page: 'administrators',
            className: 'administrators'
        });

        breadcrumbsStore.addItem({
            linkName: 'Администраторы',
            link: '/administrators',
        }, 0);

    }, []);


    useEffect(() => {
        administratorStore.loadItems()
    }, []);

//TODO все реакции обьеденить в одну
    useEffect(() => {
        const disposer = reaction(
            () => administratorStore.pager.number,
            () => {
                administratorStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => administratorStore.lastname,
            (value) => {
                titleDebounceHandler(value)
            }
        );

        return () => disposer();
    }, []);

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && administratorStore.loadItems(),
        800
    );

    useEffect(() => {
        const disposer = reaction(
            () => JSON.stringify(administratorStore.sort),
            () => {
                administratorStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    const sortHandler = (obj) => {
        setSort(obj);
    }

    /** @namespace item.brand */
    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    color={'color-green-light'}
                >
                    Администраторы
                </TitleH1>
            </div>
            <div className={cn('add-container')}>
                <Button
                    className={cn('p-button-outlined')}
                    label="Создать администратора"
                    icon="pi pi-plus"
                    onClick={() => {
                        history.push('/administrators/new');
                    }}
                />
            </div>

            <div className={cn('search-container')}>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Поиск по фамилии..."
                            name='lastname'
                            id='lastname'
                            value={lastname}
                            onChange={(e) => {
                                // noinspection JSUnresolvedVariable
                                setLastname(e.target.value);
                            }}
                        />
                    </span>
                    </div>
                </div>
            </div>

            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='lastname'
                            >ФИО</Sortable>
                        </th>
                        <th>E-mail</th>
                        <th className={cn('date-head')}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='created'
                            >Создан</Sortable>
                        </th>
                        <th className={cn('date-head')}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='updated'
                            >Изменен</Sortable>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, key) => (
                        <tr key={key}>
                            <td className={cn('id-body')}>{item.id}</td>
                            <td className={cn('activity-data')}>
                                <Activity activity={item.enabled} />
                            </td>
                            <td>
                                <Link to={`/administrators/${item.id}/detail`}>{item.fullName}</Link>
                            </td>
                            <td>{item.email}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.updatedAt}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first} /* номер элемента для отсчета страниц*/
                    rows={pager.size} /*кол-во элементов на странице*/
                    totalRecords={pager.itemCount} /*всего элементов*/
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    );


};

AdministratorsPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    administratorStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'administratorStore')(observer(AdministratorsPage));
