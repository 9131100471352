import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import 'styles/activity.scss';

const ParserActivity = ({ activity }) => {

    let connected = {
        status: 0,
        title: '',
    }
    switch (activity) {
        case 0:
            connected = {
                status: 'warning',
                title: 'Неавторизован',
            }
            break;
        case 1:
            connected = {
                status: 'true',
                title: 'Авторизован',
            }
            break;
        case 2:
            connected = {
                status: 'connecting',
                title: 'Подключение',
            }
            break;

    }
    return (
        <div className={cn(
            'activity',
            `activity_active-${connected.status}`
        )}
        title={connected.title}
        />
    );
}

ParserActivity.propTypes = {
    activity: PropTypes.number,
}

export default ParserActivity;
