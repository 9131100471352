import SmallBranch from "./SmallBranch";
import Employee from "./Employee";

export default class BranchManagerLink {
    #_branch;
    #_employee;
    #_linked;

    constructor(branchManagerLink) {
        this.#_branch = (branchManagerLink.branch) ? new SmallBranch(branchManagerLink.branch) : new SmallBranch();
        this.#_employee = (branchManagerLink.employee) ? new Employee(branchManagerLink.employee) : new Employee();
        this.#_linked = branchManagerLink.linked || false;
    }

    /**
     * @return {SmallBranch}
     */
    get branch() {
        return this.#_branch;
    }

    /**
     * @return {Employee}
     */
    get employee() {
        return this.#_employee;
    }

    /**
     * @return {boolean}
     */
    get linked() {
        return this.#_linked;
    }
}
