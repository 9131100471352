import cn from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import 'styles/sortable.scss';

const sortState = {
    default: {
        order: 'default',
        ico: 'pi-sort-alt'
    },
    asc: {
        order: 'asc',
        ico: 'pi-sort-amount-down',
    },
    desc: {
        order: 'desc',
        ico: 'pi-sort-amount-up-alt',
    }
};

/**
 *
 * @param children
 * @param onSort
 * @param sort {{column: string, order: string}}
 * @param column {string}
 * @returns {JSX.Element}
 * @constructor
 */
const Sortable = ({ children, onSort, sort, column }) => {

    const [sorting, setSorting] = useState(sortState.default);

    useEffect(() => {
        if (sort.column === column) {
            sort.order === 'asc' ?
                setSorting(sortState.asc) :
                setSorting(sortState.desc);
        } else {
            setSorting(sortState.default)
        }
    }, [sort])

    const handlerSort = () => {
        switch (sorting.order) {
            case 'asc':
                setSorting(() => {
                    onSort({
                        column: column,
                        order: sortState.desc.order,
                    });

                    return sortState.desc;
                });
                break;
            case 'desc':
            case 'default':
                setSorting(() => {
                    onSort({
                        column: column,
                        order: sortState.asc.order,
                    });
                    return sortState.asc;
                });

        }
    }

    return (
        <div
            className={cn('sortable')}
            onClick={handlerSort}
        >
            <nobr>{children}
            <span
                className={cn('pi', sorting.ico)}
            /></nobr>
        </div>
    )
}

Sortable.propTypes = {
    children: PropTypes.string,
    onSort: PropTypes.func,
    sort: PropTypes.object,
    column: PropTypes.string,
}

export default Sortable;
