import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import cn from "classnames";
import TitleH1 from "components/common/TitleH1";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from 'primereact/skeleton';
import Activity from "components/common/Activity";
import "styles/pages/analytics.scss"
import Sortable from "components/common/Sortable";
import { reaction } from "mobx";
import _ from "underscore";



const AnalyticsActivityPage = ({ uiStore, breadcrumbsStore, analyticsActivityStore }) => {

    const { title, setTitle, items, idle, setIdle, pager, setPager, sort, setSort, resetFilter } = analyticsActivityStore;

    const [first, setFirst] = useState(0);
    const [showUsersBlock, setShowUsersBlock] = useState(false);


    useEffect(() => {
        resetFilter();

        uiStore.setPageProperty({
            page: 'analytics',
            className: 'analytics'
        });

        breadcrumbsStore.addItem({
            linkName: 'Активность',
            link: `/analytics/activity`,
        }, 0);

        setShowUsersBlock(false);
    }, []);

    useEffect(() => {
        analyticsActivityStore.loadItems()
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => analyticsActivityStore.title,
            (value) => {
                titleDebounceHandler(value)
            }
        );

        return () => disposer();
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => analyticsActivityStore.pager.number,
            () => {
                analyticsActivityStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && analyticsActivityStore.loadItems(),
        800
    );

    useEffect(() => {
        const disposer = reaction(
            () => analyticsActivityStore.idle,
            () => {
                analyticsActivityStore.loadItems()
            }
        );

        return () => disposer();
    }, []);


    useEffect(() => {
        const disposer = reaction(
            () => JSON.stringify(analyticsActivityStore.sort),
            () => {
                analyticsActivityStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    const sortHandler = (obj) => {
        setSort(obj);
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1>
                    Аналитика / Активность
                </TitleH1>
            </div>

            <div className={cn('search-container')}>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Поиск..."
                            name='title'
                            id='title'
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </span>
                    </div>
                </div>
                <div className={cn('dropdown-container')}>
                    <div className="p-field">
                        <label htmlFor="activity">Без активности</label>
                        <Dropdown
                            value={idle}
                            options={[
                                { name: 'Нет', code: {} },
                                { name: 'От 8 до 30 дней', code: { from: 8, to: 30 } },
                                { name: 'Больше 30 дней', code: { from: 30, to: 99999 } },
                            ]}
                            onChange={(e) => {
                                setIdle(e.value);
                            }}
                            id="activity"
                            name="activity"
                            optionLabel="name"
                            optionValue="code"
                        />
                    </div>
                </div>
            </div>

            <div className={cn('table-container')}>
                <div className={cn('table-container__content')}>
                    <table>
                        <thead>
                        <tr>
                            <th className={cn('id-head')} colSpan={2}>ID</th>
                            <th className={cn('title-head')} colSpan={2}>
                                <Sortable
                                    onSort={(obj) => sortHandler(obj)}
                                    sort={sort}
                                    column='title'
                                >Клиент / Пользователи</Sortable>
                            </th>
                            <th className={cn('userid-head')}>ID польз-ля</th>
                            <th>
                                <Sortable
                                    onSort={(obj) => sortHandler(obj)}
                                    sort={sort}
                                    column='activity'
                                >Последнее<br/>действие</Sortable>
                            </th>
                            <th>
                                <Sortable
                                    onSort={(obj) => sortHandler(obj)}
                                    sort={sort}
                                    column='page_open'
                                >Открыта<br/>страница</Sortable>
                            </th>
                            <th>
                                <Sortable
                                    onSort={(obj) => sortHandler(obj)}
                                    sort={sort}
                                    column='send_application'
                                >Отправлена<br/>заявка</Sortable>
                            </th>
                            <th>
                                <Sortable
                                    onSort={(obj) => sortHandler(obj)}
                                    sort={sort}
                                    column='send_answer'
                                >Отправлен<br/>ответ</Sortable>
                            </th>
                            <th>
                                <Sortable
                                    onSort={(obj) => sortHandler(obj)}
                                    sort={sort}
                                    column='send_claim'
                                >Отправлена<br/>жалоба</Sortable>
                            </th>
                            <th>
                                <Sortable
                                    onSort={(obj) => sortHandler(obj)}
                                    sort={sort}
                                    column='notification_telegram'
                                >Подписки<br/>на ТГ</Sortable>
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item, key) => (
                            <Row item={item} key={`${key}_${item.id}`} isShow={showUsersBlock} />
                        ))}
                        </tbody>
                    </table>
                </div>

            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first} /* номер элемента для отсчета страниц*/
                    rows={pager.size} /*кол-во элементов на странице*/
                    totalRecords={pager.itemCount} /*всего элементов*/
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>
        </div>
    );
};
AnalyticsActivityPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    analyticsActivityStore: PropTypes.any,
}

const Row = inject('analyticsActivityStore')(observer(({ item, analyticsActivityStore, isShow }) => {
    const [show, setShow] = useState(isShow);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const handleClick = () => {
        setShow(!show);
    }

    useEffect(() => {
        if (show === true && users.length <= 0) {
            setIsLoading(true);
            analyticsActivityStore.loadClientUsers(item.id)
                .then(clients => setUsers(clients))
                .finally(() => setIsLoading(false));

        }
    }, [show]);

    return (
        <>
            <tr>
                <td className={cn('id-body')}>{item.id}</td>
                <td className={cn('activity-data')}>
                    <Activity activity={item.enabled} />
                </td>
                <td className={cn('title')}>
                    <Link to={`/clients/${item.id}/detail`}>{item.title}</Link>
                </td>
                <td className={cn('user-data')}>
                    {item.metrics.users >= 1 && <BtnShowUser
                        show={show}
                        handleClick={handleClick}
                        userCount={item.metrics.users}
                    />}
                </td>
                <td className={cn('col')}>&nbsp;</td>
                <td className={cn('col')}>{item.metrics.lastAction}</td>
                <td className={cn('col')}>{item.metrics.actions.open}</td>
                <td className={cn('col')}>{item.metrics.actions.send_application}</td>
                <td className={cn('col')}>{item.metrics.actions.send_answer}</td>
                <td className={cn('col')}>{item.metrics.actions.send_claim}</td>
                <td className={cn('col')}>{item.metrics.notifications.telegram}</td>
            </tr>
            {item.metrics.users >= 1 && isLoading && <RowLoader count={item.metrics.users}/>}
            {users.length >= 1 && show && <UserList items={users} />}
        </>
    );

}));
Row.propTypes = {
    item: PropTypes.object,
    isShow: PropTypes.bool,
}

const RowLoader = ({ count }) => {
    return (
        <>
            {[...Array(count)].map((val, key) =>
                <tr key={key}>
                    <td colSpan={4}>
                        <Skeleton/>
                    </td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                </tr>
            )}
        </>
    )
}
RowLoader.propTypes = {
    count: PropTypes.number
}

const UserList = ({ items }) => {

    return (
        <>
            {items.map((item, key) => (
                <tr className={cn('user-list')} key={`${key}_${item.id}`}>
                    <td colSpan={2}>&nbsp;</td>
                    <td colSpan={2} className={cn('title')}>
                        <div>
                            <Activity activity={item.enabled} />
                            <Link to={`/clients/${item.clientId}/users/${item.id}/detail`}>{item.fullName}</Link>
                        </div>
                    </td>
                    <td>{item.id}</td>
                    <td>{item.metrics.lastAction}</td>
                    <td>{item.metrics.actions.open}</td>
                    <td>{item.metrics.actions.send_application}</td>
                    <td>{item.metrics.actions.send_answer}</td>
                    <td>{item.metrics.actions.send_claim}</td>
                    <td>{item.metrics.notifications.telegram}</td>
                </tr>
            ))}
        </>
    );
};
UserList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    show: PropTypes.bool,
};


const BtnShowUser = ({ show, handleClick, userCount }) => {
    const btnState = show ? 'open' : 'closed';

    return (
        <div
            className={cn('btn-show-users', `btn-show-users_${btnState}`)}
            onClick={() => {
                handleClick()
            }}
        >
            <span className={cn('ico', 'avatar__blue')} />
            <span className={cn('btn-show-users__text')}>{userCount}</span>
            <span className={cn('pi', 'pi-chevron-up')} />
            <span className={cn('pi', 'pi-chevron-down')} />
        </div>
    );
};
BtnShowUser.propTypes = {
    show: PropTypes.bool,
    handleClick: PropTypes.func,
    userCount: PropTypes.number,
}

export default inject('uiStore', 'breadcrumbsStore', 'analyticsActivityStore')(observer(AnalyticsActivityPage));
