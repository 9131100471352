import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";
import _ from "underscore";
import cn from "classnames";
import TitleH1 from "components/common/TitleH1";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import Sortable from "components/common/Sortable";
import Activity from "components/common/Activity";
import { Link } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";

const AnalyticsParsingPage = ({ uiStore, breadcrumbsStore, analyticsParsingStore }) => {

    const { title, setTitle, items, parse, setParse, pager, setPager, sort, setSort, resetFilter } = analyticsParsingStore;
    const [first, setFirst] = useState(0);
    const [showBranchesBlock, setShowBranchesBlock] = useState(false);

    useEffect(() => {
        resetFilter();

        uiStore.setPageProperty({
            page: 'analytics',
            className: 'analytics'
        });

        breadcrumbsStore.addItem({
            linkName: 'Парсинг',
            link: `/analytics/parsing`,
        }, 0);

        setShowBranchesBlock(false);
    }, []);

    useEffect(() => {
        analyticsParsingStore.loadItems()
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => analyticsParsingStore.title,
            (value) => {
                titleDebounceHandler(value)
            }
        );

        return () => disposer();
    }, []);

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && analyticsParsingStore.loadItems(),
        800
    );

    useEffect(() => {
        const disposer = reaction(
            () => analyticsParsingStore.pager.number,
            () => {
                analyticsParsingStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => analyticsParsingStore.parse,
            () => {
                analyticsParsingStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => JSON.stringify(analyticsParsingStore.sort),
            () => {
                analyticsParsingStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    const sortHandler = (obj) => {
        setSort(obj);
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1>
                    Аналитика / Парсинг
                </TitleH1>
            </div>
            <div className={cn('search-container')}>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Поиск..."
                            name='title'
                            id='title'
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </span>
                    </div>
                </div>
                <div className={cn('dropdown-container')}>
                    <div className="p-field">
                        <label htmlFor="parse">Время с последнего парсинга</label>
                        <Dropdown
                            value={parse}
                            options={[
                                { name: 'Любое', code: {} },
                                { name: 'Больше 12 часов', code: { from: 1, to: 12 } },
                                { name: 'Больше 24 часов', code: { from: 12, to: 24 } },
                                { name: 'Больше 2 суток', code: { from: 24, to: 48 } },
                                { name: 'Больше 3 суток', code: { from: 48, to: 72 } },
                                { name: 'Больше 7 суток', code: { from: 168, to: 2400 } },
                            ]}
                            onChange={(e) => {
                                setParse(e.value);
                            }}
                            id="parse"
                            name="parse"
                            optionLabel="name"
                            optionValue="code"
                        />
                    </div>
                </div>
            </div>

            <div className={cn('table-container', 'parsing-table')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th colSpan={2}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='title'
                            >Клиент</Sortable>
                        </th>
                        <th>ID филиала</th>
                        <th>Avito</th>
                        <th>Google</th>
                        <th>Яндекс.Карты</th>
                        <th>2ГИС</th>
                        <th>Отзовик</th>
                        <th>Flamp</th>
                        <th>Irecommend</th>
                        <th>Вконтакте</th>
                        <th>Zoon</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, key) => (
                        <Row item={item} key={`${key}_${item.id}`} isShow={showBranchesBlock} />
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first} /* номер элемента для отсчета страниц*/
                    rows={pager.size} /*кол-во элементов на странице*/
                    totalRecords={pager.itemCount} /*всего элементов*/
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    )
};

AnalyticsParsingPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    analyticsParsingStore: PropTypes.any,
};

const Row = inject('analyticsParsingStore')(observer(({ item, analyticsParsingStore, isShow }) => {
    const [show, setShow] = useState(isShow);
    const [isLoading, setIsLoading] = useState(false);
    const [branches, setBranches] = useState([]);

    const handleClick = () => {
        setShow(!show);
    }

    useEffect(() => {
        if (show === true && branches.length <= 0) {
            setIsLoading(true);
            analyticsParsingStore.loadClientBranches(item.id)
                .then(branches => {
                    setBranches(branches)
                })
                .finally(() => setIsLoading(false));

        }
    }, [show]);

    return (
        <>
            <tr>
                <td className={cn('id-body')}>{item.id}</td>
                <td className={cn('activity-data')}>
                    <Activity activity={item.enabled} />
                </td>
                <td className={cn('title')}>
                    <Link to={`/clients/${item.id}/detail`} >{item.title}</Link>
                </td>
                <td className={cn('user-data')}>
                    {item.metrics.branches >= 1 && <BtnShowUser
                        show={show}
                        handleClick={handleClick}
                        userCount={item.metrics.branches}
                    />}
                </td>
                <td className={cn('col')}>&nbsp;</td>

                {item.metrics.sources.map((source, key) => (
                    <td className={cn('col')} key={key+source.code} title={source.lastParseTime()}>{source.lastParse}</td>
                ))}
            </tr>
            {item.metrics.branches >= 1 && isLoading && <RowLoader count={item.metrics.branches}/>}
            {branches.length >= 1 && show && <UserList items={branches} />}
        </>
    );

}));
Row.propTypes = {
    item: PropTypes.object,
    isShow: PropTypes.bool,
}

const RowLoader = ({ count }) => {
    return (
        <>
            {[...Array(count)].map((val, key) =>
                <tr key={key}>
                    <td colSpan={4}>
                        <Skeleton/>
                    </td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                </tr>
            )}
        </>
    )
}
RowLoader.propTypes = {
    count: PropTypes.number
}

const UserList = ({ items }) => {

    return (
        <>
            {items.map((item, key) => {
                return (
                <tr className={cn('user-list')} key={`${key}_${item.id}`}>
                    <td colSpan={2}>&nbsp;</td>
                    <td colSpan={2} className={cn('title')}>
                        <div>
                            <Activity activity={item.enabled} />
                            <Link to={`/clients/${item.clientId}/branches/${item.id}/detail`}>{item.title}</Link>
                        </div>
                    </td>
                    <td>{item.id}</td>
                        {item.metrics.sources.map((source, key2) => (
                            <td  key={key2+source.code}>{source.lastParse}</td>
                        ))}
                </tr>
            )})}
        </>
    );
};
UserList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    show: PropTypes.bool,
};


const BtnShowUser = ({ show, handleClick, userCount }) => {
    const btnState = show ? 'open' : 'closed';

    return (
        <div
            className={cn('btn-show-users', `btn-show-users_${btnState}`)}
            onClick={() => {
                handleClick()
            }}
        >
            <span className={cn('ico', 'avatar__blue')} />
            <span className={cn('btn-show-users__text')}>{userCount}</span>
            <span className={cn('pi', 'pi-chevron-up')} />
            <span className={cn('pi', 'pi-chevron-down')} />
        </div>
    );
};
BtnShowUser.propTypes = {
    show: PropTypes.bool,
    handleClick: PropTypes.func,
    userCount: PropTypes.number,
}



export default inject('uiStore', 'breadcrumbsStore', 'analyticsParsingStore')(observer(AnalyticsParsingPage));

