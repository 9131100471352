import { api } from "../core";

class AdPlatformTypeService {
    getAll() {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/types`)
            .then(result => {
                return result;
            })
            .catch(error => {
                return error;
            });

    }
}

const adPlatformTypeService = new AdPlatformTypeService();
export default adPlatformTypeService;