import moment from "moment";

export class AdPlatformPublicationConnectionInfo {
    #id;
    #type;

    constructor(adPlatformPublicationConnectionInfo) {
        this.#id = adPlatformPublicationConnectionInfo.id || null;
        this.#type = adPlatformPublicationConnectionInfo.type || null;
    }

    get id() {
        return this.#id;
    }

    get type() {
        return this.#type;
    }

    getPlainObject() {
        return {
            id: this.id,
            type: this.type,
        }
    }
}

export class AdPlatformPublicationConnection {
    #enabled;
    #deleted;
    #info;
    #createdAt;
    #updatedAt;
    #deletedAt;

    constructor(adPlatformPublicationConnection) {
        this.#enabled = adPlatformPublicationConnection.enabled || false;
        this.#deleted = adPlatformPublicationConnection.deleted || false;
        this.#info = new AdPlatformPublicationConnectionInfo(adPlatformPublicationConnection.info || {});
        this.#createdAt = adPlatformPublicationConnection.createdAt || null;
        this.#updatedAt = adPlatformPublicationConnection.updatedAt || null;
        this.#deletedAt = adPlatformPublicationConnection.deletedAt || null;
    }

    get enabled() {
        return this.#enabled;
    }

    get deleted() {
        return this.#deleted;
    }

    get info() {
        return this.#info;
    }

    get createdAt() {
        return this.#createdAt ? moment(this.#createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get updatedAt() {
        return this.#updatedAt ? moment(this.#updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get deletedAt() {
        return this.#deletedAt ? moment(this.#deletedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    getPlainObject() {
        return {
            enabled: this.enabled,
            deleted: this.deleted,
            info: this.info.getPlainObject(),
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt,
        }
    }
}

export default class AdPlatformPublication {
    #connection;

    constructor(adPlatformPublication) {
        this.#connection = new AdPlatformPublicationConnection(adPlatformPublication.connection || {});
    }

    get connection() {
        return this.#connection;
    }

    getPlainObject() {
        return {
            connection: this.connection.getPlainObject(),
        }
    }

    static getEmptyEntity() {
        return new AdPlatformPublication({});
    }
}

