import React, { useEffect, useState } from "react";
import cn from "classnames";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { InputSwitch } from "primereact/inputswitch";
import TitleH4 from "components/common/TitleH4";
import { AD_PLATFORM_TYPE } from "models/AdPlatform";
import 'styles/parsing.scss';
import OkDropdown from "../OkDropdown";
import { integrationService } from "../../../services";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";

const BlockParser = ({
                         adPlatformParserStore,
                         integrationId = null,
                         type = null,
                         clientId = null,
                         disabled = false,
                         onSave = false,
                         handleDisabled = () => {
                         },
                         active = true,
                         activePlatformType = {},
                     }) => {

    const { entity, setEntity } = adPlatformParserStore;

    const [select, setSelect] = useState([]);
    const [value, setValue] = useState(null);
    const [displayErrorModal, setDisplayErrorModal] = useState(false);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [messageShown, setMessageShown] = useState(false);

    useEffect(() => {
        integrationService
            .getIntegrations({ clientId: clientId, type: '2gis', page: { size: 100 } }).then(result => {
            let select = [];
            result.items.map(item => {
                select.push({
                    code: item.id,
                    title: item.title,
                });
            });
            setSelect(select);
            return Promise.resolve({})
        })
            .then(() => {
                adPlatformParserStore.resetEntity().then(() => {
                    adPlatformParserStore.loadEntity(integrationId);
                })
            });
    }, []);

//инициализация Идентификатора связи с площадкой 2ГИС
    useEffect(() => {
        setValue(adPlatformParserStore.entity.connection.parent);
    }, [adPlatformParserStore.entity.connection.parent])

    useEffect(() => {
        (entity.connection.source.id && onSave) && save();
    }, [onSave])

    useEffect(() => {
        !active && setEntity({
            parsing: {
                ...entity.parsing,
                ...{
                    enabled: active
                },
            },
            geodata: {
                ...entity.geodata,
                ...{
                    enabled: active
                },
            },
        })
    }, [active])

    useEffect(() => {
        handleDisabled(entity?.parsing.enabled);

        if (!entity?.parsing.enabled) {
            setEntity({
                geodata: {
                    ...entity.geodata,
                    ...{
                        enabled: false
                    },
                },
            })
        }
    }, [entity?.parsing.enabled]);


    const save = () => {
        if (entity?.connection.source.extreview) {
            adPlatformParserStore.saveEntity(integrationId, value);
        } else {
            adPlatformParserStore.addEntity(integrationId, value);
        }
    }

    const checkDisabledGeoData = () => {
        return (!active || !entity?.parsing.enabled)
    }

    const renderFooter = () => {
        return (
            <div className={'clients'}>
                <div className={cn('confirmation-footer', 'pdf-confirmation')}>
                    <Button
                        label="Понятно"
                        icon="pi pi-check"
                        className={cn('p-button-outlined')}
                        onClick={() => {
                            setDisplayConfirmation(false)
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <>
            {entity && <>
                <div className={cn('edit-form__row')}>
                    <div className={cn('edit-form__col', 'edit-form__col_large')}>
                        <TitleH4
                            ico={'chain_gradient'}
                            showBadge={disabled}
                            active={entity?.connection.enabled}
                        >
                            Идентификатор парсинга площадки
                        </TitleH4>
                        <div className={cn("p-field", 'pt-0')}>
                        <span className="p-float-label">
                                <InputText
                                    id='parserId'
                                    name='parserId'
                                    type='text'
                                    value={entity?.connection.source.id ?? ''}
                                    //disabled={entity?.connection.enabled || disabled}
                                    disabled={disabled}
                                    onChange={(e) => {
                                        setEntity({
                                            connection: {
                                                ...entity.connection,
                                                ...{
                                                    source: {
                                                        ...entity.connection.source,
                                                        ...{ id: e.target.value }
                                                    }
                                                },
                                            },
                                        });
                                    }}
                                    onClick={()=>{
                                        if(!messageShown) {
                                            setDisplayConfirmation(true);
                                            setMessageShown(true)
                                        }
                                    }}
                                />
                            <label htmlFor="url">Идентификатор парсинга площадки</label>
                            <Dialog
                                header="Внимание"
                                visible={displayConfirmation}
                                modal
                                footer={renderFooter()}
                                onHide={() => {
                                    setDisplayConfirmation(false)

                                }}>
                                <div className="confirmation-content">
                                    <span>
                                        При изменении идентификатора парсинга и сохранении изменений<br/>все текущие собранные данные для мониторинга и аналитики будут удалены навсегда.
                                    </span>
                                </div>
                            </Dialog>

                        </span>
                            {/* TODO добавить динамику из обьекта activePlatformType */}
                            {type !== AD_PLATFORM_TYPE.FLAMP && <div className={cn('parser__status')}>
                                {entity.auth.enabled ? <span>Источник авторизован</span> :
                                    <span>Источник не авторизован</span>}
                                &nbsp;
                                {entity.parsing.enabled ? <span>Сбор отзывов с источника разрешён</span> :
                                    <span>Сбор отзывов с источника не разрешён</span>}
                                &nbsp;<span>{entity.parsing.connect_status}</span>
                            </div>}
                        </div>
                    </div>

                    {entity?.connection.enabled && disabled &&
                        <div className={cn('edit-form__col', 'edit-form__col_flex', 'field__account')}>
                            <div className={cn("p-field")} style={{ width:'300px' }}>
                            <span className="p-float-label">
                                    <InputText
                                        id='account'
                                        name='account'
                                        type='text'
                                        value={entity.auth.account || ''}
                                        disabled={true}
                                    />
                                <label htmlFor="account">Технический аккаунт</label>
                            </span>
                            </div>
                        </div>
                    }

                </div>

                <div className={cn('edit-form__row')}>
                    <div className={cn('edit-form__col', 'edit-form__col_large')}>
                        <TitleH4
                            ico={''}
                            showBadge={disabled}
                            active={entity?.parsing.enabled}
                        >
                            Функционал мониторинга отзывов
                        </TitleH4>
                    </div>
                    {!disabled && <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                        <div className={cn('switch__wrapper', 'mb-20')}>
                            <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                                <div className={cn('switch', 'switch-activity')}>
                                    <InputSwitch
                                        checked={entity?.parsing.enabled}
                                        disabled={!active}
                                        onChange={(e) => {
                                            setEntity({
                                                parsing: {
                                                    ...entity.parsing,
                                                    ...{
                                                        enabled: e.value
                                                    },
                                                },
                                            })
                                        }}

                                    />
                                    <span className={cn('switch__text', { 'disabled': !active })}
                                    >{entity.connection.enabled ? 'Активен' : 'Не активeн'}</span>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {activePlatformType?.features.includes('geodata') && <div className={cn('edit-form__row')}>
                    <div className={cn('edit-form__col', 'edit-form__col_large')}>
                        <TitleH4
                            ico={''}
                            showBadge={disabled}
                            active={entity?.geodata.enabled}
                        >
                            Функционал управления геоданными
                        </TitleH4>
                    </div>
                    {!disabled && <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                        <div className={cn('switch__wrapper', 'mb-20')}>
                            <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                                <div className={cn('switch', 'switch-activity')}
                                     onClick={() => {
                                         checkDisabledGeoData() && setDisplayErrorModal(true)
                                     }}
                                >
                                    <InputSwitch
                                        checked={entity?.geodata.enabled}
                                        disabled={checkDisabledGeoData()}
                                        onChange={(e) => {
                                            setEntity({
                                                geodata: {
                                                    ...entity.geodata,
                                                    ...{
                                                        enabled: e.value
                                                    },
                                                },
                                            })
                                        }}

                                    />
                                    <span className={cn('switch__text', { 'disabled': checkDisabledGeoData() })}
                                    >{entity.geodata.enabled ? 'Активен' : 'Не активeн'}</span>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <Dialog
                        header="Внимание"
                        visible={displayErrorModal}
                        modal
                        onHide={() => {
                            setDisplayErrorModal(false)
                        }}>
                        <div className="confirmation-content">
                            <span>
                                Активация управления геоданных возможна <br/>только при активном функционале мониторинга отзывов
                            </span>
                        </div>
                    </Dialog>
                </div>}
            </>
            }
            {type === AD_PLATFORM_TYPE.FLAMP &&
                <div className={cn("edit-form__row", "pt-10")}>
                    <div className={cn('edit-form__col', 'edit-form__col_large')}>
                        <div className={cn("p-field", 'pt-0')}>
                    <span className="p-float-label">
                            <OkDropdown
                                value={value}
                                options={select}
                                isrequired="true"
                                onChange={(e) => {
                                    setValue(e.value)
                                }}
                                id="list"
                                name="list"
                                optionLabel="title"
                                optionValue="code"
                                disabled={disabled}
                            />
                                <label htmlFor="name">Идентификатор связи с площадкой 2ГИС</label>
                       </span>
                        </div>
                    </div>
                </div>

            }
        </>
    );
}

BlockParser.propTypes = {
    adPlatformParserStore: PropTypes.any,
    integrationId: PropTypes.any,
    type: PropTypes.string,
    clientId: PropTypes.string,
    disabled: PropTypes.bool,
    onSave: PropTypes.bool,
    handleDisabled: PropTypes.any,
    active: PropTypes.bool,
    activePlatformType: PropTypes.any,
}

export default inject(
    'adPlatformParserStore',
)(observer(BlockParser));

