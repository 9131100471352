import { action, makeObservable, observable } from "mobx";

class UiStore {

    message = {
        severity: 'success',
        summary: 'header',
        detail: 'detail',
        sticky: true,
        life: 3000,
        closable: true,
    };

    isLoading = false;

    pageProperty = {
        name: '',
        className: '',
        link: '',
        linkName: '',
    }

    close = false;

    constructor() {
        makeObservable(this,{
            message: observable,
            isLoading: observable,
            pageProperty: observable,
            close: observable,
            setMessage: action,
            startLoading: action,
            stopLoading: action,
            setPageProperty: action,
            closeMessage: action,
        })
    }

    closeMessage() {
        this.close = true;
    }

    setMessage = (message = {}) => {
        this.message = {
            ...this.message,
            ...message,
        };
    }

    startLoading = () => {
        this.isLoading = true;
    }

    stopLoading = () => {
        this.isLoading = false;
    }

    setPageProperty = (property = {}) => {
        this.pageProperty = {
            ...this.pageProperty,
            ...property,
        };
    }

    getPageProperty = () => {
        return this.pageProperty;
    }
}

const uiStore = new UiStore();
export default uiStore;
