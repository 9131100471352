/**
 * @author Roman Zaycev <roman.zaycev@olmisoft.com>
 */
export default class ClientFeature {
    #feature;
    #title;
    #type;
    #value;
    #parent;

    constructor(clientFeature) {
        this.#feature = clientFeature.feature || null;
        this.#title = clientFeature.title || '';
        this.#type = clientFeature.type || null;
        this.#value = (clientFeature.type === 'boolean' && !clientFeature.value) ? false : clientFeature.value;
        this.#parent = clientFeature.parent || null;
    }

    /**
     * @return {string|null}
     */
    get feature() {
        return this.#feature;
    }

    /**
     * @return {string}
     */
    get title() {
        return this.#title;
    }

    /**
     * @return {string|null}
     */
    get type() {
        return this.#type;
    }

    /**
     * @return {boolean|string|number|null}
     */
    get value() {
        return this.#value;
    }

    /**
     * @return {string|null}
     */
    get parent() {
        return this.#parent;
    }

    /**
     * @return {ClientFeature}
     */
    getPlainObject() {
        // noinspection JSValidateTypes
        return {
            feature: this.feature,
            title: this.title,
            type: this.type,
            value: this.value,
            parent: this.parent,
        };
    }
}
