import moment from "moment";

export class AdPlatformReviewConnectionInfo {
    #type;
    #url;

    constructor(adPlatformReviewConnectionInfo) {
        this.#type = adPlatformReviewConnectionInfo.type || '';
        this.#url = adPlatformReviewConnectionInfo.url || '';
    }

    get type() {
        return this.#type;
    }

    get url() {
        return this.#url;
    }

    getPlainObject() {
        return {
            type: this.type,
            url: this.url,
        }
    }

    static getEmptyEntity() {
        return new AdPlatformReviewConnectionInfo({});
    }

}

export class AdPlatformReviewConnection {
    #enabled;
    #deleted;
    #info;
    #createdAt;
    #updatedAt;
    #deletedAt;

    constructor(adPlatformReviewConnection) {
        this.#enabled = adPlatformReviewConnection.enabled || false;
        this.#deleted = adPlatformReviewConnection.deleted || false;
        this.#info = new AdPlatformReviewConnectionInfo(adPlatformReviewConnection.info || {});
        this.#createdAt = adPlatformReviewConnection.createdAt || null;
        this.#updatedAt = adPlatformReviewConnection.updatedAt || null;
        this.#deletedAt = adPlatformReviewConnection.deletedAt || null;
    }

    get enabled() {
        return this.#enabled;
    }

    get deleted() {
        return this.#deleted;
    }

    get info() {
        return this.#info;
    }

    get createdAt() {
        return this.#createdAt ? moment(this.#createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get updatedAt() {
        return this.#updatedAt ? moment(this.#updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get deletedAt() {
        return this.#deletedAt ? moment(this.#deletedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    getPlainObject() {
        return {
            enabled: this.enabled,
            deleted: this.deleted,
            info: this.info.getPlainObject(),
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt,
        }
    }

    static getEmptyEntity() {
        return new AdPlatformReviewConnection({});
    }

}

export default class AdPlatformReview {
    #connection;

    constructor(adPlatformReview) {
        this.#connection = new AdPlatformReviewConnection(adPlatformReview.connection || {});
    }

    get connection() {
        return this.#connection;
    }

    getPlainObject() {
        return {
            connection: this.connection.getPlainObject(),
        }
    }

    static getEmptyEntity() {
        return new AdPlatformReview({});
    }

}
