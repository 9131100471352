export default class AdPlatformTypeObj {
    #code;
    #name;

    constructor(obj) {
        this.#code = obj.code || '';
        this.#name = obj.name || '';
    }

    /**
     * @return {string}
     */
    get code() {
        return this.#code;
    }

    /**
     * @return {string}
     */
    get name() {
        return this.#name;
    }
}
