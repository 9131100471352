class MenuService {

    menu = [
        {
            name: 'Клиенты',
            link: '/clients',
            active: false,
            child: [
                {
                    name: 'Компании',
                    link: '/companies',
                    active: false,
                },
                {
                    name: 'Филиалы',
                    link: '/branches',
                    active: false,
                },
                {
                    name: 'Интеграции',
                    link: '/integration',
                    active: false,
                },
                {
                    name: 'Пользователи',
                    link: '/users',
                    active: false,
                },
            ]
        },
        {
            name: 'Список интеграций',
            link: '/integration',
            active: false,
        },
        {
            name: 'Список филиалов',
            link: '/branches',
            active: false,
        },

        {
            name: 'Администраторы',
            link: '/administrators',
            active: false,
        },
        {
            name: 'Справочники',
            link: '/references/company-groups',
            active: false,
            child: [
                {
                    name: 'Группы компаний ',
                    link: '/company-groups',
                    active: false,
                },
                {
                    name: 'Бренды филиалов',
                    link: '/brands',
                    active: false,
                },
                {
                    name: 'Локации',
                    link: '/locations',
                    active: false,
                },
                {
                    name: 'Типы филиалов',
                    link: '/branch-types',
                    active: false,
                },
                {
                    name: 'Правила автотегов',
                    link: '/tags',
                    active: false,
                },

            ]
        },
        {
            name: 'Аналитика',
            link: '/analytics/services',
            active: false,
            child: [
                {
                    name: 'Услуги',
                    link: '/services',
                    active: false,
                },
                {
                    name: 'Активность',
                    link: '/activity',
                    active: false,
                },
            ]
        },
    ]

    constructor() {
    }
}

const menuService = new MenuService();
export default menuService;
