import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { menuService } from "services";

const Menu = () => {

    return (
        <ul className={cn('menu')}>
            {menuService.menu.map((item, key) => (
                <li
                    className={cn('menu__item')}
                    key={key}
                >
                    <NavLink to={item.link}>{item.name}</NavLink>
                </li>
            ))}
        </ul>
    );
}

export default Menu;
