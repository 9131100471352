import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const FieldMessageError = ({ errors = [], code = null }) => {

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const message = getErrorMessage(code, errors);
        setErrorMessage(message);
    }, [])

    const getErrorMessage = (code, errorsArray) => {
        const error = errorsArray.find(error => {
            return error.field === code;
        })
        return error?.message || null;
    }

    return (
        <>
            {errorMessage && <span className={'error-message'}>{errorMessage}</span>}
        </>
    )
}

FieldMessageError.propTypes = {
    errors: PropTypes.array,
    code: PropTypes.string,
}


export default FieldMessageError;