import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import 'styles/tag.scss';

const Tag = ({ children, iconClass, tagClass }) => {
    return (
        <div className={cn('tag', tagClass)}>
            <span className={cn('ico', iconClass)} />
            <span className={cn('text')}>{children}</span>
        </div>
    )
}

Tag.propTypes = {
    children: PropTypes.number,
    iconClass: PropTypes.string,
    tagClass: PropTypes.string,
}

export default Tag;
