import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import cn from "classnames";

const OkDropdown = (props) => {
    // eslint-disable-next-line react/prop-types
    const { value, isrequired } = props;
    const [blur, setBlur] = useState(false);

    return <Dropdown
        {...props}
        className={cn({ 'p-invalid': (isrequired && blur && !value) })}
        onBlur={() => {
            setBlur(true);
        }}
    />
}

export default OkDropdown;
