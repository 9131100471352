import moment from "moment";

/**
 *
 * @typedef {{
 * id:number,
 * title:string,
 * enabled:boolean,
 * createdAt: Object,
 * updatedAt: Object,
 * counters: Object }} client
 *
 */

class Client {
    #id;
    #title;
    #enabled;
    #createdAt;
    #updatedAt;
    #counters;
    #technicalAccount;
    #reviewCollectorTemplate;

    constructor(client = {}) {
        this.#id = client.id || null;
        this.#title = client.title || '';
        this.#technicalAccount = client.technicalAccount || '';
        this.#reviewCollectorTemplate = client.reviewCollectorTemplate || '';
        this.#enabled = client.enabled || false;
        this.#createdAt = client.createdAt || null;
        this.#updatedAt = client.updatedAt || null;
        this.#counters = client.counters || {}
    }

    get id() {
        return this.#id;
    }

    get technicalAccount() {
        return this.#technicalAccount;
    }

    get reviewCollectorTemplate() {
        return this.#reviewCollectorTemplate;
    }

    set id(id) {
        this.#id = id;
    }

    get title() {
        return this.#title;
    }

    set title(title) {
        this.#title = title;
    }

    get enabled() {
        return this.#enabled;
    }

    set enabled(enabled) {
        this.#enabled = enabled;
    }

    get createdAt() {
        return this.#createdAt ? moment(this.#createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    set createdAt(createdAt) {
        this.#createdAt = createdAt;
    }

    get updatedAt() {
        return this.#updatedAt ? moment(this.#updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    set updatedAt(updatedAt) {
        this.#updatedAt = updatedAt;
    }

    get counters() {
        return this.#counters;
    }

    set counters(counters) {
        this.#counters = counters;
    }

    getObjectFromApi() {
        return {
            id: this.id,
            title: this.title,
            enabled: this.enabled,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            counters: this.counters,
            technicalAccount: this.technicalAccount,
            reviewCollectorTemplate: this.reviewCollectorTemplate,
        }
    }

    static getEmptyEntity() {
        return new Client();
    }
}

export default Client;
