import References from "services/References";
import { api } from "core";
import uiStore from "stores/UiStore";
import { ClientActivity, ClientActivityUser } from "models";
import { ClientParsing, ClientParsingBranches } from "models/ClientParsing";

class AnalyticsService extends References {
    features = [
        { name: 'Нет', code: 'disabled' },
        { name: 'Базовый', code: 'basic' },
        { name: 'Расширенный', code: 'extended' },
    ];

    constructor() {
        super();
    }

    /**
     *
     * @param {Array} values
     * @param {String} code
     * @returns {{code: String, name: String, value: String}}
     */
    getServiceObject = (values = [], code) => {
        let serviceObject = values.find(value => value.code === code);
        let featureObject = this.features.find(feature => feature.code === serviceObject.value)

        return {
            name: featureObject.name,
            code: serviceObject.code,
            value: serviceObject.value,
        }
    }

    /**
     *
     * @param {Object} filter
     * @returns {Promise<T>}
     */
    loadItems = (filter) => {
        const params = this.parseFilter(filter);
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/clients/features?${params}`)
            .then(result => result)
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            })
    }

    loadClientUsers = (clientId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/users/activity?clientId=${clientId}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new ClientActivityUser(item);
                    }),
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            })
    }

    loadClientActivity = (filter) => {
        const params = this.parseFilter(filter);
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/clients/activity?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new ClientActivity(item);
                    }),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            })

    }

    loadClientBranches = (clientId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/branches/extreview/parsing?clientId=${clientId}&page[size]=100`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new ClientParsingBranches(item);
                    }),
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            })

    }

    loadParsingData = (filter) => {
        const params = this.parseFilter(filter);
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/clients/extreview/parsing?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new ClientParsing(item);
                    }),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            })


    }
}


const analyticsService = new AnalyticsService();

export default analyticsService;
