import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import OkInputText from "../../common/OkInputText";
import { InputText } from "primereact/inputtext";
import OkDropdown from "../../common/OkDropdown";

const CompaniesDetailPage = ({ uiStore, breadcrumbsStore, companyStore, mainStore }) => {

    let history = useHistory();
    const { client } = mainStore;
    const {
        entity,
    } = companyStore;

    const { id } = useParams();

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'companies'
        });
    }, [entity]);


    useEffect(() => {
        if (client.id && entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${client.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Компании',
                link: `/clients/${entity.id}/companies`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: entity.title,
                link: `/clients/${entity.clientId}/companies/${id}/detail`,
            }, 3);
        }
    }, [client, entity])

    useEffect(() => {
        companyStore.loadEntity(id);
    }, []);

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'house2_gradient'}
                    active={entity.enabled}
                    showBadge={true}
                >
                    {entity.title}
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <Button
                            className={cn('p-button-outlined')}
                            label="Редактировать"
                            icon="pi pi-pencil"
                            onClick={() => {
                                history.push(`/clients/${entity.clientId}/companies/${id}/edit`);
                            }}
                        />
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='name'
                                        name='name'
                                        type='text'
                                        isrequired="true"
                                        value={entity.title}
                                        disabled
                                    />
                                    <label htmlFor="name">Название компании</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='id'
                                        name='id'
                                        type='text'
                                        value={entity.id || ''}
                                        disabled
                                    />
                                    <label htmlFor="id">Идентификатор OKR</label>
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        id='defaultApplication'
                                        name='defaultApplication'
                                        value={entity.type.code}
                                        options={[
                                            { title: 'Франшиза', code: 'franchise' },
                                        ]}
                                        optionLabel="title"
                                        optionValue="code"
                                        disabled
                                    />
                                    <label htmlFor="defaultApplication">Тип</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

CompaniesDetailPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    companyStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'companyStore', 'mainStore')(observer(CompaniesDetailPage));
