import { action, makeObservable, observable } from "mobx";
import { authService } from "services";

import uiStore from "stores/UiStore";

class AuthStore {
    /**
     * @type {boolean}
     */
    isAuthorized = false;

    isRemember = true;

    #uiStore;

    prevUrl = null;

    constructor() {
        this.#uiStore = uiStore;

        makeObservable(this, {
            isAuthorized: observable,
            isRemember: observable,
            setAuthorized: action,
            removeAuthorized: action,
            setRemember: action,
        });

//TODO убрать в функцию
//TODO может перенести в authService ?
        const data = authService.getFromStorage();
        if (data) {
            const { refresh_token, access_token, access_exp } = JSON.parse(data);
            const nowTs = Math.ceil(Date.now() / 1000);

            authService.refreshToken = refresh_token;
            authService.accessToken = access_token;

            if (nowTs < access_exp - 90) {
                this.setAuthorized();
            } else {
                this.refresh();
            }
        } else {
            this.removeAuthorized()
        }
    }

    setPrevUrl = (prevUrl) => {
        this.prevUrl = prevUrl
    }

    getPrevUrl = () => {
        return this.prevUrl
    }

    delPrevUrl = () => {
        this.prevUrl = null;
    }

    refresh() {
        authService.refresh()
            .then(() => {
                this.setAuthorized();
            })
            .catch(error => {
                this.#uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response.data.error.type,
                        detail: error.response.data.error.description,
                    }
                )
            });

    }

    authImpersonate(impersonate = '') {
        if (!impersonate) return Promise.reject("impersonate is empty");
        return authService.authImpersonate({ username: impersonate })
            .then((result) => {
                return result;
            })
            .catch(error => {
                this.#uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error?.data?.type,
                        detail: error.error?.description,
                    }
                )
            })
    }

    auth(username, password) {
        authService.auth({ username, password })
            .then(() => {
                this.setAuthorized();
            })
            .catch(error => {
                this.#uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error?.data?.type,
                        detail: error.error?.description,
                    }
                )
            });
    }

    logout() {
        authService.logout()
            .then(() => {
                this.removeAuthorized();
                document.location.replace('/login');
            })
            .catch(error => {
                this.#uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.data.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    setRemember = (remember) => {
        this.isRemember = remember;
        authService.setRemember(remember);
    }

    removeAuthorized = () => {
        this.isAuthorized = false;
        authService.isAuthorized = false;
    }

    setAuthorized = () => {
        this.isAuthorized = true;
        authService.isAuthorized = true;
    }

}

const authStore = new AuthStore();
export default authStore;
