import { serialize } from "helper";

class References {
//TODO Разнести для разных обектов/сервисов.

    parseFilter = (filter) => {
        let params;

        const { pager, sort, title, enabled, firstname, lastname, clientId, feature, idle, parse } = filter;

        params = {
            page: pager || '',
            sort: sort || '',
        }

        title && (params.title = title);
        enabled !== null && enabled !== undefined && (params.enabled = enabled);
        firstname && (params.firstname = firstname);
        lastname && (params.lastname = lastname);
        clientId && (params.clientId = clientId);

        if(feature && feature.extreview !== 'disabled') {
            params.feature = {
                extreview: feature.extreview,
            }
        }
        if(feature && feature.analytics !== 'disabled') {
            params.feature = {
                analytics: feature.analytics,
            }
        }

        idle && (params.idle = idle);
        parse && (params.parse = parse);

        return serialize(params);
    }

}

export default References;
