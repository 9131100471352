import React from 'react';
import { Toast } from 'primereact/toast';
import { reaction, toJS } from "mobx";
import uiStore from "stores/UiStore";

//TODO возможно переделать в классовый компонент
const toast = React.createRef();

const Message = () => {
    return (
        <div>
            <Toast ref={toast} />
        </div>
    );
}

/**
 * @param message
 */
const showMessage = (message) => {
    toast.current.show(
        {
            severity: message.severity,
            summary: message.summary,
            detail: message.detail,
            sticky: message.sticky,
            life: message.life,
            closable: message.closable,
        }
    );
}

reaction(
    () => toJS(uiStore.message),
    (message) => {
        showMessage(message);
    }
);


export default Message;
