import administratorStore from "stores/AdministratorStore";
import adPlatformStore from "./AdPlatformStore";
import authStore from "stores/AuthStore";
import branchStore from "stores/BranchStore";
import brandStore from "stores/BrandStore";
import breadcrumbsStore from "stores/BreadcrumbsStore";
import clientStore from "stores/ClientStore";
import companyGroupStore from "stores/CompanyGroupStore";
import locationStore from "stores/LocationStore";
import mainStore from 'stores/MainStore';
import uiStore from "stores/UiStore";
import userStore from "stores/UserStore";
import branchTypeStore from "stores/BranchTypeStore";
import adPlatformReviewStore from 'stores/AdPlatformReviewStore';
import adPlatformPublicationStore from "stores/AdPlatformPublicationStore";
import adPlatformParserStore from "stores/AdPlatformParserStore";
import companyStore from "stores/CompanyStore";
import adPlatformTypeStore from "stores/AdPlatformTypeStore";
import integrationStore from "stores/IntegrationStore";
import tagStore from "stores/TagStore";
import parsingAccountsStore from "./ParsingAccountsStore";

import {
    analyticsActivityStore,
    analyticsServicesStore,
    analyticsParsingStore,
} from "stores/AnalyticsActivityStore";

const stores = {
    administratorStore,
    adPlatformStore,
    authStore,
    branchStore,
    brandStore,
    breadcrumbsStore,
    clientStore,
    companyGroupStore,
    locationStore,
    mainStore,
    uiStore,
    userStore,
    analyticsActivityStore,
    analyticsServicesStore,
    analyticsParsingStore,
    branchTypeStore,
    adPlatformReviewStore,
    adPlatformPublicationStore,
    adPlatformParserStore,
    companyStore,
    adPlatformTypeStore,
    integrationStore,
    tagStore,
    parsingAccountsStore,
};

export default stores;
