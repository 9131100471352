export default class SmallAdPlatform {
    #_id;
    #_title;
    #_type;
    #_url;

    constructor(smallAdPlatform) {
        this.#_id = smallAdPlatform.id || null;
        this.#_title = smallAdPlatform.title || '';
        this.#_type = smallAdPlatform.type || null;
        this.#_url = smallAdPlatform.url || null;
    }

    /**
     * @returns {int|null}
     */
    get id() {
        return this.#_id;
    }

    /**
     * @returns {string}
     */
    get title() {
        return this.#_title;
    }

    /**
     * @returns {string|null}
     */
    get type() {
        return this.#_type;
    }

    /**
     * @returns {string|null}
     */
    get url() {
        return this.#_url;
    }
}
