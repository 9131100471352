import { adPlatformTypeService } from "services"

class AdPlatformTypeStore {
    getAll() {
        return adPlatformTypeService.getAll().then(result => {
            return result
        }).catch(error => error);
    }
}

const adPlatformTypeStore = new AdPlatformTypeStore();
export default adPlatformTypeStore;
