import React from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { Link, NavLink } from "react-router-dom";
import 'styles/header/bottomSide.scss';

const ClientsBottomMenu = inject('mainStore')(observer(({ mainStore }) => {
    const { client } = mainStore;

    return (
        <>
        {client.id && <div className={cn('bottom-side')}>
            <div className={cn('company-name-container')}>
                <div className={cn('company-name')}>
                    <div className={cn('bag', 'ico')} />
                    <Link
                        to={`/clients/${client.id}/detail`}
                        title={client.title}
                    >
                        {client.title}
                    </Link>
                </div>
            </div>
            <div className={cn('sub-menu-container')}>
                <div className={cn('sub-menu')}>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to={`/clients/${client.id}/companies`}>Компании</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to={`/clients/${client.id}/branches`}>Филиалы</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to={`/clients/${client.id}/integrations`}>Интеграции</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to={`/clients/${client.id}/users`}>Пользователи</NavLink>
                    </ul>
                </div>
            </div>
        </div>}
        </>

    );
}));

const ReferencesBottomMenu = () =>  {
    return (
        <div className={cn('bottom-side')}>
            <div className={cn('company-name-container')}>
                &nbsp;
            </div>
            <div className={cn('sub-menu-container')}>
                <div className={cn('sub-menu')}>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/references/company-groups">Группы компаний</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/references/brands">Бренды филиалов</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/references/branch-types">Типы филиалов</NavLink>
                    </ul>

                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/references/locations">Локации</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/references/tags">Правила автотегов</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/references/parsing-accounts">Аккаунты парсинга</NavLink>
                    </ul>
                </div>
            </div>
        </div>

    );
};

const AnalyticsBottomMenu = () =>  {
    return (
        <div className={cn('bottom-side')}>
            <div className={cn('company-name-container')}>
                &nbsp;
            </div>
            <div className={cn('sub-menu-container')}>
                <div className={cn('sub-menu')}>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/analytics/services">Услуги</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/analytics/activity">Активность</NavLink>
                    </ul>
                    <ul className={cn('sub-menu__item')}>
                        <NavLink to="/analytics/parsing">Парсинг</NavLink>
                    </ul>
                </div>
            </div>
        </div>

    );
};


export {
    ReferencesBottomMenu,
    ClientsBottomMenu,
    AnalyticsBottomMenu,
}

