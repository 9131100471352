import moment from "moment";

class AdPlatformSource {
    #_type;
    #_id;
    #_extreview;

    constructor(adPlatformSource) {
        this.#_type = adPlatformSource.type || '';
        this.#_id = adPlatformSource.id || null;
        this.#_extreview = adPlatformSource.extreview || '';
    }

    /**
     * @return {AdPlatformType}
     */
    get type() {
        return this.#_type;
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this.#_id;
    }

    /**
     * @return {string}
     */
    get extreview() {
        return this.#_extreview;
    }

    /**
     * @returns {AdPlatformSource}
     */
    getPlainObject() {
        // noinspection JSValidateTypes
        return {
            type: this.type,
            id: this.id,
            extreview: this.extreview,
        };
    }
}

class AdPlatformExtreviewRequest {
    #_lastError;
    #_lastRequest;
    #_lastSync;

    constructor(adPlatformExtreviewRequest) {
        this.#_lastError = adPlatformExtreviewRequest.lastError || '';
        this.#_lastRequest = adPlatformExtreviewRequest.lastRequest || null;
        this.#_lastSync = adPlatformExtreviewRequest.lastSync || null;
    }

    /**
     * @return {string}
     */
    get lastError() {
        return this.#_lastError;
    }

    /**
     * @return {string}
     */
    get lastRequest() {
        return this.#_lastRequest ? moment(this.#_lastRequest)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @return {string}
     */
    get lastSync() {
        return this.#_lastSync ? moment(this.#_lastSync)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @returns {AdPlatformExtreviewRequest}
     */
    getPlainObject() {
        // noinspection JSValidateTypes
        return {
            lastError: this.lastError,
            lastRequest: this.lastRequest,
            lastSync: this.lastSync,
        };
    }
}

class AdPlatformConnectionStatusConnection {
    #_enabled;
    #_deleted;
    #_source;
    #_request
    #_createdAt;
    #_updatedAt;
    #_deletedAt;

    constructor(adPlatformConnectionStatusConnection) {
        this.#_enabled = adPlatformConnectionStatusConnection.enabled || false;
        this.#_deleted = adPlatformConnectionStatusConnection.deleted || false;
        this.#_source = new AdPlatformSource(adPlatformConnectionStatusConnection.source || {});
        this.#_request = new AdPlatformExtreviewRequest(
            adPlatformConnectionStatusConnection.request || {}
        );
        this.#_createdAt = adPlatformConnectionStatusConnection.createdAt || null;
        this.#_updatedAt = adPlatformConnectionStatusConnection.updatedAt || null;
        this.#_deletedAt = adPlatformConnectionStatusConnection.deletedAt || null;
    }

    /**
     * @return {boolean}
     */
    get enabled() {
        return this.#_enabled;
    }

    /**
     * @return {boolean}
     */
    get deleted() {
        return this.#_deleted;
    }

    /**
     * @return {AdPlatformSource}
     */
    get source() {
        return this.#_source;
    }

    /**
     * @return {AdPlatformExtreviewRequest}
     */
    get request() {
        return this.#_request;
    }

    /**
     * @return {String}
     */
    get createdAt() {
        return this.#_createdAt ? moment(this.#_createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @return {string}
     */
    get updatedAt() {
        return this.#_updatedAt ? moment(this.#_updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @return {string}
     */
    get deletedAt() {
        return this.#_deletedAt ? moment(this.#_deletedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @returns {AdPlatformConnectionStatusConnection}
     */
    getPlainObject() {
        // noinspection JSValidateTypes
        return {
            enabled: this.enabled,
            deleted: this.deleted,
            source: this.source.getPlainObject(),
            request: this.request.getPlainObject(),
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt,
        };
    }
}

class AdPlatformConnectionStatusParsing {
    #_enabled;
    #_reason;
    #_disabledAt;

    constructor(adPlatformConnectionStatusParsing) {
        this.#_enabled = adPlatformConnectionStatusParsing.enabled || false;
        this.#_reason = adPlatformConnectionStatusParsing.reason || '';
        this.#_disabledAt = adPlatformConnectionStatusParsing.disabledAt || null;
    }

    /**
     * @return {boolean}
     */
    get enabled() {
        return this.#_enabled;
    }

    /**
     * @return {string}
     */
    get reason() {
        return this.#_reason;
    }

    /**
     * @return {string}
     */
    get disabledAt() {
        return this.#_disabledAt ? moment(this.#_disabledAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @returns {AdPlatformConnectionStatusParsing}
     */
    getPlainObject() {
        // noinspection JSValidateTypes
        return {
            enabled: this.enabled,
            reason: this.reason,
            disabledAt: this.disabledAt,
        };
    }
}

class AdPlatformConnectionStatusAuth {
    #_enabled;
    #_checkedAt;

    constructor(adPlatformConnectionStatusAuth) {
        this.#_enabled = adPlatformConnectionStatusAuth.enabled || false;
        this.#_checkedAt = adPlatformConnectionStatusAuth.checkedAt || null;
    }

    /**
     * @return {boolean}
     */
    get enabled() {
        return this.#_enabled;
    }

    /**
     * @return {string}
     */
    get checkedAt() {
        return this.#_checkedAt ? moment(this.#_checkedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @returns {AdPlatformConnectionStatusAuth}
     */
    getPlainObject() {
        // noinspection JSValidateTypes
        return {
            enabled: this.enabled,
            checkedAt: this.checkedAt,
        };
    }
}

export class AdPlatformConnectionStatus {
    #_isConnectionCreated;
    #_connection;
    #_parsing;
    #_auth;

    constructor(adPlatformConnectionStatus) {
        this.#_connection = new AdPlatformConnectionStatusConnection(
            adPlatformConnectionStatus.connection || {},
        );
        this.#_parsing = new AdPlatformConnectionStatusParsing(
            adPlatformConnectionStatus.parsing || {},
        );
        this.#_auth = new AdPlatformConnectionStatusAuth(
            adPlatformConnectionStatus.auth || {},
        );
        this.#_isConnectionCreated = !!adPlatformConnectionStatus.connection;
    }

    /**
     * @return {AdPlatformConnectionStatusConnection}
     */
    get connection() {
        return this.#_connection;
    }

    /**
     * @return {AdPlatformConnectionStatusParsing}
     */
    get parsing() {
        return this.#_parsing;
    }

    /**
     * @return {AdPlatformConnectionStatusAuth}
     */
    get auth() {
        return this.#_auth;
    }

    /**
     * @return {boolean}
     */
    get isConnectionCreated() {
        return this.#_isConnectionCreated;
    }

    /**
     * @returns {AdPlatformConnectionStatus}
     */
    getPlainObject() {
        // noinspection JSValidateTypes
        return {
            connection: this.connection.getPlainObject(),
            parsing: this.parsing.getPlainObject(),
            auth: this.auth.getPlainObject(),
            isConnectionCreated: this.isConnectionCreated,
        };
    }

    /**
     * @returns {AdPlatformConnectionStatus}
     */
    static getEmpty() {
        return new AdPlatformConnectionStatus({});
    }
}
