class WindowStorage {

    static LOCAL_STORAGE = localStorage;
    static SESSION_STORAGE = sessionStorage;

    /**
     * @type {Storage}
     */
    #storage = WindowStorage.LOCAL_STORAGE;

    constructor() {

    }

    set storage(storage) {
        this.#storage = storage;
    }

    get storage() {
        return this.#storage;
    }

    checkStorageRemember(key) {
        if (WindowStorage.LOCAL_STORAGE.getItem(key)) {
            this.storage = WindowStorage.LOCAL_STORAGE;
        }
        if (WindowStorage.SESSION_STORAGE.getItem(key)) {
            this.storage = WindowStorage.SESSION_STORAGE;
        }

    }

    setStorage(isRemember) {
        if (isRemember) this.storage = WindowStorage.LOCAL_STORAGE;
        else {
            this.storage = WindowStorage.SESSION_STORAGE;
        }
    }

    /**
     * @param key
     * @param value
     */
    setItem(key, value) {
        this.#storage.setItem(key, value);
    }

    /**
     * @param key
     * @returns {string}
     */
    getItem(key) {
        return this.#storage.getItem(key)
    }

    /**
     * @param key
     */
    removeItem(key) {
        this.#storage.removeItem(key);
    }

    removeItemAllStorage(key) {
        WindowStorage.LOCAL_STORAGE.removeItem(key);
        WindowStorage.SESSION_STORAGE.removeItem(key);
    }
}

const windowStorage = new WindowStorage();
export default windowStorage;

