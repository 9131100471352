import React, { useEffect, useState } from "react";
import cn from "classnames";
import OkInputText from "../../../common/OkInputText";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Button } from "primereact/button";

const Synonym = ({ item, tagline, index, tagIndex, tagStore, disabled = false, onChange, removeSynonym }) => {
    const [keyword, setKeyword]  = useState(item);

    useEffect(() => {
        onChange(index, keyword)
    }, [keyword]);

    return (
        <div className={cn('edit-form__col')} style={{ width:"auto", marginRight: "5px" }}>
            <div className="p-field">
                        {disabled ? <>
                            <span className="p-float-label">
                                <OkInputText
                                    id={`synonym_${tagIndex}_${index}`}
                                    className={'p-inputtext-sm p-d-block p-mb-2'}
                                    name={`synonym_${tagIndex}_${index}`}
                                    type='text'
                                    value={keyword.name}
                                    disabled={disabled}
                                />
                                <label htmlFor="title">{index === 0 && 'Синонимы'}</label>
                            </span>
                        </> : <>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <OkInputText
                                        id={`synonym_${tagIndex}_${index}`}
                                        className={cn('p-inputtext-sm', 'p-d-block', 'p-mb-2', { 'p-invalid': !tagline.keywords[index]?.valid })}
                                        name={`synonym_${tagIndex}_${index}`}
                                        type='text'
                                        value={keyword.name || ''}
                                        isrequired="true"
                                        disabled={disabled}
                                        onChange={(e) => {
                                            setKeyword({ name: e.target.value, valid: !!e.target.value })
                                        }}
                                    />
                                    <label htmlFor="title">{index === 0 && 'Синонимы'}</label>
                                </span>
                                <Button
                                    icon="pi pi-times"
                                    className={cn('p-inputtext-sm', 'p-button-outlined')}
                                    style={{ marginLeft: '-1px' }}
                                    onClick={()=>{removeSynonym(tagIndex, index)}}
                                />
                            </div>
                        </>}

            </div>
        </div>
    )
}

Synonym.propTypes = {
    item: PropTypes.any,
    tagline: PropTypes.any,
    index: PropTypes.number,
    tagIndex: PropTypes.number,
    tagStore: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    removeSynonym: PropTypes.func,
}

export default inject('tagStore')(observer(Synonym));
