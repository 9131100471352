import cn from "classnames";
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";

const TitleH4 = ({ children, count, active, color, showBadge, ico, href }) => {
    return (
        <h4 className={cn(`title-h4`)}>
            <div className={cn('ico', ico)} />
            {href ?
                <Link to={href} className={cn(`title-h4__text`)}>{children}</Link>
                :
                <span className={cn(`title-h4__text`)}>{children}</span>
            }
            {count && <span className={cn(`title-h4__count`, `title-h4__count_${color}`)}>{count}</span>}
            {showBadge && <span className={cn('badge', `badge_active-${active}` )}>{active ? 'Активен' : 'Не активен'}</span>}
            {href && <Link to={href} className={cn('ico', 'blank-link')} />}
        </h4>
    )

}
TitleH4.propTypes = {
    children: PropTypes.any,
    count: PropTypes.number,
    active: PropTypes.any,
    color: PropTypes.string,
    showBadge: PropTypes.any,
    ico: PropTypes.string,
    href: PropTypes.string,
}

export default TitleH4;
