import _ from "underscore";
import { api } from "core";
import { serialize } from "../helper";
import {
    Branch,
    BranchManagerLink,
    BranchAdPlatformLink,
} from "../models";
import {
    brandsService,
    companyGroupsService,
    locationsService,
} from "./index";
import uiStore from "../stores/UiStore";

/**
 * Branches service
 *
 * @author Roman Zaycev <roman.zaycev@olmisoft.com>
 */
class BranchService {
    /**
     * @param {int} clientId
     * @param {{}} filter
     * @return {Promise<{items: Array<Branch>, pager: PagerAnswer, sort: SortAnswer}>}
     */
    getBranches(clientId, filter) {
        filter = _.clone(filter || {});
        filter['clientId'] = clientId;

        if (filter.title && !filter.title.length) {
            delete filter.title;
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/branches?${serialize(filter)}`)
            .then(result => {
                return {
                    items: result.items.map(item => new Branch(item)),
                    pager: result.pager,
                    sort: result.sorting,
                };
            });
    }

    getAllBranches(filter) {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/branches?${serialize(filter)}`)
            .then(result => {
                return {
                    items: result.items.map(item => new Branch(item)),
                    pager: result.pager,
                    sort: result.sorting,
                };
            });
    }

    /**
     * @param {int} branchId
     * @returns {Promise<Branch>}
     */
    getBranch(branchId) {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/branches/${branchId}`)
            .then(result => new Branch(result));
    }

    /**
     * @param {int} branchId
     * @param {boolean} onlyLinked
     * @returns {Promise<{
     * entityAdditional: {companyGroups: CompanyGroup[], location: Location, brand: Brand},
     * branch: Branch,
     * users: {items: BranchManagerLink[], pager: PagerAnswer, linkCount: int},
     * adplatforms: {items: BranchAdPlatformLink[], pager: PagerAnswer, linkCount: int}
     * }>}
     */
    getBranchDetailView(branchId, onlyLinked) {
        const result = {
            branch: null,
            entityAdditional: {
                companyGroups: [],
                location: null,
                brand: null,
            },
            users: {},
            adplatforms: {},
        };

        return this
            .getBranch(branchId)
            .then((branch) => {
                result.branch = branch;

                const additionalPromises = [];

                if (branch.group?.id) {
                    additionalPromises.push(
                        companyGroupsService
                            .loadEntity(branch.group.id)
                            .then((companyGroup) => {
                                result.entityAdditional.companyGroups = [
                                    companyGroup,
                                ];
                            })
                    );
                }

                if (branch.location?.id) {
                    additionalPromises.push(
                        locationsService
                            .loadEntity(branch.location.id)
                            .then((location) => {
                                result.entityAdditional.location = location;
                            })
                    );
                }

                if (branch.brand?.id) {
                    additionalPromises.push(
                        brandsService
                            .loadEntity(branch.brand.id)
                            .then((brand) => {
                                result.entityAdditional.brand = brand;
                            })
                    )
                }

                additionalPromises.push(
                    this
                        .getManagers(branch.id, onlyLinked)
                        .then((links) => {
                            result.users = links;
                        }),
                );
                additionalPromises.push(
                    this
                        .getAdPlatforms(branch.id, onlyLinked)
                        .then((links) => {
                            result.adplatforms = links;
                        })
                )

                return Promise.all(additionalPromises);
            })
            .then(() => {
                return result;
            });
    }

    /**
     * @param {int} branchId
     * @param {*} data
     * @returns {Promise<Branch>}
     */
    saveBranch = (branchId, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/company/branches/${branchId}`, data);
    }

    /**
     * @param {int} branchId
     * @param {boolean|null} onlyLinked
     * @return {Promise<{items: BranchManagerLink[], pager: PagerAnswer, linkCount: int}>}
     */
    getManagers = (branchId, onlyLinked = null) => {
        const filter = {};

        if (onlyLinked !== null) {
            filter.onlyLinks = (onlyLinked) ? 1 : 0;
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/branches/${branchId}/managers?${serialize(filter)}`)
            .then((result) => {
                return {
                    items: result.items.map(item => new BranchManagerLink(item)),
                    pager: result.pager,
                    linkCount: result.linkCount,
                };
            });
    }

    /**
     * @param {int} branchId
     * @param {boolean|null} onlyLinked
     * @returns {Promise<{items: BranchAdPlatformLink[], pager: PagerAnswer, linkCount: int}>}
     */
    getAdPlatforms = (branchId, onlyLinked = null) => {
        const filter = {};

        if (onlyLinked !== null) {
            filter.onlyLinks = (onlyLinked) ? 1 : 0;
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/branches/${branchId}/adplatforms?${serialize(filter)}`)
            .then((result) => {
                return {
                    items: result.items.map(item => new BranchAdPlatformLink(item)),
                    pager: result.pager,
                    linkCount: result.linkCount,
                };
            });
    }

    /**
     * @param {{}} data
     * @return {Promise<Branch>}
     */
    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/company/branches`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )

                return new Branch(result);
            });
    }
}

const branchService = new BranchService();
export default branchService;
