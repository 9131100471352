import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import TitleH1 from "components/common/TitleH1";
import TitleH2 from "components/common/TitleH2";
import { implodeAddress } from "../../../../helper";
import { AD_PLATFORM_TYPE } from "../../../../models/AdPlatform";

/**
 * @param {MainStore} mainStore
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {BranchStore} branchStore
 * @returns {JSX.Element}
 * @constructor
 */
const BranchesDetailPage = ({ mainStore, uiStore, breadcrumbsStore, branchStore }) => {
    let history = useHistory();

    const { client } = mainStore;
    const {
        entity,
        entityAdditional,
        users,
        usersPager,
        integrations,
        integrationsPager,
    } = branchStore;

    const { id } = useParams();

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'branches'
        });
    }, [entity]);

    useEffect(() => {
        if (client.id && entity.clientId) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${client.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Филиалы',
                link: `/clients/${client.id}/branches`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: entity.title,
                link: `/clients/${client.id}/branches/${id}/detail`,
            }, 3);
        }
    }, [client, entity])

    useEffect(() => {
        branchStore.loadEntity(id);
    }, []);

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'house_gradient'}
                    active={entity.enabled}
                    showBadge={true}
                >
                    { entity.title }
                </TitleH1>
            </div>

            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <Button
                            className={cn('p-button-outlined')}
                            label="Редактировать"
                            icon="pi pi-pencil"
                            onClick={() => {
                                history.push(`/clients/${entity.clientId}/branches/${id}/edit`);
                            }}
                        />
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Dropdown
                                        value={entity.group.id}
                                        options={entityAdditional.companyGroups?.map((cg) => {
                                            return {
                                                name: cg.title,
                                                code: cg.id,
                                            };
                                        })}
                                        id="company-group"
                                        name="company-group"
                                        optionLabel="name"
                                        optionValue="code"
                                        disabled
                                    />
                                    <label htmlFor="company-group">Группа компаний</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='legalEntity'
                                        name='legalEntity'
                                        type='text'
                                        value={entity.organization}
                                        disabled
                                    />
                                    <label htmlFor="legalEntity">Юридическое лицо</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='location'
                                        name='location'
                                        type='text'
                                        value={entityAdditional.location?.title}
                                        disabled
                                    />
                                    <label htmlFor="location">Локация</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='brand'
                                        name='brand'
                                        type='text'
                                        value={entityAdditional.brand?.title}
                                        disabled
                                    />
                                    <label htmlFor="brand">Бренд филиала</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='type'
                                        name='type'
                                        type='text'
                                        value={entity.type?.title}
                                        disabled
                                    />
                                    <label htmlFor="brand">Тип филиала</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col', 'edit-form__col_large')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='legalAddress'
                                        name='legalAddress'
                                        type='text'
                                        value={implodeAddress(entity.address)}
                                        disabled
                                    />
                                    <label htmlFor="legalAddress">Юридический адрес</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='id'
                                        name='id'
                                        type='text'
                                        value={entity.id || ''}
                                        disabled
                                    />
                                    <label htmlFor="id">Идентификатор OKR</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='titleSms'
                                        name='titleSms'
                                        type='text'
                                        value={entity.titleSms}
                                        disabled
                                    />
                                    <label htmlFor="titleSms">Название филиала в SMS</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'integrations')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={integrationsPager.itemCount}
                                color='color-orange'
                                ico='chain_gradient'
                            >
                                Интеграции / {client.title}
                            </TitleH2>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Интеграции</th>
                                    <th>Площадка</th>
                                    <th>Связь с филиалом</th>
                                </tr>
                            </thead>
                            <tbody>

                            {integrations.map((item, key) => (
                                <tr key={key}>
                                    <td>
                                        <Link to={`/clients/${entity.clientId}/integrations/${item.adplatform.id}/detail`}>
                                            {item.adplatform.title}
                                        </Link>
                                    </td>
                                    <td>{AD_PLATFORM_TYPE.getTitle(item.adplatform.type)}</td>
                                    <td>
                                        <span className={cn('badge', (item.linked) ? 'badge_linked-true' : null)}>
                                            {(item.linked ? 'Связаны' : 'Нет связи')}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className={cn('table-container', 'users')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={usersPager.itemCount}
                                color='color-blue-light'
                                ico='avatar__blue_gradient'
                            >
                                Доступы пользователей / {client.title}
                            </TitleH2>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Пользователи</th>
                                    <th>Доступ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, key) => (<tr key={`${user.employee.id}_${key}`}>
                                    <td>
                                        <Link to={`/clients/${entity.clientId}/users/${user.employee.id}/detail`}>
                                            {user.employee.firstname} {user.employee.lastname}
                                        </Link>
                                    </td>
                                    <td>
                                        <span className={cn('badge', (user.linked) ? 'badge_linked-true' : null)}>
                                            {(user.linked ? 'Открыт' : 'Закрыт')}
                                        </span>
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

BranchesDetailPage.propTypes = {
    mainStore: PropTypes.any,
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    branchStore: PropTypes.any,
}

export default inject('mainStore', 'uiStore', 'breadcrumbsStore', 'branchStore')(observer(BranchesDetailPage));

