import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import cn from 'classnames';
import { Redirect } from "react-router";
import loginImg from 'images/login.svg';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import 'styles/login.scss';

const LoginPage = ({ authStore, uiStore }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'login',
            className: 'login'
        });
    },[]);

    const { isAuthorized } = authStore;

    const prevUrl = authStore.getPrevUrl()

    const submitHandler = (e) => {
        e.preventDefault();
        (username && password) && authStore.auth(username, password);
    }
    return isAuthorized
        ?
            prevUrl ? <Redirect to={prevUrl}/> : <Redirect to='clients' />
        :
        <div className={cn('loginForm-container')}>
            <div className={cn('loginForm')}>
                <div className={cn('image')}>
                    <img src={loginImg} alt="" width={'312px'} height={'312px'} />
                </div>
                <div className={cn('form')}>
                    <form onSubmit={submitHandler}>
                        <div className="p-field">
                        <span className="p-float-label">
                            <InputText
                                id='username'
                                name='username'
                                type='text'
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <label htmlFor="username">Логин</label>
                        </span>
                        </div>
                        <div className="p-field">
                            <span className="p-float-label">
                                <Password
                                    value={password}
                                    type='password'
                                    id='password'
                                    name='password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    feedback={false}
                                    toggleMask
                                />
                                <label htmlFor="password">Пароль</label>
                            </span>
                        </div>
                        <div className={cn('checkbox-container')}>
                            <div className="p-field-checkbox">
                                <Checkbox
                                    id='remember'
                                    name='remember'
                                    checked={authStore.isRemember}
                                    onChange={e => authStore.setRemember(e.checked)}
                                />
                                <label htmlFor="binary">Запомнить меня</label>
                            </div>
                        </div>
                        <div className={cn('button-container')}>
                            <Button label="Войти" /> {/*<a href="#">Не помню пароль</a>*/}
                        </div>
                    </form>
                </div>
            </div>
        </div>
}

LoginPage.propTypes = {
    uiStore: PropTypes.any,
    authStore: PropTypes.any,
}

export default inject('authStore', 'uiStore')(observer(LoginPage));

