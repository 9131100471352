export default class SmallBranch {
    #_id;
    #_title;

    constructor(smallBranch) {
        this.#_id = smallBranch.id || null;
        this.#_title = smallBranch.title || '';
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this.#_id;
    }

    /**
     * @return {string}
     */
    get title() {
        return this.#_title;
    }
}
