export default class AdPlatformCounters {
    #branches;

    constructor(counters) {
        this.#branches = counters.branches || 0;
    }

    /**
     * @return {int}
     */
    get branches() {
        return this.#branches;
    }
}
