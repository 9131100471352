
class Rule {
    id;
    title;
    externalId;
    enabled;
    tags;
    clients;
    counters;
    createdAt;
    updatedAt;

    constructor(rule = {}) {
        this.id = rule.id || null;
        this.title = rule.title || '';
        this.externalId = rule.externalId || '';
        this.enabled = rule.enabled || false;
        this.tags = rule.tags?.map(tag => new Tag(tag || {})) || [];
        this.clients = rule.clients?.map(client => new Client(client || {})) || [];
        this.counters = rule.counters || {};
        this.createdAt = rule.createdAt || null;
        this.updatedAt = rule.updatedAt || null;
    }

    getPlainObject() {
        return {
            id: this.id,
            title: this.title,
            externalId: this.externalId,
            tags: this.tags,
            clients: this.clients,
            enabled: this.enabled,
            counters: this.counters,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        }
    }

    static getEmptyEntity() {
        return new Rule({});
    }
}

class Tag {
    name;
    keywords;

    constructor(tag = {}) {
        this.name = tag.name || '';
        this.keywords = tag.keywords || [];
    }
}

class Client {
    id;
    title;

    constructor(client = {}) {
        this.id = client.id || null;
        this.title = client.title || '';
    }
}

export { Rule, Tag };