import { action, makeObservable, observable } from "mobx";
import { integrationService } from "services";
import uiStore from "stores/UiStore";
import AdPlatformParser from "models/AdPlatformParser";

class AdPlatformParserStore {
    entity = AdPlatformParser.getEmptyEntity().getPlainObject();

    constructor() {
        makeObservable(this, {
            entity: observable,
            setEntity: action,
            resetEntity: action,
            loadEntity: action,
        })
    }

    activateEntity = (integrationId) => {
        integrationService.activateParser(integrationId)
            .then(result => result)
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );

            })
    }

    deactivateEntity = (integrationId) => {
        integrationService.deactivateParser(integrationId)
            .then(result => result)
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );

            })

    }

    saveEntity = (integrationId, parentId) => {
        return integrationService.editParser(integrationId, {
            parserId: this.entity.connection.source.id,
            parserEnabled: this.entity.parsing.enabled,
            geodataEnabled: this.entity.geodata.enabled,
            parentId
        })
            .then(result => result)
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );
            });
    }

    addEntity = (integrationId, parentId) => {
        return integrationService.addParser(integrationId, {
            parserId: this.entity.connection.source.id,
            parserEnabled: this.entity.parsing.enabled,
            geodataEnabled: this.entity.geodata.enabled,
            parentId
        })
            .then(result => result)
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );
            });
    }

    loadEntity = (integrationId) => {
        integrationService.getParser(integrationId).then(data => {
            this.setEntity(data);
        })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );
            });
    }

    resetEntity = () => {
        this.entity = AdPlatformParser.getEmptyEntity().getPlainObject();
        return Promise.resolve('OK');
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }


}

const adPlatformParserStore = new AdPlatformParserStore();
export default adPlatformParserStore;
