import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";
import _ from "underscore";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import Sortable from "../../common/Sortable";
import { useHistory } from "react-router";
import Activity from "../../common/Activity";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const TagsPage = ({ uiStore, breadcrumbsStore, tagStore }) => {

    let history = useHistory();

    const {
        items,
        pager,
        setPager,
        title,
        setTitle,
        fEnabled,
        setFEnabled,
        sort,
        setSort
    } = tagStore;

    const [first, setFirst] = useState(0);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'references',
            className: 'locations'
        });

        breadcrumbsStore.addItem({
            linkName: 'Правила автотегов',
            link: '/references/tags',
        }, 0);

        tagStore.loadItems();

    }, [])


    useEffect(() => {

        const disposers = [
            reaction(
                () => [
                    tagStore.fEnabled,
                ],
                () => {
                    tagStore.loadItems();
                }
            ),
            reaction(
                () => tagStore.title,
                (value) => {
                    queryDebounceHandler(value)
                }
            ),

            reaction(
                () => tagStore.pager.number,
                () => {
                    tagStore.loadItems();
                }
            ),
            reaction(
                () => JSON.stringify(tagStore.sort),
                () => {
                    tagStore.loadItems()
                }
            ),
        ];
        return () => {
            disposers.map(func => func());
        }
    }, []);

    const queryDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && tagStore.loadItems(),
        800
    );

    const sortHandler = (obj) => {
        setSort(obj);
    }
    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    color={'color-red'}
                >
                  Справочники / Правила автотегов
                </TitleH1>
            </div>
            <div className={cn('add-container')}>
                <Button
                    className={cn('p-button-outlined')}
                    label="Создать значение"
                    icon="pi pi-plus"
                    onClick={() => {
                        history.push('/references/tags/new');
                    }}
                />
            </div>

            <div className={cn('search-container')}>
                <div className={cn('dropdown-container')} style={{ marginRight: '24px' }}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fEnabled}
                                options={[
                                    { name: 'Все', code: -1 },
                                    { name: 'Активна', code: 1 },
                                    { name: 'Не активна', code: 0 },
                                ]}
                                onChange={(e) => {
                                    setFEnabled(e.value)
                                }}
                                id="fEnabled"
                                name="fEnabled"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fEnabled">Активность</label>
                            </span>
                    </div>
                </div>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText
                                placeholder="Поиск по названию..."
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='title'
                            >Значение справочника</Sortable>
                        </th>
                        <th className={cn('date-head')}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='created'
                            >Создано</Sortable>
                        </th>
                        <th className={cn('date-head')}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='updated'
                            >Изменено</Sortable>
                        </th>
                        <th>Клиентов подключено</th>
                    </tr>
                    </thead>
                    <tbody>

                    {items.map((item, key) => (
                        <tr key={key}>
                            <td className={cn('id-body')}>{item.id}</td>
                            <td className={cn('activity-data')}>
                                <Activity activity={item.enabled} />
                            </td>
                            <td>
                                <Link to={`/references/tags/${item.id}/detail`}>{item.title}</Link>
                            </td>
                            <td>
                                {moment(item.createdAt)
                                    .format('DD.MM.YYYY HH:mm')
                                    .toString()}
                            </td>
                            <td>
                                {moment(item.updatedAt)
                                .format('DD.MM.YYYY HH:mm')
                                .toString()}
                            </td>
                            <td>{item.counters.applied}</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first}
                    rows={pager.size}
                    totalRecords={pager.itemCount}
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    )
}

TagsPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    tagStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'tagStore', 'mainStore')(observer(TagsPage));