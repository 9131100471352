import React, { useEffect, useState } from "react";
import cn from "classnames";
import TitleH1 from "../../../common/TitleH1";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router";
import PDFReport from "../../../common/PDFReport";
import PDFSentimentReport from "../../../common/PDFSentimentReport";
import XLSReport from "../../../common/XLSReport";
import { MultiSelect } from 'primereact/multiselect';

const AdditionalActionsPage = ({ uiStore, breadcrumbsStore, clientStore, mainStore }) => {

    const { entity } = clientStore;
    const { title } = entity;
    const { clientId } = useParams();

    const [branchList, setBranchList] = useState([]);
    const [branch, setBranch] = useState([]);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'clients'
        });

        breadcrumbsStore.addItem({
            linkName: 'Клиенты',
            link: '/clients',
        }, 0);

        breadcrumbsStore.addItem({
            linkName: title,
            link: `/clients/${entity.id}/detail`,
        }, 1);

        breadcrumbsStore.addItem({
            linkName: 'Дополнительные действия',
            link: `/clients/${entity.id}/additional-actions`,
        }, 2);

    }, [entity]);

    useEffect(() => {
        if (clientId) {
            clientStore.loadEntity(clientId)
                .then(() => {
                    mainStore.setClient(clientStore.entity)
                });


            clientStore.getAllBranches(clientId).then(result => {
                const br = result.items.map(item => {
                    return {
                        code: item.id,
                        name: item.title
                    }
                })

                setBranchList(  prev => {
                    return [...prev, ...br]
                })
            });
        }
    }, [])

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    active={true}
                    ico={'gear'}
                    showInfo={false}
                    color={'color-blue'}
                >
                    Дополнительные действия
                </TitleH1>
            </div>
            <div className={cn('search-container')}>
                <div className={cn('dropdown-container')} style={{ marginRight: '24px' }}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <MultiSelect
                                filter
                                value={branch}
                                options={branchList}
                                onChange={(e) => {
                                    setBranch(e.value)
                                }}
                                id="branch"
                                name="branch"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="branch">Филиалы</label>
                            </span>
                    </div>
                </div>
            </div>
            <div className={cn('form-container')} style={{ paddingBottom: 0 }}>
                <div className={cn('edit-form')}>
                    <PDFReport clientId={clientId} branches={branch}/>
                    <PDFSentimentReport clientId={clientId} branches={branch}/>
                    <XLSReport clientId={clientId} branches={branch}/>

                </div>
            </div>
        </div>
    )
}

AdditionalActionsPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    clientStore: PropTypes.any,
    mainStore: PropTypes.any,
    branchStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'clientStore', 'mainStore', 'branchStore')(observer(AdditionalActionsPage));