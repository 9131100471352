import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import {
    Profile,
    ClientsBottomMenu,
    ReferencesBottomMenu,
    AnalyticsBottomMenu,
    Menu,
} from "components/header/index";
import HeaderLogo from '../images/header_logo.png';
import 'styles/header.scss';


const Header = ({ authStore, uiStore }) => {

    const isVisible = (page) => authStore.isAuthorized
        && uiStore.pageProperty.page === page;

    return (
        <div className={cn('header')}>
            <div className={cn('top-side-container')}>
                <div className={cn('top-side')}>
                    <div className={cn('logo-container')}>
                        <Link to="/">
                            <img src={HeaderLogo} alt="okreview" width={157} height={37}/>
                        </Link>
                    </div>
                    {authStore.isAuthorized && <>
                        <div className={cn('menu-container')}>
                            <Menu />
                        </div>
                        <div className={cn('profile-container')}>
                            <Profile />
                        </div>
                    </>}
                </div>
            </div>
            <div className={cn('separator-middle')} />
            {isVisible('clients') && <>
                <div className={cn('bottom-side-container')}>
                    <ClientsBottomMenu />
                </div>
                <div className={cn('separator-bottom')} />
            </>}
            {isVisible('references') && <>
                <div className={cn('bottom-side-container')}>
                    <ReferencesBottomMenu />
                </div>
                <div className={cn('separator-bottom')} />
            </>}
            {isVisible('analytics') && <>
                <div className={cn('bottom-side-container')}>
                    <AnalyticsBottomMenu />
                </div>
                <div className={cn('separator-bottom')} />
            </>}

        </div>
    )

};

Header.propTypes = {
    uiStore: PropTypes.any,
    authStore: PropTypes.any,
}

export default inject('authStore', 'uiStore')(observer(Header));