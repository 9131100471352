import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { reaction } from "mobx";
import _ from "underscore";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Sortable from "../../common/Sortable";
import Activity from "../../common/Activity";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import moment from "moment/moment";

const ParsingAccounts = ({ uiStore, breadcrumbsStore, parsingAccountsStore }) => {
    let history = useHistory();

    const { title, setTitle, items, pager, setPager, sort, setSort, resetFilter } = parsingAccountsStore;

    const [first, setFirst] = useState(0);

    useEffect(() => {
        resetFilter();

        uiStore.setPageProperty({
            page: 'references',
            className: 'locations'
        });

        breadcrumbsStore.addItem({
            linkName: 'Аккаунты парсинга',
            link: '/references/parsing-accounts',
        }, 0);

    }, []);

    useEffect(() => {
        parsingAccountsStore.loadItems()
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => parsingAccountsStore.pager.number,
            () => {
                parsingAccountsStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => parsingAccountsStore.title,
            (value) => {
                titleDebounceHandler(value)
            }
        );

        return () => disposer();
    }, []);

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && parsingAccountsStore.loadItems(),
        800
    );

    useEffect(() => {
        const disposer = reaction(
            () => JSON.stringify(parsingAccountsStore.sort),
            () => {
                parsingAccountsStore.loadItems()
            }
        );

        return () => disposer();
    }, []);

    const sortHandler = (obj) => {
        setSort(obj);
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    color={'color-red'}
                >
                    Справочники / Аккаунты парсинга
                </TitleH1>
            </div>
            <div className={cn('add-container')}>
                <Button
                    className={cn('p-button-outlined')}
                    label="Создать значение"
                    icon="pi pi-plus"
                    onClick={() => {
                        history.push('/references/parsing-accounts/new');
                    }}
                />
            </div>
            <div className={cn('search-container')}>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Поиск по значению справочника..."
                            name='title'
                            id='title'
                            value={title}
                            onChange={(e) => {
                                // noinspection JSUnresolvedVariable
                                setTitle(e.target.value);
                            }}
                        />
                    </span>
                    </div>
                </div>
            </div>
            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={1}>ID</th>

                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='title'
                            >Название аккаунта парсинга</Sortable>
                        </th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='username'
                            >Значение справочника</Sortable>
                        </th>
                        <th className={cn('date-head')}>
                            Создан
                        </th>
                        <th className={cn('date-head')}>
                            Изменен
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, key) => (
                        <tr key={key}>
                            <td className={cn('id-body')}>{item.id}</td>
                            <td style={{ width: '300px' }}>
                                <Link to={`/references/parsing-accounts/${item.id}/detail`}>{item.title}</Link>
                            </td>
                            <td>{item.username}</td>
                            <td>{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                            <td>{item.updatedAt ? moment(item.updatedAt).format('DD.MM.YYYY HH:mm') : '-'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first} /* номер элемента для отсчета страниц*/
                    rows={pager.size} /*кол-во элементов на странице*/
                    totalRecords={pager.itemCount} /*всего элементов*/
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    )
}

ParsingAccounts.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    parsingAccountsStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'parsingAccountsStore')(observer(ParsingAccounts));
