export default class Employee {
    #_id;
    #_lastname;
    #_firstname;

    constructor(employee) {
        this.#_id = employee.id || null;
        this.#_lastname = employee.lastname || '';
        this.#_firstname = employee.firstname || '';
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this.#_id;
    }

    /**
     * @return {string}
     */
    get lastname() {
        return this.#_lastname;
    }

    /**
     * @return {string}
     */
    get firstname() {
        return this.#_firstname;
    }
}
