import CommonPageStore from "./CommonPageStore";
import { Company } from "../models";
import { action, makeObservable, observable, toJS } from "mobx";
import uiStore from "./UiStore";
import { companyService } from "../services";

class CompanyStore extends CommonPageStore {
    /**
     * @type {Array<Company>}
     */
    items = [];

    // noinspection JSValidateTypes
    /**
     * @type {Company}
     */
    entity = Company.getEmptyEntity().getPlainObject();

    constructor() {
        super();

        makeObservable(this, {
            items: observable,
            entity: observable,
            setItems: action,
            setEntity: action,
            resetEntity: action,
        })
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    resetEntity = () => {
        this.setEntity({
            id: null,
            title: '',
            enabled: false,
            type: {
                code: null
            }
        });
    }

    saveEntity = () => {

        const data = {
            title: this.entity.title,
            enabled: this.entity.enabled,
            type: this.entity.type.code,
        }

        uiStore.startLoading();

        return companyService
            .saveEntity(this.entity.id, data)
            .then(() => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                );
            })

    }

    loadItems = (clientId) => {
        let filter = {
            page: toJS(this.pager),
            sort: toJS(this.sort),
            title: toJS(this.title),
        };

        if (this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }

        uiStore.startLoading();

        companyService.getCompanies(clientId, filter).then((result)=>{
            this.setItems(result?.items);
            this.setSort(result?.sort);
            this.setPager(result?.pager);
        })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadEntity = (id) => {
        uiStore.startLoading();
        companyService
            .loadEntity(id)
            .then(result => {
                this.setEntity(
                    result.getPlainObject()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });

    }


    addEntity = () => {
        const data = {
            clientId: this.entity.clientId,
            title: this.entity.title,
            enabled: this.entity.enabled,
            type: toJS(this.entity.type.code),
        }


        uiStore.startLoading();

        return companyService
            .addEntity(data)
            .then(result => {
                this.setEntity(
                    result.getPlainObject()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    /**
     * @private
     */
    prepareEntityData = () => {
        return {
            title: this.entity.title,
            enabled: this.entity.enabled,
        };
    }


}


const companyStore = new CompanyStore();
export default companyStore;
