import CommonPageStore from "./CommonPageStore";
import { Rule, Tag } from "../models/Tag";
import { action, makeObservable, observable, toJS } from "mobx";
import tagService from "../services/Tag";

class TagStore extends CommonPageStore {

    items = [];

    entity = new Rule({});

    constructor(props) {
        super(props);

        makeObservable(
            this,
            {
                items: observable,
                entity: observable,
                setItems: action,
                setEntity: action,
                resetEntity: action,
                addTag: action,
                removeTag: action,
                addSynonym: action,
                setTagValue: action,
                addSynonymValue: action,
                removeSynonym: action,
            }
        );
    }

    checkUniqueTagName = (object) => {
        let valid = true;
        let errors = [];
        let tagsStore = [];
        const tags = object.tags.map(tag => {
            let keywordsStore = [];
            const keywords = tag.keywords?.map(keyword => {

                if(keywordsStore.includes(keyword.name)) {
                    valid = false;
                    errors.push('Названия синонимов не уникальны')

                    return {
                        ...keyword,
                        ...{ valid: false }
                    }
                }
                else if(keyword.name === '') {
                    valid = false;
                    errors.push('Пустые названия синонимов недопустимы')

                    return {
                        ...keyword,
                        ...{ valid: false }
                    }
                }
                else {
                    keywordsStore.push(keyword.name)

                    return {
                        ...keyword,
                        ...{ valid: true }
                    }
                }
            });

            tag = {
                ...tag,
                ...{ keywords }
            }

            if(tagsStore.includes(tag.name)) {
                valid = false;
                errors.push('Названия тегов не уникальны')

                return {
                    ...tag,
                    ...{ valid: false }
                }
            }
            else if(tag.name === '') {
                valid = false;
                errors.push('Пустые названия тегов недопустимы')

                return {
                    ...tag,
                    ...{ valid: false }
                }
            }

            else {
                tagsStore.push(tag.name)

                return {
                    ...tag,
                    ...{ valid: true }
                }
            }
        })

        const processedFields = {
            ...object,
            ...{ tags }
        }

        return { valid, errors, processedFields }
    }

    prepareForApi = (object) => {
        let copy = { ...object }
        const tags = copy.tags.map(tag => {
            let keywords = [];
            delete tag.valid;
            tag.keywords.map(keyword => {
                keywords.push(keyword.name)
            })

            return {
                ...tag,
                ...{ keywords: keywords },
            }
        })

        const object2 = {
            ...copy,
            ...{ tags: tags },
        }

        this.setEntity(object2)
    }

    prepareForForm = () => {
        const tags = this.entity.tags.map(tag=>{
            let keywords = [];
            tag.keywords.map(keyword => {
                keywords.push(
                    {
                        name: keyword,
                        valid: !!keyword,
                    }
                )
            })

            return {
                ...tag,
                ...{ keywords: keywords },
                ...{ valid: !!tag.name }
            }
        })

        const object = {
            ...this.entity,
            ...{ tags: tags },
            ...{ valid: !!this.entity.title }
        }

        return object;
    }

    removeTag = (index) => {
        this.entity.tags.splice(index, 1);
    }

    addTag = () => {
        this.entity.tags.push(new Tag({ name: '', keywords:[''] }));
    }

    addSynonym = (indexTag, keywords) => {
        this.entity.tags[indexTag] = {
            name:this.entity.tags[indexTag].name,
            keywords: keywords
        }
    }

    removeSynonym = (tagIndex, index) => {
        this.entity.tags[tagIndex].keywords.splice(index, 1);
    }

    addSynonymValue = (indexTag, keywordIndex, value) => {
        this.entity.tags[indexTag].keywords[keywordIndex] = value;
    }

    setTagValue = (index, value) => {
        this.entity.tags[index].name = value;
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    resetEntity = () => {
        this.setEntity(new Rule({}));
    }

    loadItems() {
        this.resetEntity();

        let filter = {
            pager: this.pager,
            sort: this.sort,
            title: this.title,
        }

        if (this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }

        return tagService.loadItems(filter)
            .then(result => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
                this.setSort(result?.sort)
                return result;
            })
            .catch(e => e)
    }

    addEntity() {
        const data = {
            title: this.entity.title,
            tags: toJS(this.entity.tags),
            enabled: this.entity.enabled,
        }

        return tagService
            .addEntity(data)
            .then(result => {
                return result;
            })
            .catch(e => Promise.reject(e));

    }

    saveEntity() {
        const data = {
            title: this.entity.title,
            tags: this.entity.tags,
            enabled: this.entity.enabled,
        }

        return tagService
            .saveEntity(this.entity.id, data)
            .then(result => result)
            .catch(e => Promise.reject(e));
    }

    loadEntity(id) {
        return tagService
            .loadEntity(id)
            .then(result => {
                this.setEntity(result);
                return result;
            })
            .catch(e=>e)
    }


    /**
     *
     * @returns {Promise<{pager: *, sort: *, items: *}>}
     */
    loadAllRules () {
        return tagService.loadAllRules()
            .then(result => {
                return result.items.map(item=> {
                    return {
                        id: item.id,
                        title: item.title,
                        selected: false,
                    }
                })
            })
            .catch(e => e)
    }

    /**
     *
     * @param clientId
     * @returns {Promise<{pager: {}, linkCount: number, items: []}>}
     */
    loadRulesForClient(clientId) {
        return tagService.loadRulesForClient(clientId)
            .then(result => {
                return result.items.map(item=> {
                    return {
                        id: item.rule.id,
                        title: item.rule.title
                    }
                })
            })
            .catch(e => e)
    }

    /**
     *
     * @param ruleId
     * @param clientId
     * @returns {Promise<string>}
     */
    cancelRuleToClient(ruleId, clientId) {
        return tagService.cancelRuleToClient(ruleId, clientId)
            .then(result => {
                return result;
            })
            .catch(e => Promise.reject(e))
    }

    /**
     *
     * @param ruleId
     * @param clientId
     * @returns {Promise<string>}
     */
    applyRuleToClient(ruleId, clientId) {
        return tagService.applyRuleToClient(ruleId, clientId)
            .then(result => {
                return result;
            })
            .catch(e => Promise.reject(e))
    }
}

const tagStore = new TagStore();
export default tagStore;