import React, { useEffect, useState } from "react";
import cn from "classnames";
import OkInputText from "../../../common/OkInputText";
import { Button } from "primereact/button";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import Synonym from "./TagSynonym";

const TagLine = ({ item, index, disabled = false, removeTag, onChange, addKeywords }) => {
    const [value, setValue]  = useState('');
    const [keywords, setKeywords] = useState([])
    const [show, setShow] = useState(true)


    useEffect(() => {
        setKeywords(item.keywords)
        setValue(item.name)
    }, [])


    useEffect(() => {
        addKeywords(index, keywords)
    }, [keywords])

    const handlerChangeSynonym = (index, value) => {
        let copy = [ ...keywords ]
        copy[index] = value;
        setKeywords(copy)
    }

    const addSynonym = () => {
        setKeywords(prev => {
            return [
                ...prev,
                ...[{ name:'', valid: false }]
            ]
        })
    }

    const handlerRemoveSynonym = (tagIndex, index) => {
    /*
        не смог в Synonym передать динамический key (смотреть в Synonym почему)
        поэтому добавил принудительный ререндер компонента для обновления списка keywords
    */
        setShow(false);
        setTimeout(()=>{
            setShow(true);
        }, 0)

        let copy = [...keywords]
        copy.splice(index, 1)
        setKeywords(copy)
    }

    return (
        <>
            <div className={cn('edit-form__row')}>
                <div className={cn('edit-form__col')}>
                    &nbsp;
                </div>
            </div>

            <div className={cn('edit-form__row')}>
                <div className={cn('edit-form__col')}>
                    <div className="p-field" style={{ 'paddingBottom': '5px' }}>
                            {disabled ? <>
                                <span className="p-float-label">
                                    <OkInputText
                                        id={'title'+index}
                                        name={'title'+index}
                                        type='text'
                                        value={value}
                                        disabled={disabled}
                                    />
                                    <label htmlFor={'title'+index}>Название тега</label>
                                </span>
                            </>
                                :
                            <>
                            <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <OkInputText
                                            id={'title'+index}
                                            name={'title'+index}
                                            type='text'
                                            value={value ?? ''}
                                            disabled={disabled}
                                            isrequired="true"
                                            className={cn({ 'p-invalid': !item.valid })}

                                            onChange={(e) => {
                                                setValue(e.target.value)
                                                onChange(index, e.target.value)
                                            }}
                                        />

                                        <label htmlFor={'title'+index}>Название тега</label>
                                    </span>
                                    <Button
                                        icon="pi pi-times"
                                        className={cn('p-button-danger', 'p-button-outlined')}
                                        style={{ marginLeft: '-1px' }}
                                        onClick={()=>{removeTag(index)}}/>
                                </div>
                            </>
                            }

                    </div>
                </div>
            </div>
            <>
                <div className={cn('edit-form__row')}>
                    {show && keywords.length > 0 && keywords.map((keyword, key) => {
                        return <Synonym
                            item={keyword}
                            tagline={item}
                            key={key}
                            index={key}
                            tagIndex={index}
                            disabled={disabled}
                            onChange={handlerChangeSynonym}
                            removeSynonym={handlerRemoveSynonym}
                        />
                    })}

                    {!disabled && <div className={cn('edit-form__col')}>
                        <div className="p-field">
                            <Button
                                className={cn('p-button-outlined ', 'p-button-sm')}
                                label="Добавить синоним"
                                icon="pi pi-plus"
                                onClick={addSynonym}
                            />
                        </div>
                    </div>}
                </div>
            </>
        </>
    )
}

TagLine.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    disabled: PropTypes.bool,
    removeTag: PropTypes.func,
    onChange: PropTypes.func,
    addKeywords: PropTypes.func,
}

export default inject('tagStore')(observer(TagLine));