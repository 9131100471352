import SmallBranch from "./SmallBranch";
import SmallAdPlatform from "./SmallAdPlatform";

export default class BranchAdPlatformLink {
    #_branch;
    #_adplatform;
    #_linked;

    constructor(branchManagerLink) {
        this.#_branch = (branchManagerLink.branch) ? new SmallBranch(branchManagerLink.branch) : new SmallBranch();
        this.#_adplatform = (branchManagerLink.adplatform) ? new SmallAdPlatform(branchManagerLink.adplatform) : new SmallAdPlatform();
        this.#_linked = branchManagerLink.linked || false;
    }

    /**
     * @return {SmallBranch}
     */
    get branch() {
        return this.#_branch;
    }

    /**
     * @return {SmallAdPlatform}
     */
    get adplatform() {
        return this.#_adplatform;
    }

    /**
     * @return {boolean}
     */
    get linked() {
        return this.#_linked;
    }
}
