import {
    action,
    makeObservable,
    observable, toJS
} from "mobx";
import { branchService, clientService } from "services"
import uiStore from "./UiStore";
import CommonPageStore from "stores/CommonPageStore";

class ClientStore extends CommonPageStore {
    enabled = null;

    items = [];

    entity = {
        id: null,
        title: '',
        enabled: false,
        counters: {},
    };

    /**
     * @type {ClientFeature[]}
     */
    features = [];

    /**
     * @type {string[]}
     */
    unsavedEnabledFeatures = [];

    /**
     * @type {string[]}
     */
    unsavedDisabledFeatures = [];

    mutedFeatures = {};

    clientList = [];

    constructor() {
        super();
        makeObservable(this, {
                items: observable,
                entity: observable,
                features: observable,
                mutedFeatures: observable,
                clientList: observable,
                setItems: action,
                setEntity: action,
                resetEntity: action,
                setClientList:action,
                setFeatures: action,
                enableFeature: action,
                disableFeature: action,
                setMutedFeatureByCode: action,
                setMutedFeatures: action,
            }
        );
    }

    addEntity = () => {
        const data = {
            title: this.entity.title,
            enabled: this.entity.enabled,
            technicalAccount: this.entity.technicalAccount,
            reviewCollectorTemplate: this.entity.reviewCollectorTemplate
        }

        uiStore.startLoading();

        return clientService
            .addEntity(data)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                this.setClientList([]);
                uiStore.stopLoading();
            });
    }

    saveEntity = () => {
        const data = {
            title: this.entity.title,
            enabled: this.entity.enabled,
            technicalAccount: this.entity.technicalAccount,
            reviewCollectorTemplate: this.entity.reviewCollectorTemplate
        }

        uiStore.startLoading();

        return clientService
            .saveEntity(this.entity.id, data)
            .then(() => {
                const enableBoolFeatures = [];
                const disableBoolFeatures = [];

                this.unsavedEnabledFeatures.forEach((featureCode) => {
                    if (this.features.filter((f) => f.feature === featureCode && f.value).length === 0) {
                        enableBoolFeatures.push(featureCode);
                    }
                });
                this.unsavedDisabledFeatures.forEach((featureCode) => {
                    if (this.features.filter((f) => f.feature === featureCode && !f.value).length === 0) {
                        disableBoolFeatures.push(featureCode);
                    }
                });

                this.unsavedEnabledFeatures = [];
                this.unsavedDisabledFeatures = [];

                return Promise.all(
                    [
                        ...enableBoolFeatures.map((featureName) => clientService.enableBooleanFeature(this.entity.id, featureName)),
                        ...disableBoolFeatures.map((featureName) => clientService.disableBooleanFeature(this.entity.id, featureName)),
                    ]);
            })
            .finally(() => {
                this.setClientList([]);
                uiStore.stopLoading();
            });
    }

    loadEntity = (id) => {
        uiStore.startLoading();

        this.unsavedEnabledFeatures = [];
        this.unsavedDisabledFeatures = [];
        this.setMutedFeatures({});

        return clientService
            .loadEntity(id)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );

                return clientService.loadClientFeatures(id);
            })
            .then((features) => {
                this.setFeatures(features.map(feature => feature.getPlainObject()));
                this.features.forEach((f) => {
                    this.setMutedFeatureByCode(f.feature, this.isParentFeatureMuted(f.parent));
                });
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadAllItems = () => {
        uiStore.startLoading();
        let clients = [];

        if(this.clientList.length > 0) {
            uiStore.stopLoading();
            return Promise.resolve(this.clientList);
        }


//TODO убрать зависимость от кол-ва станиц.
        return clientService
            .loadItems({
                pager: { size:100, number:1 },
                sort: {
                    column: 'title',
                    order: 'asc'
                },
            })
            .then((result) => {
                result?.items.map(item=>{
                    clients.push( {
                        name: item.title,
                        code: item.id,
                    })
                });

                return clientService
                    .loadItems({
                        pager: { size:100, number:2 },
                        sort: {
                            column: 'title',
                            order: 'asc'
                        },
                    });
            })
            .then((result) => {
                result?.items.map(item=>{
                    clients.push( {
                        name: item.title,
                        code: item.id,
                    })
                });

                return clientService
                    .loadItems({
                        pager: { size:100, number:3 },
                        sort: {
                            column: 'title',
                            order: 'asc'
                        },
                    });
            })

            .then(result => {

                result?.items.map(item=>{
                    clients.push( {
                        name: item.title,
                        code: item.id,
                    })
                });
                this.setClientList(clients)

                return clients;
            })
            .finally(()=>{
                uiStore.stopLoading();
            })
    }

    loadItems = () => {
        let filter = {
            pager: this.pager,
            sort: this.sort,
            title: this.title,
        }

        if (this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }

        uiStore.startLoading();
        clientService
            .loadItems(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
                this.setSort(result?.sort)
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    onDownloadPdf = (clientId, filter) => {
        return clientService.onDownloadPdf(clientId, filter).then((result) => result);
    }

    onDownloadPdfSentimentReport = (clientId, filter) => {
        return clientService.onDownloadPdfSentimentReport(clientId, filter).then((result) => result);
    }

    onDownloadXls = (clientId, filter) => {
        return clientService.onDownloadXls(clientId, filter).then((result) => result);
    }

    getAllBranches = (clientId) => {
        const filter = {
            clientId: clientId,
            enabled: 1,
            page: {
                size: 100,
                number: 1,
            }
        }

        return branchService
            .getAllBranches(filter)
            .then((result) => {
                return result
            })
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setClientList = (clientList) => {
        this.clientList = clientList || [];
    }


    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    setFeatures = (features) => {
        this.features = features;
    }

    setMutedFeatures = (val) => {
        this.mutedFeatures = val;
    }

    setMutedFeatureByCode = (featureCode, isDisabled) => {
        if (isDisabled) {
            this.mutedFeatures[featureCode] = true;
        } else {
            delete this.mutedFeatures[featureCode];
        }
    };

    resetEntity = () => {
        this.setEntity({
            id: null,
            title: '',
            enabled: false,
            counters: {},
            technicalAccount: '',
        });
        this.setFeatures([]);
        this.setMutedFeatures({});
        this.unsavedEnabledFeatures = [];
        this.unsavedDisabledFeatures = [];
    }

    /**
     * @param {string} featureCode
     */
    enableFeature = (featureCode) => {
        if (this.unsavedEnabledFeatures.indexOf(featureCode) === -1) {
            this.unsavedEnabledFeatures.push(featureCode);
        }

        if (this.unsavedDisabledFeatures.indexOf(featureCode) > -1) {
            this.unsavedDisabledFeatures.splice(
                this.unsavedDisabledFeatures.indexOf(featureCode),
                1,
            )
        }

        this.features.forEach((f) => {
            this.setMutedFeatureByCode(f.feature, this.isParentFeatureMuted(f.parent));
        });
    }

    /**
     * @param {string} featureCode
     */
    disableFeature = (featureCode) => {
        if (this.unsavedDisabledFeatures.indexOf(featureCode) === -1) {
            this.unsavedDisabledFeatures.push(featureCode);
        }

        if (this.unsavedEnabledFeatures.indexOf(featureCode) > -1) {
            this.unsavedEnabledFeatures.splice(
                this.unsavedEnabledFeatures.indexOf(featureCode),
                1,
            )
        }

        this.features.forEach((f) => {
            this.setMutedFeatureByCode(f.feature, this.isParentFeatureMuted(f.parent));
        });
    }

    /**
     * @param {string|null} parentFeatureCode
     * @private
     */
    isParentFeatureMuted = (parentFeatureCode) => {
        if (parentFeatureCode === null) {
            return false;
        }

        if (this.unsavedDisabledFeatures.indexOf(parentFeatureCode) > -1) {
            return true;
        }

        if (this.unsavedEnabledFeatures.indexOf(parentFeatureCode) > -1) {
            return false;
        }

        return this.features.filter((f) => f.feature === parentFeatureCode && f.value).length === 0;
    }
}

const clientStore = new ClientStore();
export default clientStore;
