import { api } from "../core";
import { serialize } from "../helper";
import uiStore from "../stores/UiStore";
import { Rule } from "../models/Tag";

class TagService {

    loadAllRules() {
        const filter = {
            pager: {
                size: 100,
                number: 1,
            },
            sort: {
                column: 'title',
                order: 'desc'
            },
            enabled: 1,
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/mlservice/text-markup/rules?${serialize(filter)}`)
            .then((result) => {
                return {
                    items: result.items.map(item => new Rule(item)),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch((e) => e);

    }

    loadRulesForClient(clientId, onlyLinked = 1) {
        const filter = {};

        if (onlyLinked !== null) {
            filter.onlyLinks = (onlyLinked) ? 1 : 0;
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/clients/${clientId}/markup-rules?${serialize(filter)}`, ()=>{})
            .then((result) => {
                return result;
            })
            .catch((e) => e);
    }

    cancelRuleToClient(ruleId, clientId) {

        return api
            .delete(`${process.env.REACT_APP_API_URL}/admin/mlservice/text-markup/rules/${ruleId}/clients/${clientId}`)
            .then(result => {
                return result;
            })
            .catch(e => Promise.reject(e));
    }

    applyRuleToClient(ruleId, clientId) {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/mlservice/text-markup/rules/${ruleId}/clients/${clientId}`, {})
            .then(result => {
                return result
            })
            .catch(e => Promise.reject(e));
    }

    loadItems(filter) {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/mlservice/text-markup/rules?${serialize(filter)}`)
            .then((result) => {
                return {
                    items: result.items.map(item => new Rule(item)),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch((error) => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );

                return Promise.reject();
            });
    }

    addEntity(data) {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/mlservice/text-markup/rules`, data)
            .then(result => {
                return result
            })
            .catch(e => Promise.reject(e));
    }

    saveEntity(id, data) {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/mlservice/text-markup/rules/${id}`, data)
            .then(result => result)
            .catch(e => Promise.reject(e));
    }

    loadEntity(id) {

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/mlservice/text-markup/rules/${id}`)
            .then(result => {
                return new Rule(result);
            })
            .catch(e => Promise.reject(e));
    }



}

const tagService = new TagService();
export default tagService;