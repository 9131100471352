import { AdPlatformPublication } from "models";
import { action, makeObservable, observable } from "mobx";
import { integrationService } from "services";
import uiStore from "stores/UiStore";

class AdPlatformPublicationStore {
    entity = AdPlatformPublication.getEmptyEntity().getPlainObject();

    constructor() {
        makeObservable(this, {
            entity: observable,
            setEntity: action,
        })
    }

    addEntity = (integrationId) => {
        return integrationService.addPublisher(integrationId, {
            publisherId: this.entity.connection.info.id,
            enabled: this.entity.connection.enabled,
        }).then(result => {
            uiStore.setMessage(
                {
                    severity: 'success',
                    summary: 'Успех',
                    detail: 'Изменения сохранены',
                    sticky: false,
                }
            );
            return result;
        }).catch(error => {
            uiStore.setMessage(
                {
                    severity: 'error',
                    summary: error.error.type,
                    detail: error.error.description,
                }
            );

        });
    }

    saveEntity = (integrationId) => {
        return integrationService.editPublisher(integrationId, {
            publisherId: this.entity.connection.info.id,
            enabled: this.entity.connection.enabled,
        }).then(result => {
            uiStore.setMessage(
                {
                    severity: 'success',
                    summary: 'Успех',
                    detail: 'Изменения сохранены',
                    sticky: false,
                }
            );
            return result;
        }).catch(error => {
            uiStore.setMessage(
                {
                    severity: 'error',
                    summary: error.error.type,
                    detail: error.error.description,
                }
            );
        });
    }

    loadEntity = (integrationId) => {
        integrationService.getPublisher(integrationId).then(data => {
            this.setEntity(data);
        });
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }


}

const adPlatformPublicationStore = new AdPlatformPublicationStore();
export default adPlatformPublicationStore;
