import moment from "moment";
export class AdPlatformParserConnectionSource {
    #id;
    #type;
    #extreview;

    constructor(adPlatformParserConnectionSource) {
        this.#id = adPlatformParserConnectionSource.id || null;
        this.#type = adPlatformParserConnectionSource.type || '';
        this.#extreview = adPlatformParserConnectionSource.extreview || '';
    }

    get id() {
        return this.#id;
    }

    get type() {
        return this.#type;
    }

    get extreview() {
        return this.#extreview;
    }

    getPlainObject() {
        return {
            id: this.id,
            type: this.type,
            extreview: this.extreview,
        }
    }

}
export class AdPlatformParserConnectionRequest {
    #lastError;
    #lastRequest;
    #lastSync;

    constructor(adPlatformParserConnectionRequest) {
        this.#lastError = adPlatformParserConnectionRequest.lastError || '';
        this.#lastRequest = adPlatformParserConnectionRequest.lastRequest || '';
        this.#lastSync = adPlatformParserConnectionRequest.lastSync || '';
    }

    get lastError() {
        return this.#lastError;
    }

    get lastRequest() {
        return this.#lastRequest;
    }

    get lastSync() {
        return this.#lastSync;
    }


    getPlainObject() {
        return {
            lastError: this.lastError,
            lastRequest: this.lastRequest,
            lastSync: this.lastSync,
        }
    }


}

export class AdPlatformParserConnection {
    #enabled;
    #parent;
    #deleted;
    #source;
    #request;
    #createdAt;
    #updatedAt;
    #deletedAt;

    constructor(adPlatformParserConnection) {
        this.#enabled = adPlatformParserConnection.enabled || false;
        this.#parent =  adPlatformParserConnection.parent || null;
        this.#deleted = adPlatformParserConnection.deleted || false;
        this.#source = new AdPlatformParserConnectionSource(adPlatformParserConnection.source || {});
        this.#request = new AdPlatformParserConnectionRequest(adPlatformParserConnection.request || {});
        this.#createdAt = adPlatformParserConnection.createdAt || null;
        this.#updatedAt = adPlatformParserConnection.updatedAt || null;
        this.#deletedAt = adPlatformParserConnection.deletedAt || null;
    }

    get enabled() {
        return this.#enabled;
    }

    get parent() {
        return this.#parent;
    }

    get deleted() {
        return this.#deleted;
    }

    get source() {
        return this.#source;
    }

    get request() {
        return this.#request;
    }

    get createdAt() {
        return this.#createdAt ? moment(this.#createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get updatedAt() {
        return this.#updatedAt ? moment(this.#updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get deletedAt() {
        return this.#deletedAt ? moment(this.#deletedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    getPlainObject() {
        return {
            enabled: this.enabled,
            parent: this.parent,
            deleted: this.deleted,
            source: this.source.getPlainObject(),
            request: this.request.getPlainObject(),
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt,
        }
    }

}

const CONNECT_STATUS = {
    'disconnected': 'Парсер отсоединён от источника',
    'created': 'Загрузка данных...',
    'queued': 'Соедиенние ожидает сбора данных',
    'connected': 'Данные с источника получены',
};

export class AdPlatformParserParsing {
    #enabled;
    #reason;
    #connect;
    #disabledAt;

    constructor(adPlatformParserParsing = {}) {
        this.#enabled = adPlatformParserParsing.enabled || false;
        this.#reason = adPlatformParserParsing.reason || null;
        this.#connect = adPlatformParserParsing.connect || '';
        this.#disabledAt = adPlatformParserParsing.disabledAt || null;
    }

    get connect_status() {
        return this.connect ? CONNECT_STATUS[this.connect] : 'Ошибка статуса...';
    }

    get enabled() {
        return this.#enabled;
    }

    get reason() {
        return this.#reason;
    }

    get connect() {
        return this.#connect;
    }

    get disabledAt() {
        return this.#disabledAt ? moment(this.#disabledAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    getPlainObject() {
        return {
            enabled: this.enabled,
            reason: this.reason,
            connect: this.connect,
            disabledAt: this.disabledAt,
            connect_status: this.connect_status,
        }
    }
}

export class AdPlatformParserGeodata {
    #enabled;

    constructor(adPlatformParserGeodata) {
        this.#enabled = adPlatformParserGeodata.enabled || false;
    }

    get enabled() {
        return this.#enabled;
    }

    getPlainObject() {
        return {
            enabled: this.enabled,
        }
    }

}

export class AdPlatformParserAuth {
    #enabled;
    #checkedAt;
    #account;

    constructor(adPlatformParserAuth) {
        this.#enabled = adPlatformParserAuth.enabled || false;
        this.#checkedAt = adPlatformParserAuth.checkedAt || null;
        this.#account =  adPlatformParserAuth.account || ''
    }

    get enabled() {
        return this.#enabled;
    }

    get checkedAt() {
        return this.#checkedAt ? moment(this.#checkedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get account() {
        return this.#account;
    }

    getPlainObject() {
        return {
            enabled: this.enabled,
            checkedAt: this.checkedAt,
            account: this.account,
        }
    }
}

export default class AdPlatformParser {
    #connection;
    #parsing;
    #auth;
    #geodata;

    constructor(adPlatformParser) {
        this.#connection = new AdPlatformParserConnection(adPlatformParser.connection || {});
        this.#parsing = new AdPlatformParserParsing(adPlatformParser.parsing || {});
        this.#auth = new AdPlatformParserAuth(adPlatformParser.auth || {});
        this.#geodata = new AdPlatformParserGeodata(adPlatformParser.geodata || {})

    }

    get connection() {
        return this.#connection;
    }

    get parsing() {
        return this.#parsing;
    }

    get auth() {
        return this.#auth;
    }

    get geodata() {
        return this.#geodata;
    }


    getPlainObject() {
        return {
            connection: this.connection.getPlainObject(),
            parsing: this.parsing.getPlainObject(),
            auth: this.auth.getPlainObject(),
            geodata: this.geodata.getPlainObject(),
        }
    }

    static getEmptyEntity() {
        return new AdPlatformParser({});
    }
}
