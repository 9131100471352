import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

import { reaction } from "mobx";
import _ from "underscore";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";

import { InputText } from "primereact/inputtext";
import Activity from "../../common/Activity";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";

const AllBranchesPage = ({ uiStore, breadcrumbsStore, branchStore, clientStore }) => {

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'branches',
            className: 'branches'
        });

        breadcrumbsStore.addItem({
            linkName: 'Филиалы',
            link: '/branches',
        }, 0);

    }, [])

    /** @type {Array<Branch>} items */
    const {
        title,
        setTitle,
        items,
        pager,
        setPager,
        resetFilter,
        clientId,
        setClientId,
        fEnabled,
        setFEnabled
    } = branchStore;
    const [first, setFirst] = useState(0);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        resetFilter();

        clientStore.loadAllItems()
            .then(result => {
                setClients(result)
            });


    }, []);

    useEffect(() => {
        const disposers = [
            reaction(
                () => branchStore.pager.number,
                () => {
                    branchStore.getAllBranches();
                }
            ),
            reaction(
                () => [
                    branchStore.clientId,
                ],
                () => {
                    branchStore.getAllBranches();
                }
            ),
            reaction(
                () => [
                    branchStore.fEnabled,
                ],
                () => {
                    branchStore.getAllBranches();
                }
            ),
        ]

        return () => {
            disposers.map(func => func());
        }
    }, []);

    useEffect(() => {
        const disposer = reaction(
            () => branchStore.title,
            (value) => {
                titleDebounceHandler(value)
            }
        );

        return () => disposer();
    }, []);

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && branchStore.getAllBranches(),
        800
    );

    const getClientName = (clientId) => {
        return clients.find(item => item.code === clientId)
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    ico={'house_gradient'}
                    color={'color-lilac'}
                >
                    Филиалы
                </TitleH1>
            </div>

            <div className={cn('search-container')}>
                <div className={cn('dropdown-container')}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                filter
                                value={clientId}
                                options={
                                    [
                                        ...[{ 'name': 'Все', 'code': -1 }],
                                        ...clients,
                                    ]
                                }
                                onChange={(e) => {
                                    setClientId(e.value);
                                }}
                                id="client"
                                name="client"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="client">Клиент</label>
                            </span>
                    </div>
                </div>

                <div className={cn('dropdown-container')} style={{ marginLeft: '24px', marginRight: '24px' }}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fEnabled}
                                options={[
                                    { name: 'Все', code: -1 },
                                    { name: 'Активна', code: 1 },
                                    { name: 'Не активна', code: 0 },
                                ]}
                                onChange={(e) => {
                                    setFEnabled(e.value)
                                }}
                                id="fEnabled"
                                name="fEnabled"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fEnabled">Активность</label>
                            </span>
                    </div>
                </div>

                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText
                                placeholder="Поиск по названию филиала..."
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>

            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>Название филиала</th>
                        <th>Клиент</th>
                        <th>Тип филиала</th>
                        <th>Создан</th>
                        <th>Изменен</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.length > 0 && items.length > 0 && items.map((item, key) => (
                        <tr key={key}>
                            <td>{item.id}</td>
                            <td className={cn('activity-data')}>
                                <Activity activity={item.enabled}/>
                            </td>
                            <td>
                                <Link to={`/clients/${item.clientId}/branches/${item.id}/detail`}>{item.title}</Link>
                            </td>
                            <td>
                                <Link to={`/clients/${item.clientId}/detail`}>{clients.length > 0 && getClientName(item.clientId)?.name || 'Нет названия'}</Link>
                            </td>
                            <td>{item.type.title}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.updatedAt}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first}
                    rows={pager.size}
                    totalRecords={pager.itemCount}
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    );
}

AllBranchesPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    branchStore: PropTypes.any,
    clientStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'branchStore', 'clientStore')(observer(AllBranchesPage));

