import { action, makeObservable, observable } from "mobx";
import { administratorService } from "services";
import uiStore from "./UiStore";
import CommonPageStore from "stores/CommonPageStore";

class AdministratorStore extends CommonPageStore {
    enabled = null;

    firstname = '';

    items = [];

    entity = {
        id: null,
        lastname: '',
        firstname: '',
        email: '',
        enabled: false,
        password: '',
    };

    constructor() {
        super();

        makeObservable(this, {
                items: observable,
                entity: observable,

                setItems: action,
                setEntity: action,
                resetEntity: action,
                setPassword: action,
                resetPassword: action,
            }
        );
    }

    addEntity = () => {
        const data = this.parseData();

        uiStore.startLoading();

        return administratorService
            .addEntity(data)
            .then(result => {
                if(result.statusCode === 422){
                    return Promise.reject(result)
                }
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    parseData = () => {
        const data = {
            lastname: this.entity.lastname,
            firstname: this.entity.firstname,
            email: this.entity.email,
            enabled: this.entity.enabled,
        }

        const password = (this.entity.password.length !== 0) ? { password: this.entity.password } : null

        return {
            ...data,
            ...password,
        }
    }

    saveEntity = () => {
        const data = this.parseData();

        uiStore.startLoading();

        return administratorService
            .saveEntity(this.entity.id, data)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });

    }

    loadEntity = (id) => {
        uiStore.startLoading();
        administratorService
            .loadEntity(id)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadItems = () => {
        const filter = {
            pager: this.pager,
            sort: this.sort,
            lastname: this.lastname,
            enabled: this.enabled,
        }

        uiStore.startLoading();

        administratorService
            .loadItems(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
                this.setSort(result?.sort)
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    getPassword = () => {
        let buf = new Uint8Array(6);
        window.crypto.getRandomValues(buf);

        return btoa(String.fromCharCode.apply(null, buf));
    }

    resetPassword = () => {
        this.setEntity({
            password: '',
        })
    }

    setPassword = () => {
        this.entity.password = this.getPassword();
        return Promise.resolve(this.entity.password);
    }

    resetEntity = () => {
        this.setEntity({
            id: null,
            firstname: '',
            lastname: '',
            email: '',
            enabled: false,
            password: '',
        })
    }
}

const administratorStore = new AdministratorStore();
export default administratorStore;
