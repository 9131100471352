import React from "react";
import { Route } from "react-router";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import { Redirect } from "react-router-dom";

const AuthRoute = ({ children, exact, path, authStore }) => {
    const { isAuthorized, setPrevUrl } = authStore;

    if(!isAuthorized) {
        setPrevUrl(window.location.pathname)
    }

    return isAuthorized === true
        ?
        <Route exact={exact} path={path}>{children}</Route>
        :
        <Redirect to="/" />;
}

AuthRoute.propTypes = {
    authStore: PropTypes.any,
    children: PropTypes.any,
    exact: PropTypes.bool,
    path: PropTypes.string,
}

export default inject('authStore')(AuthRoute);
