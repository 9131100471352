import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import cn from "classnames";
import { isValidUrl } from "helper";

const OkInputText = (props) => {
    // eslint-disable-next-line react/prop-types
    const { value, isrequired, isurl } = props;
    const [blur, setBlur] = useState(false);
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
        setInvalid(() => {

//TODO убрать "isurl" и запилить реги или что-то вроде.
            if(isrequired && isurl) {
                return  !value || !isValidUrl(value);
            }
            else if(isurl) {
                return value && !isValidUrl(value);
            }
            else if (isrequired) {
                return  !value;
            }

        });
    }, [value]);

    return <InputText
        {...props}
        // eslint-disable-next-line react/prop-types
        className={cn({ 'p-invalid': (blur && invalid) }, props.className)}
        onBlur={() => {
            setBlur(true);
        }}
    />
}

export default OkInputText;
