import moment from "moment";
import BranchType from "models/BranchType";

/**
 * @typedef {string} BranchType
 **/

/**
 * @readonly
 * @enum {BranchType}
 */
export const BRANCH_TYPE = {
    NEW: 'new',
    USED: 'used',
    GARAGE: 'garage',

    /**
     * @param {BranchType} type
     * @return {string}
     */
    getTitle: (type) => {
        switch (type) {
            case BRANCH_TYPE.NEW:
                return 'Новые авто';

            case BRANCH_TYPE.USED:
                return 'Авто с пробегом';

            case BRANCH_TYPE.GARAGE:
                return 'СТО';
        }

        return type;
    },

    /**
     * @returns {Array<{code: BranchType, title: string}>}
     */
    getAll: () => {
        return [
            {
                code: BRANCH_TYPE.NEW,
                title: BRANCH_TYPE.getTitle(BRANCH_TYPE.NEW),
            },
            {
                code: BRANCH_TYPE.USED,
                title: BRANCH_TYPE.getTitle(BRANCH_TYPE.USED),
            },
            {
                code: BRANCH_TYPE.GARAGE,
                title: BRANCH_TYPE.getTitle(BRANCH_TYPE.GARAGE),
            },
        ];
    },
};

class BranchGroup {
    #_id;
    #_title;

    constructor(branchGroup) {
        this.#_id = branchGroup.id || null;
        this.#_title = branchGroup.title || '';
    }

    /**
     * @returns {int}
     */
    get id() {
        return this.#_id;
    }

    /**
     * @returns {string}
     */
    get title() {
        return this.#_title;
    }

    /**
     * @returns {{id: int, title: string}}
     */
    getPlainObject() {
        return {
            id: this.id,
            title: this.title,
        };
    }
}

class BranchLocation {
    #_id;
    #_title;

    constructor(branchLocation) {
        this.#_id = branchLocation.id || null;
        this.#_title = branchLocation.title || '';
    }

    /**
     * @returns {int}
     */
    get id() {
        return this.#_id;
    }

    /**
     * @returns {string}
     */
    get title() {
        return this.#_title;
    }

    /**
     * @returns {{id: int, title: string}}
     */
    getPlainObject() {
        return {
            id: this.id,
            title: this.title,
        };
    }
}

class BranchBrand {
    #_id;
    #_title;

    constructor(branchBrand) {
        this.#_id = branchBrand.id || null;
        this.#_title = branchBrand.title || '';
    }

    /**
     * @returns {int}
     */
    get id() {
        return this.#_id;
    }

    /**
     * @returns {string}
     */
    get title() {
        return this.#_title;
    }

    /**
     * @returns {{id: int, title: string}}
     */
    getPlainObject() {
        return {
            id: this.id,
            title: this.title,
        };
    }
}

/**
 * @typedef {{
 * city: string|null,
 * postcode: string|null|undefined,
 * street: string|null,
 * building: string|null,
 * additional: string|null|undefined }} BranchAddress
 */

/**
 * @type {BranchAddress}
 * @readonly
 */
const emptyAddress = {
    city: '',
    postcode: '',
    street: '',
    building: '',
    additional: '',
};

/**
 * Branch model
 *
 * @author Roman Zaycev <roman.zaycev@olmisoft.com>
 */
class Branch {
    #_id;
    #_crmId;
    #_clientId;
    #_title;
    #_group;
    #_location;
    #_brand;
    #_type;
    #_organization;
    #_address;
    #_phone;
    #_email;
    #_website;
    #_enabled;
    #_createdAt;
    #_updatedAt;
    #_titleSms;

    constructor(branch) {
        this.#_id = branch.id || null;
        this.#_crmId = branch.crmId || '';
        this.#_clientId = branch.clientId || null;
        this.#_title = branch.title || '';
        this.#_group = new BranchGroup(branch.group || {});
        this.#_location = new BranchLocation(branch.location || {});
        this.#_brand = new BranchBrand(branch.brand || {});
        this.#_type = new BranchType(branch.type || {});//branch.type?.code || '';
        this.#_organization = branch.organization || '';
        this.#_address = branch.address || emptyAddress;
        this.#_phone = branch.phone || ''
        this.#_email = branch.email || '';
        this.#_website = branch.website || '';
        this.#_enabled = branch.enabled || false;
        this.#_createdAt = branch.createdAt || null;
        this.#_updatedAt = branch.updatedAt || null;
        this.#_titleSms = branch.titleSms || '';
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this.#_id;
    }

    /**
     * @return {int|null}
     */
    get crmId() {
        return this.#_crmId;
    }

    /**
     * @return {int|null}
     */
    get clientId() {
        return this.#_clientId;
    }

    /**
     * @return {string}
     */
    get title() {
        return this.#_title;
    }

    /**
     * @param {string} title
     */
    set title(title) {
        this.#_title = title;
    }

    /**
     * @return {BranchGroup}
     */
    get group() {
        return this.#_group;
    }

    /**
     * @return {BranchLocation}
     */
    get location() {
        return this.#_location;
    }

    /**
     * @return {BranchBrand}
     */
    get brand() {
        return this.#_brand;
    }

    /**
     * @return {BranchType}
     */
    get type() {
        return this.#_type;
    }

    /**
     * @param {BranchType} type
     */
    set type(type) {
        this.#_type = type;
    }

    /**
     * @return {string|null}
     */
    get organization() {
        return this.#_organization;
    }

    /**
     * @param {string} organization
     */
    set organization(organization) {
        this.#_organization = organization;
    }

    /**
     * @return {BranchAddress|null}
     */
    get address() {
        return this.#_address;
    }

    /**
     * @param {BranchAddress} address
     */
    set address(address) {
        this.#_address = address;
    }

    get phone() {
        return this.#_phone;
    }

    /**
     * @param {string} phone
     */
    set phone(phone) {
        this.#_phone = phone;
    }

    get email() {
        return this.#_email;
    }

    /**
     * @param {string} email
     */
    set email(email) {
        this.#_email = email;
    }

    get website() {
        return this.#_website;
    }

    /**
     * @param {string} website
     */
    set website(website) {
        this.#_website = website;
    }

    get enabled() {
        return this.#_enabled;
    }

    /**
     * @param {boolean} isEnabled
     */
    set enabled(isEnabled) {
        this.#_enabled = isEnabled;
    }

    /**
     * @return {string}
     */
    get createdAt() {
        return this.#_createdAt ? moment(this.#_createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @return {string}
     */
    get updatedAt() {
        return this.#_updatedAt ? moment(this.#_updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @return {string}
     */
    get titleSms() {
        return this.#_titleSms;
    }

    getPlainObject() {
        return {
            id: this.id,
            crmId: this.crmId,
            clientId: this.clientId,
            title: this.title,
            group: this.group.getPlainObject(),
            location: this.location.getPlainObject(),
            brand: this.brand.getPlainObject(),
            type: this.type.getObjectFromApi(),
            organization: this.organization,
            address: this.address,
            phone: this.phone,
            email: this.email,
            website: this.website,
            enabled: this.enabled,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            titleSms: this.titleSms,
        };
    }

    static getEmptyEntity() {
        return new Branch({});
    }
}

export default Branch;
