import References from "services/References";
import uiStore from "stores/UiStore";
import { api } from "core";
import { Location } from "models";

class LocationsService extends References {
    constructor() {
        super();
    }

    /**
     * @param {{}} data
     * @return {Promise<Location>}
     */
    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/company/locations`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )

                return new Location(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     * @param {int} id
     * @param {{}} data
     * @return {Promise<Location>}
     */
    saveEntity = (id, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/company/locations/${id}`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                )

                return new Location(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     * @param id {number}
     * @returns {Promise<Location>}
     */
    loadEntity = (id) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/locations/${id}`)
            .then(result => {
                return new Location(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {{}} filter
     * @returns {Promise<{items: Location[], pager: PagerAnswer, sort: SortAnswer}>}
     */
    loadItems = (filter) => {
        const params = this.parseFilter(filter);

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/company/locations?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new Location(item);
                    }),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }
}

const locationsService = new LocationsService();
export default locationsService;
