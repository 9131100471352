import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { AutoComplete } from "primereact/autocomplete";
import cn from "classnames";
import "./RulesApply.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const RulesApply = inject('tagStore', 'uiStore')(observer(({ tagStore, uiStore, clientId }) => {
    const [rules, setRules] = useState([]);
    const [rulesForClient, setRulesForClient] = useState([])
    const [rule, setRule] = useState(null)
    const [filteredRules, setFilteredRules] = useState([])

    useEffect(() => {
        tagStore.loadAllRules()
            .then(result => {
                setRules(result);
                return tagStore.loadRulesForClient(clientId)
            })
            .then(result => {
                setRulesForClient(result);
            })
            .catch(e => e)
    }, [])

    useEffect(() => {
        setRules(setSelectedRule);
    }, [rulesForClient])

    const setSelectedRule = () => {
        return rules.map(rule => {
            return {
                id: rule.id,
                title: rule.title,
                selected: !!rulesForClient.find(item => item.id === rule.id),
            }
        })
    }

    const searchRule = (e) => {
        setTimeout(() => {
            let fRules;
            if (!e.query.trim().length) {
                fRules = [...rules];
            } else {
                fRules = rules.filter((rule) => {
                    return rule.title.toLowerCase().startsWith(e.query.toLowerCase());
                });
            }
            setFilteredRules(fRules)
        }, 250);
    }

    const addRule = (value) => {
        const clone = rulesForClient.find(rule => rule.id === value.id);

        if (!clone) {
            tagStore.applyRuleToClient(value.id, clientId)
                .then(result => {
                    if (result === "OK") {
                        setRulesForClient(prev => {
                            let copy = [...prev];
                            copy.push(value);
                            return copy
                        })
                    }
                })
                .catch(e => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: 'Внимание',
                            detail: e.error.description
                        }
                    );
                })
        }
        setRule(null)

    }

    const handlerRuleRemove = (index, ruleId) => {
        if (!rulesForClient.length) return;

        tagStore.cancelRuleToClient(ruleId, clientId)
            .then(result => {
                if (result === "OK") {
                    let copy = [...rulesForClient];
                    if (index > -1) {
                        copy.splice(index, 1);
                    }

                    setRulesForClient(copy)
                }
            })
            .catch(e => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: 'Внимание',
                        detail: e.error.description
                    }
                );
            })

    }

    const autoCompleteItemTemplate = (item) => {
        return (
            <div>
                {item.selected && <i className="pi pi-check" />}&nbsp;{item.title}
            </div>
        )
    }

    return (
        <>
            <div className={cn('rules__wrapper')}>
                <div className="p-field">
                    <span className="p-float-label">
                        <AutoComplete
                            value={rule}
                            suggestions={filteredRules}
                            completeMethod={searchRule}
                            itemTemplate={autoCompleteItemTemplate}
                            onSelect={(e) => {
                                addRule(e.value)
                            }}
                            field="title"
                            dropdown
                            onChange={(e) => {
                                setRule(e.value)
                            }}/>
                        <label htmlFor="title">Добавить правила автотегов</label>
                    </span>
                </div>
            </div>
            <div className={cn('rules__attached')}>
                <ul className={cn('rules__items')}>
                    {rulesForClient.length > 0 && rulesForClient.map((item, key) => (
                            <RulesApplyItem
                                key={`${key}_${item.id}`}
                                item={item}
                                index={key}
                                onRemove={handlerRuleRemove}
                            />
                        )
                    )}
                </ul>
            </div>
        </>
    )
}))

const RulesApplyItem = ({ item, index, onRemove }) => {

    return (
        <>
            <li className={cn('rules__item')}>
                <Link to={`/references/tags/${item.id}/detail`}>{item.title}</Link>
                <i
                    className="pi pi-times"
                    onClick={() => {
                        onRemove(index, item.id)
                    }}
                ></i>
            </li>
        </>
    )
}
RulesApplyItem.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    onRemove: PropTypes.func,
}

export default RulesApply;