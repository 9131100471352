import React from 'react';
import PropTypes from "prop-types";
import cn from "classnames";
import "../../styles/overflow-link.scss";

/**
 * @param {string} link
 * @return {JSX.Element}
 * @constructor
 */
const OverflowLink = ({ link }) => {
    return (
        <div className={cn('overflow-link')}>
            <a href={link} target='_blank' rel="noopener noreferrer">{link}</a>
        </div>
    );
}

OverflowLink.propTypes = {
    link: PropTypes.string,
};

export default OverflowLink;
