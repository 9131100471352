import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import OkInputText from "../../common/OkInputText";
import { InputSwitch } from "primereact/inputswitch";
import TagLine from "./common/TagLine";
import TitleH2 from "../../common/TitleH2";
import { Link } from "react-router-dom";

const TagsEditPage = ({ uiStore, breadcrumbsStore, tagStore }) => {

    let history = useHistory();

    const { id } = useParams();

    const [isValid, setIsValid] = useState(false);
    const [fields, setFields] = useState({});

    useEffect(() => {
        tagStore.loadEntity(id)
            .then(() => {
                setFields(tagStore.prepareForForm())
            });
    }, []);

    useEffect(() => {

        uiStore.setPageProperty({
            page: 'references',
            className: 'locations'
        });

        breadcrumbsStore.addItem({
            linkName: 'Правила автотегов',
            link: '/references/tags',
        }, 0);

        breadcrumbsStore.addItem({
            linkName: fields.title,
            link: `/references/tags/${fields.id}/detail`,
        }, 1);

        breadcrumbsStore.addItem({
            linkName: 'Редактирование',
            link: `/references/tags/${fields.id}/edit`,
        }, 2);

        setIsValid(!!fields.title)
    }, [fields]);

    const save = () => {

        //проверка уникальности названий тегов
        const { valid, errors, processedFields } = tagStore.checkUniqueTagName(fields);

        if(!valid) {
            setFields(processedFields);

            errors.map(error => {
                setTimeout(() => {
                    uiStore.setMessage(
                        {
                            life: 2000,
                            severity: 'error',
                            summary: 'Ошибка валидации',
                            detail: error
                        }
                    )
                }, 0)
            })
        }
        else {
            tagStore.prepareForApi(fields)

            tagStore.saveEntity()
                .then((result) => {
                    if(result === 'OK') {
                        history.push(`/references/tags/${tagStore.entity.id}/detail`);
                    }

                })
                .catch(error => {
                    // TODO обернуть в функцию
                    switch (error.statusCode) {
                        case 422:
                            uiStore.setMessage(
                                {
                                    severity: 'error',
                                    summary: error.error.description,
                                    detail: error.error.data.map(error=>{
                                        return error.message
                                    }),
                                }
                            );
                            break;
                        case 500:
                            uiStore.setMessage(
                                {
                                    severity: 'error',
                                    summary: 'Внимание',
                                    detail: error.error.description
                                }
                            );
                            break
                        default:
                            uiStore.setMessage(
                                {
                                    severity: 'error',
                                    summary: 'Внимание',
                                    detail: 'Неизвестная ошибка'
                                }
                            );

                    }
                })

        }
    };

    const addTag = () => {
        let tags = [...fields.tags]
        tags.push({ name: '', valid: false, keywords: [{ name: '', valid: false }] })

        setFields(() => {
            return {
                ...fields,
                ...{ tags },
            }
        })
    }

    const handlerAddKeywords = (index, value) => {
        let tags = [ ...fields.tags ]
        tags[index] = {
            ...tags[index],
            ...{ keywords: value }
        }

        setFields(() => {
            return {
                ...fields,
                ...{ tags }
            }
        })
    }

    const handlerChange = (index, value) => {

        let tags = [ ...fields.tags ]

        tags[index] = {
            name: value,
            valid: !!value,
            keywords: tags[index].keywords
        };

        setFields(() => {
            return {
                ...fields,
                ...{ tags }
            }
        })
    }

    const handlerRemove = (index) => {
        let copy = { ...fields }
        copy.tags.splice(index, 1);
        setFields(copy)
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1>
                    {fields.title} / Редактирование
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                {!!fields.id && <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={save}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/references/tags/${fields.id}/detail`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='title'
                                        name='title'
                                        type='text'
                                        value={fields.title || ''}
                                        isrequired="true"
                                        onChange={(e) => {
                                            // noinspection JSUnresolvedVariable
                                            setFields(() => (
                                                {
                                                    ...fields,
                                                    ...{ title: e.target.value }
                                                }
                                            ))
                                            ///setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="title">Название правила *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={fields.enabled}
                                    onChange={(e) => {
                                        setFields(() => (
                                            {
                                                ...fields,
                                                ...{ enabled: e.value }
                                            }
                                        ))
                                        //setEntity({ enabled: e.value })
                                    }}
                                />
                                <span className={cn('switch__text')}>Активен</span>
                            </div>
                        </div>
                    </div>
                    {fields.tags?.length > 0 && fields.tags.map((tag, key) => (
                        <TagLine
                            key={key}
                            item={tag}
                            index={key}
                            onChange={handlerChange}
                            removeTag={handlerRemove}
                            addKeywords={handlerAddKeywords}
                        />
                    ))}

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <Button
                                label="Добавить тег"
                                icon="pi pi-plus"
                                onClick={addTag}
                                disabled={!fields.title?.length}
                            />
                        </div>
                    </div>
                </div>}
            </div>
            {fields.clients?.length > 0 && <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'users')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={fields.clients?.length}
                                color='color-blue'
                                ico='house_gradient'
                            >
                                Связь правила с клиентами
                            </TitleH2>
                            <table>
                                <thead>
                                <tr>
                                    <th>Клиенты</th>
                                    <th>Связь с правилом</th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.clients?.map((client, key) => (<tr key={`${client.id}_${key}`}>
                                    <td>
                                        <Link to={`/clients/${client.id}/detail`}>{client.title}</Link>
                                    </td>
                                    <td>
                                        <span className={cn('badge', 'badge_linked-true')}>
                                            {('Подключено')}
                                        </span>
                                    </td>
                                </tr>))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>}
        </div>
    )
}

TagsEditPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    tagStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'tagStore', 'mainStore')(observer(TagsEditPage));
