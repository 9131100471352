import { api } from "core";
import { Client } from "models";
import References from "services/References";
import uiStore from "stores/UiStore";
import ClientFeature from "../models/ClientFeature";

class ClientService extends References {
    constructor() {
        super();
    }

    /**
     * @param {{}} data
     * @return {Promise<Client>}
     */
    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/account/clients`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )

                return new Client(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     * @param {int} id
     * @param {{}} data
     * @return {Promise}
     */
    saveEntity = (id, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/account/clients/${id}`, data)
            .then(() => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                )
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )

                if (error.error.data.length) {
                    error.error.data.map(m => {
                        uiStore.setMessage(
                            {
                                severity: 'error',
                                summary: error.error.description,
                                detail: m.message,
                            }
                        )

                    })
                }

            });
    }

    /**
     * @param id {number}
     * @returns {Promise<Client>}
     */
    loadEntity = (id) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/clients/${id}`)
            .then(result => {
                return new Client(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {{}} filter
     * @return {Promise<{items: Client[], pager: PagerAnswer, sort: SortAnswer}>}
     */
    loadItems = (filter) => {
        const params = this.parseFilter(filter);

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/clients?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => {
                        return new Client(item);
                    }),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {number} clientId
     * @return {Promise<ClientFeature[]>}
     */
    loadClientFeatures = (clientId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/clients/${clientId}/features`)
            .then(result => result.items.map((item) => new ClientFeature(item)))
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {number} clientId
     * @param {string} feature
     * @return {Promise<boolean>}
     */
    enableBooleanFeature = (clientId, feature) => {
        return api
            .post(
                `${process.env.REACT_APP_API_URL}/admin/account/clients/${clientId}/features/activate`,
                {
                    feature,
                }
            )
            .then((result) => {
                return result === 'OK';
            });

    }

    /**
     * @param {number} clientId
     * @param {string} feature
     * @return {Promise<boolean>}
     */
    disableBooleanFeature = (clientId, feature) => {
        return api
            .post(
                `${process.env.REACT_APP_API_URL}/admin/account/clients/${clientId}/features/deactivate`,
                {
                    feature,
                }
            )
            .then((result) => {
                return result === 'OK';
            });
    }
    onDownloadPdf = (clientId, filter) => {
        return api.post(
            `${process.env.REACT_APP_API_URL}/admin/account/clients/${clientId}/reports/rating?format=pdf`,
            filter
        ).then(result => result)
    }

    onDownloadPdfSentimentReport = (clientId, filter) => {
        return api.post(
            `${process.env.REACT_APP_API_URL}/admin/account/clients/${clientId}/reports/sentiment?format=pdf`,
            filter
        ).then(result => result)
    }

    onDownloadXls = (clientId, filter) => {
        return api.post(
            `${process.env.REACT_APP_API_URL}/admin/account/clients/${clientId}/reports/application-export`,
            filter
        ).then(result => result)
    }

}

const clientService = new ClientService();
export default clientService;
