import { action, makeObservable, observable } from "mobx";

export default class CommonPageStore {
    title = '';

    lastname = '';

    fEnabled = -1;

    pager = {
        size: 10,
        number: 1,
    }

    sort = {
        column: 'created',
        order: 'desc'
    };

    constructor() {
        makeObservable(
            this,
            {
                title: observable,
                lastname: observable,
                pager: observable,
                sort: observable,
                fEnabled: observable,
                setFEnabled: action,
                setLastname: action,
                setTitle: action,
                setPager: action,
                setSort: action,
                resetFilter: action,
            }
        );
    }

    setLastname = (lastname) => {
        this.lastname = lastname || '';
    }

    setFEnabled = (fEnabled) => {
        this.fEnabled = fEnabled;
    }

    /**
     * @param {string} value
     */
    setTitle = (value) => {
        this.title = value;
    }

    /**
     * @param {PagerAnswer} pager
     */
    setPager = (pager = {}) => {
        this.pager = {
            ...this.pager,
            ...pager,
        };
    }

    /**
     * @param {SortAnswer} sort
     */
    setSort = (sort) => {
        this.sort = sort || {};
    }

    resetFilter = () => {
        this.title = '';

        this.lastname = '';

        this.pager = {
            size: 10,
            number: 1,
        }

        this.sort = {
            column: 'created',
            order: 'desc'
        };
    }
}
