import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import _ from "underscore";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

import TitleH1 from "components/common/TitleH1";
import TitleH2 from "components/common/TitleH2";
import CustomizableLinkedSwitch from "../../../common/CustomizableLinkedSwitch";
import { AD_PLATFORM_TYPE } from "models/AdPlatform";
import OkInputText from "components/common/OkInputText";
import OkDropdown from "components/common/OkDropdown";

/**
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {BranchStore} branchStore
 * @param {MainStore} mainStore
 * @param {BranchTypeStore} branchTypeStore
 * @returns {JSX.Element}
 * @constructor
 */
const BranchesEditPage = ({ uiStore, breadcrumbsStore, branchStore, mainStore, branchTypeStore }) => {
    let history = useHistory();
    const { client } = mainStore;

    const {
        entity,
        setEntity,
        lazyLoadCompanyGroups,
        lazyLoadLocations,
        lazyLoadBrands,
        users,
        usersPager,
        integrations,
        integrationsPager,
        setUserLinked,
        delUserLinked,
        setIntegrationLinked,
        delIntegrationLinked,
    } = branchStore;

    const { title, enabled } = entity;
    const { id } = useParams();

    const [isValid, setIsValid] = useState(false);
    const [branchTypes, setBranchTypes] = useState([]);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'branches'
        });

        setIsValid((
            !!entity.clientId &&
            !!entity.title &&
            !!entity.group.id &&
            !!entity.location.id &&
            !!entity.brand.id &&
            !!entity.type.id &&
            !!entity.address.city.length &&
            !!entity.address.street.length &&
            !!entity.titleSms.length &&
            !!entity.address.building.length
        ));

    }, [entity]);

    useEffect(() => {
        if (client.id && entity.clientId) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${client.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Филиалы',
                link: `/clients/${client.id}/branches`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: entity.title,
                link: `/clients/${client.id}/branches/${id}/detail`,
            }, 3);

            breadcrumbsStore.addItem({
                linkName: 'Редактирование',
                link: `/clients/${client.id}/branches/${id}/edit`,
            }, 4);
        }
    }, [client, entity])

    useEffect(() => {
        branchStore.loadEntity(id, true);
        branchTypeStore.loadItems();
    }, []);



    useEffect(() => {
        setBranchTypes(() => {
            return branchTypeStore.items.map((item) => {
                return {
                    code: item.id,
                    title: item.title,
                }
            })
        });
    }, [branchTypeStore.items]);

    const save = () => {
        branchStore
            .saveEntity()
            .then(() => {
                history.push(`/clients/${branchStore.entity.clientId}/branches/${branchStore.entity.id}/detail`);
            })
            .catch(() => {});
    };
    const companyGroupDebounceHandler = _.debounce(
        (e) => {
            branchStore.loadLazyCompanyGroups(e.filter);
        },
        800
    );
    const locationDebounceHandler = _.debounce(
        (e) => {
            branchStore.loadLazyLocations(e.filter);
        },
        800
    );
    const brandDebounceHandler = _.debounce(
        (e) => {
            branchStore.loadLazyBrands(e.filter);
        },
        800
    );

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'house_gradient'}
                >
                    {title} / Редактирование
                </TitleH1>
            </div>

            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={() => {
                                    save();
                                }}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${entity.clientId}/branches/${entity.id}/detail`);
                                }}
                            />
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='name'
                                        name='name'
                                        type='text'
                                        isrequired="true"
                                        value={entity.title}
                                        onChange={(e) => {
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="name">Название филиала *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.value })
                                    }}
                                />
                                <span className={cn('switch__text')}>Активен</span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        value={entity.group.id}
                                        options={lazyLoadCompanyGroups}
                                        onChange={(e) => {
                                            setEntity({
                                                group: {
                                                    ...entity.group,
                                                    ...{
                                                        id: e.value,
                                                    },
                                                },
                                            });
                                        }}
                                        id="company-group"
                                        name="company-group"
                                        optionLabel="title"
                                        optionValue="id"
                                        isrequired="true"
                                        filter
                                        resetFilterOnHide={false}
                                        onFilter={companyGroupDebounceHandler}
                                    />
                                    <label htmlFor="company-group">Группа компаний *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='legalEntity'
                                        name='legalEntity'
                                        type='text'
                                        value={entity.organization}
                                        onChange={(e) => {
                                            setEntity({ organization: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="legalEntity">Юридическое лицо</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        value={entity.location.id}
                                        options={lazyLoadLocations}
                                        onChange={(e) => {
                                            setEntity({
                                                location: {
                                                    ...entity.location,
                                                    ...{
                                                        id: e.value,
                                                    },
                                                },
                                            });
                                        }}
                                        id="location"
                                        name="location"
                                        optionLabel="title"
                                        optionValue="id"
                                        isrequired="true"
                                        filter
                                        resetFilterOnHide={false}
                                        onFilter={locationDebounceHandler}
                                    />
                                    <label htmlFor="location">Локация *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        value={entity.brand.id}
                                        options={lazyLoadBrands}
                                        onChange={(e) => {
                                            setEntity({
                                                brand: {
                                                    ...entity.brand,
                                                    ...{
                                                        id: e.value,
                                                    },
                                                },
                                            });
                                        }}
                                        id="brand"
                                        name="brand"
                                        optionLabel="title"
                                        optionValue="id"
                                        isrequired="true"
                                        filter
                                        resetFilterOnHide={false}
                                        onFilter={brandDebounceHandler}
                                    />
                                    <label htmlFor="brand">Бренд филиала *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        id='type'
                                        name='type'
                                        value={entity.type.id}
                                        options={branchTypes}
                                        optionLabel="title"
                                        optionValue="code"
                                        onChange={(e) => {
                                            setEntity({
                                                type: {
                                                    ...entity.type,
                                                    ...{
                                                        id: e.value,
                                                    },
                                                },
                                            });
                                        }}
                                    />
                                    <label htmlFor="type">Тип филиала *</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='zip'
                                        name='zip'
                                        type='text'
                                        value={entity.address.postcode}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        postcode: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="zip">Индекс</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='city'
                                        name='city'
                                        type='text'
                                        isrequired="true"
                                        value={entity.address.city}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        city: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="location">Город *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='address'
                                        name='address'
                                        type='text'
                                        isrequired="true"
                                        value={entity.address.street}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        street: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="address">Улица *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='building'
                                        name='building'
                                        type='text'
                                        isrequired="true"
                                        value={entity.address.building}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        building: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="building">Дом *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='additional_address'
                                        name='additional_address'
                                        type='text'
                                        value={entity.address.additional}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        additional: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="additional_address">Доп. адрес</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='phone'
                                        name='phone'
                                        type='text'
                                        value={entity.phone}
                                        onChange={(e) => {
                                            setEntity({ phone: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="phone">Телефон</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='email'
                                        name='email'
                                        type='text'
                                        value={entity.email}
                                        onChange={(e) => {
                                            setEntity({ email: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="email">Контактный email</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='website'
                                        name='website'
                                        type='text'
                                        value={entity.website}
                                        onChange={(e) => {
                                            setEntity({ website: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="website">Сайт</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='titleSms'
                                        name='titleSms'
                                        type='text'
                                        isrequired="true"
                                        value={entity.titleSms}
                                        onChange={(e) => {
                                            setEntity({ titleSms: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="titleSms">Название филиала в SMS *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='crmId'
                                        name='crmId'
                                        type='text'
                                        value={entity.crmId}
                                        onChange={(e) => {
                                            setEntity({ crmId: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="crmId">Идентификатор в СRM</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='id'
                                        name='id'
                                        type='text'
                                        value={entity.id}
                                        disabled
                                    />
                                    <label htmlFor="id">Идентификатор OKR</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'integrations')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={integrationsPager.itemCount}
                                color='color-orange'
                                ico='chain_gradient'
                            >
                                Интеграции / {client.title}
                            </TitleH2>
                        </div>

                        <div className={cn('buttons-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Добавить интеграцию"
                                icon="pi pi-plus"
                                onClick={() => {
                                    history.push(`/clients/${client.id}/integrations/new`);
                                }}
                            />
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>Интеграции</th>
                                    <th>Площадка</th>
                                    <th>Связь с филиалом</th>
                                </tr>
                            </thead>
                            <tbody>
                            {integrations.map((item, key) => (<tr key={`${entity.clientId}_${key}`}>
                                <td>
                                    <Link to={`/clients/${entity.clientId}/integrations/${item.adplatform.id}/detail`}>
                                        {item.adplatform.title}
                                    </Link>
                                </td>
                                <td>{AD_PLATFORM_TYPE.getTitle(item.adplatform.type)}</td>
                                <td>
                                    <div className={cn('switch', 'connection')}>
                                        <CustomizableLinkedSwitch
                                            initialLinked={item.linked}
                                            item={item}
                                            switchTextOn="Связаны"
                                            switchTextOff="Нет связи"
                                            changeHandler={(e) => {
                                                if (e.linked) {
                                                    setIntegrationLinked(e.item.adplatform.id)
                                                } else {
                                                    delIntegrationLinked(e.item.adplatform.id)
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>

                    <div className={cn('table-container', 'users')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={usersPager.itemCount}
                                color='color-blue-light'
                                ico='avatar__blue_gradient'
                            >
                                Доступы пользователей / {client.title}
                            </TitleH2>
                        </div>

                        <div className={cn('buttons-container')}>
                            <Button
                                className={cn('p-button-outlined', 'color-blue-light')}
                                label="Добавить пользователя"
                                icon="pi pi-plus"
                                onClick={() => {
                                    history.push(`/clients/${entity.clientId}/users/new`);
                                }}
                            />
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>Пользователи</th>
                                    <th>Доступ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, key) => (<tr key={`${user.employee.id}_${key}`}>
                                    <td>
                                        <Link to={`/clients/${entity.clientId}/users/${user.id}/detail`}>
                                            {`${user.employee.firstname} ${user.employee.lastname}`}
                                        </Link>
                                    </td>
                                    <td>
                                        <div className={cn('switch', 'connection')}>
                                            <CustomizableLinkedSwitch
                                                initialLinked={user.linked}
                                                item={user}
                                                switchTextOn="Открыт"
                                                switchTextOff="Закрыт"
                                                changeHandler={(e) => {
                                                    if (e.linked) {
                                                        setUserLinked(e.item.employee.id)
                                                    } else {
                                                        delUserLinked(e.item.employee.id)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

BranchesEditPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    branchStore: PropTypes.any,
    mainStore: PropTypes.any,
    branchTypeStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'branchStore', 'mainStore', 'branchTypeStore')(observer(BranchesEditPage));

