import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "underscore";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

import TitleH1 from "components/common/TitleH1";
import OkDropdown from "components/common/OkDropdown";
import OkInputText from "components/common/OkInputText";

/**
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {BranchStore} branchStore
 * @param {BranchTypeStore} branchTypeStore
 * @param {mainStore} mainStore
 * @returns {JSX.Element}
 * @constructor
 */
const BranchesNewPage = ({ uiStore, breadcrumbsStore, branchStore, branchTypeStore, mainStore }) => {
    const history = useHistory();
    const { clientId } = useParams();
    const { client } = mainStore;
    const {
        entity,
        setEntity,
        addEntity,
        resetEntity,
        lazyLoadLocations,
        lazyLoadCompanyGroups,
        lazyLoadBrands,
    } = branchStore;

    const [isValid, setIsValid] = useState(false);
    const [branchTypes, setBranchTypes] = useState([]);

    useEffect(() => {
        resetEntity();
        setEntity({ clientId: clientId });
        branchStore.loadLazyCompanyGroups('', 20);
        branchStore.loadLazyBrands('', 20);
        branchStore.loadLazyLocations('', 20);
        branchTypeStore.loadItems();
    }, []);


    useEffect(() => {
        setBranchTypes(() => {
            return branchTypeStore.items.map((item) => {
                return {
                    code: item.id,
                    title: item.title,
                }
            })
        });
    }, [branchTypeStore.items]);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'branches'
        });

        setIsValid((
            !!entity.clientId &&
            !!entity.title &&
            !!entity.group.id &&
            !!entity.location.id &&
            !!entity.brand.id &&
            !!entity.type.id &&
            !!entity.address.city.length &&
            !!entity.address.street.length &&
            !!entity.titleSms.length &&
            !!entity.address.building.length
        ));
    }, [entity]);


    useEffect(() => {
        if (client.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${client.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Филиалы',
                link: `/clients/${client.id}/branches`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: 'Создание филиала',
                link: `/clients/${client.id}/branches/new/`,
            }, 3);
        }
    }, [client])

    const save = () => {
        addEntity()
            .then(() => {
                history.push(`/clients/${clientId}/branches/${branchStore.entity.id}/detail`);
            })
            .catch(() => {
            });
    };
    const companyGroupDebounceHandler = _.debounce(
        (e) => {
            branchStore.loadLazyCompanyGroups(e.filter);
        },
        800
    );
    const locationDebounceHandler = _.debounce(
        (e) => {
            branchStore.loadLazyLocations(e.filter);
        },
        800
    );
    const brandDebounceHandler = _.debounce(
        (e) => {
            branchStore.loadLazyBrands(e.filter);
        },
        800
    );

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'house_gradient'}
                >
                    &nbsp;
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={() => {
                                    save();
                                }}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${clientId}/branches`);
                                }}
                            />
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='name'
                                        name='name'
                                        type='text'
                                        isrequired="true"
                                        value={entity.title}
                                        onChange={(e) => {
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="name">Название филиала *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={entity.enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.value })
                                    }}
                                />
                                <span className={cn('switch__text')}>Активен</span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        isrequired="true"
                                        value={entity.group.id}
                                        options={lazyLoadCompanyGroups}
                                        onChange={(e) => {
                                            setEntity({
                                                group: {
                                                    ...entity.group,
                                                    ...{
                                                        id: e.value
                                                    }
                                                },
                                            });
                                        }}
                                        id="company-group"
                                        name="company-group"
                                        optionLabel="title"
                                        optionValue="id"
                                        filter
                                        resetFilterOnHide={false}
                                        onFilter={companyGroupDebounceHandler}
                                    />
                                    <label htmlFor="company-group">Группа компаний *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='legalEntity'
                                        name='legalEntity'
                                        type='text'
                                        value={entity.organization}
                                        onChange={(e) => {
                                            setEntity({ organization: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="legalEntity">Юридическое лицо</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        isrequired="true"
                                        value={entity.location.id}
                                        options={lazyLoadLocations}
                                        onChange={(e) => {
                                            setEntity({
                                                location: {
                                                    ...entity.location,
                                                    ...{
                                                        id: e.value,
                                                    },
                                                },
                                            });
                                        }}
                                        id="location"
                                        name="location"
                                        optionLabel="title"
                                        optionValue="id"
                                        filter
                                        resetFilterOnHide={false}
                                        onFilter={locationDebounceHandler}
                                    />
                                    <label htmlFor="location">Локация *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        isrequired="true"
                                        value={entity.brand.id}
                                        options={lazyLoadBrands}
                                        onChange={(e) => {
                                            setEntity({
                                                brand: {
                                                    ...entity.brand,
                                                    ...{
                                                        id: e.value,
                                                    },
                                                },
                                            });
                                        }}
                                        id="brand"
                                        name="brand"
                                        optionLabel="title"
                                        optionValue="id"
                                        filter
                                        resetFilterOnHide={false}
                                        onFilter={brandDebounceHandler}
                                    />
                                    <label htmlFor="brand">Бренд филиала *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        id='type'
                                        name='type'
                                        isrequired="true"
                                        value={entity.type.id}
                                        options={branchTypes}
                                        optionLabel="title"
                                        optionValue="code"
                                        onChange={(e) => {
                                            setEntity({
                                                type: {
                                                    ...entity.type,
                                                    ...{
                                                        id: e.value,
                                                    },
                                                },
                                            });
                                        }}
                                    />
                                    <label htmlFor="type">Тип филиала *</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='zip'
                                        name='zip'
                                        type='text'
                                        value={entity.address.postcode}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        postcode: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="zip">Индекс</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='city'
                                        name='city'
                                        type='text'
                                        isrequired="true"
                                        value={entity.address.city}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        city: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="location">Город *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='address'
                                        name='address'
                                        type='text'
                                        isrequired="true"
                                        value={entity.address.street}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        street: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="address">Улица *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='building'
                                        name='building'
                                        type='text'
                                        isrequired="true"
                                        value={entity.address.building}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        building: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="building">Дом *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='additional_address'
                                        name='additional_address'
                                        type='text'
                                        value={entity.address.additional}
                                        onChange={(e) => {
                                            setEntity({
                                                address: {
                                                    ...entity.address,
                                                    ...{
                                                        additional: e.target.value,
                                                    },
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="additional_address">Доп. адрес</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='phone'
                                        name='phone'
                                        type='text'
                                        value={entity.phone}
                                        onChange={(e) => {
                                            setEntity({ phone: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="phone">Телефон</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='email'
                                        name='email'
                                        type='text'
                                        value={entity.email}
                                        onChange={(e) => {
                                            setEntity({ email: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="email">Контактный email</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='website'
                                        name='website'
                                        type='text'
                                        value={entity.website}
                                        onChange={(e) => {
                                            setEntity({ website: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="website">Сайт</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='titleSms'
                                        name='titleSms'
                                        type='text'
                                        isrequired="true"
                                        value={entity.titleSms}
                                        onChange={(e) => {
                                            setEntity({ titleSms: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="titleSms">Название филиала в SMS *</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

BranchesNewPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    branchStore: PropTypes.any,
    branchTypeStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'branchStore', 'branchTypeStore', 'mainStore')(observer(BranchesNewPage));


