import { action, makeObservable, observable } from "mobx";
import { clientService, authService } from "services"
import { Client } from "models";

class MainStore {
    /**
     * @type {ClientService}
     */
    #clientService;

    /**
     * @type {AuthService}
     */
    #authService

    client = Client.getEmptyEntity().getObjectFromApi();

    /**
     * @param {ClientService} clientService
     * @param  {AuthService} authService
     */
    constructor(clientService, authService) {
        this.#clientService = clientService;
        this.#authService = authService;

        makeObservable(this, {
            client: observable,
            setClient: action,
        });
        this.init();
    }

    setClient(client) {
        this.client = {
            ...this.client,
            ...client,
        }
    }

    /**
     * @private
     */
    init() {
        if (!this.client.id && this.#authService.isAuthorized === true) {
            const clientIdMatch = window.location.pathname.match(/\/clients\/(\d+)\/?/);
            if (clientIdMatch && clientIdMatch[1]) {
                const clientId = parseInt(clientIdMatch[1], 10);

                if (clientId > 0) {
                    this
                        .#clientService
                        .loadEntity(clientId)
                        .then((client) => {
                            if (client) {
                                this.setClient(client.getObjectFromApi())
                            }
                        });
                }
            }
        }
    }
}

const mainStore = new MainStore(clientService, authService);
export default mainStore;
