import React, { useEffect } from "react";
import cn from "classnames";

import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { InputSwitch } from "primereact/inputswitch";
import TitleH4 from "components/common/TitleH4";

const BlockPublication = ({
                              adPlatformPublicationStore,
                              integrationId = null,
                              disabled = false,
                              onSave = false,
                              active = true,
                          }) => {

    const { entity, setEntity } = adPlatformPublicationStore;

    useEffect(() => {
        adPlatformPublicationStore.loadEntity(integrationId);
    }, []);

    useEffect(() => {
        !active && setEntity({
            connection: {
                ...entity.connection,
                ...{
                    enabled: active
                },
            },
        })
    },[active])

    useEffect(()=>{
        (entity.connection.info.id && onSave) && save();
    },[onSave])

    const save = () => {
        if (entity.connection.info.type) {
            adPlatformPublicationStore.saveEntity(integrationId);
        } else {
            adPlatformPublicationStore.addEntity(integrationId);
        }
    }

    return (
        <>
            <div className={cn('edit-form__col', 'edit-form__col_large')}>
                <TitleH4
                    ico={'chain_gradient'}
                    showBadge={disabled}
                    active={entity?.connection.enabled}
                >
                    Функционал автоматической публикации отзывов на Авито
                </TitleH4>

                <div className={cn("p-field", 'pt-0')}>
                    <span className="p-float-label">
                        <InputText
                            id='publicationId'
                            name='publicationId'
                            type='text'
                            value={entity?.connection.info.id ?? ''}
                            disabled={disabled}
                            onChange={(e) => {
                                setEntity({
                                    connection: {
                                        ...entity.connection,
                                        ...{
                                            info: {
                                                ...entity.connection.info,
                                                ...{ id: e.target.value }
                                            }
                                        },
                                    },
                                });
                            }}
                        />
                        <label htmlFor="url">Хеш магазина Авито для публикации отзыва</label>
                    </span>
                </div>
            </div>
            {!disabled && <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                <div className={cn('switch__wrapper')}>
                    <div className={cn('switch', 'switch-activity')}>
                        <InputSwitch
                            checked={entity?.connection.enabled}
                            disabled={!active}
                            onChange={(e) => {
                                setEntity({
                                    connection: {
                                        ...entity.connection,
                                        ...{
                                            enabled: e.value
                                        },
                                    },
                                })
                            }}
                        />
                        <span className={cn('switch__text', { 'disabled': !active })}>{entity.connection.enabled ? 'Активен' : 'Не активeн'}</span>
                    </div>
                </div>
            </div>}
        </>
    );
}

BlockPublication.propTypes = {
    adPlatformPublicationStore: PropTypes.any,
    integrationId: PropTypes.any,
    disabled: PropTypes.bool,
    onSave: PropTypes.any,
    active: PropTypes.bool,
}
export default inject(
    'adPlatformPublicationStore',
)(observer(BlockPublication));

