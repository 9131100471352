import React, { useEffect, useState } from "react";
import cn from "classnames";
import TitleH1 from "components/common/TitleH1";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import { Paginator } from "primereact/paginator";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import Activity from "components/common/Activity";

import 'styles/pages/users.scss';
import { reaction } from "mobx";
import _ from "underscore";
import Sortable from "components/common/Sortable";
import { Dropdown } from "primereact/dropdown";

/**
 *
 * @param {MainStore} mainStore
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {ClientStore} clientStore
 * @param {UserStore} userStore
 * @returns {JSX.Element}
 * @constructor
 */
const UsersPage = ({ mainStore, uiStore, breadcrumbsStore, clientStore, userStore }) => {

    let history = useHistory();
    const { clientId } = useParams();
    const { client } = mainStore;
    const { lastname, setLastname, items, pager, setPager, sort, setSort, resetFilter, fEnabled, setFEnabled } = userStore;
    const { entity } = clientStore;

    const [first, setFirst] = useState(0);

    useEffect(() => {
        resetFilter();

        clientStore.loadEntity(clientId)
            .then(() => {
                mainStore.setClient(clientStore.entity)
                userStore.loadItems(mainStore.client.id || clientId);

                uiStore.setPageProperty({
                    page: 'clients',
                    className: 'users'
                });
            });
    }, []);


    useEffect(() => {
        if (client.id && entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: entity.title,
                link: `/clients/${entity.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: `Пользователи`,
                link: `/clients/${entity.id}/users`,
            }, 2);
        }
    }, [client, entity])

    useEffect(() => {
        const disposers = [
            reaction(
                () => [
                    userStore.fEnabled,
                ],
                () => {
                    userStore.loadItems(mainStore.client.id || clientId);
                }
            ),
            reaction(
                () => userStore.lastname,
                (value) => {
                    titleDebounceHandler(value)
                }
            ),
            reaction(
                () => userStore.pager.number,
                () => {
                    userStore.loadItems(mainStore.client.id)
                }
            )
        ];

        return () => {
            disposers.map(func => func());
        }
    }, [])

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && userStore.loadItems(mainStore.client.id),
        800
    );

    useEffect(() => {
        const disposer = reaction(
            () => JSON.stringify(userStore.sort),
            () => {
                userStore.loadItems(mainStore.client.id)
            }
        );

        return () => disposer();
    }, []);

    const sortHandler = (obj) => {
        setSort(obj);
    }


    /** @namespace item.brand */
    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    ico={'avatar__blue_gradient'}
                    color={'color-blue-light'}
                >
                    Пользователи
                </TitleH1>
            </div>
            <div className={cn('add-container')}>
                <Button
                    className={cn('p-button-outlined')}
                    label="Создать пользователя"
                    icon="pi pi-plus"
                    onClick={() => {
                        history.push(`/clients/${client.id}/users/new`);
                    }}
                />
            </div>

            <div className={cn('search-container')}>
                <div className={cn('dropdown-container')} style={{ marginRight: '24px' }}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fEnabled}
                                options={[
                                    { name: 'Все', code: -1 },
                                    { name: 'Активна', code: 1 },
                                    { name: 'Не активна', code: 0 },
                                ]}
                                onChange={(e) => {
                                    setFEnabled(e.value)
                                }}
                                id="fEnabled"
                                name="fEnabled"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fEnabled">Активность</label>
                            </span>
                    </div>
                </div>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Поиск по фамилии..."
                            name='lastname'
                            id='lastname'
                            value={lastname}
                            onChange={(e) => {
                                // noinspection JSUnresolvedVariable
                                setLastname(e.target.value);
                            }}
                        />
                    </span>
                    </div>
                </div>
            </div>

            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='lastname'
                            >ФИО</Sortable>
                        </th>
                        <th>E-mail</th>
                        <th className={cn('date-head')}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='created'
                            >Создан</Sortable>
                        </th>
                        <th className={cn('date-head')}>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='updated'
                            >Изменен</Sortable>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, key) => (
                        <tr key={key}>
                            <td className={cn('id-body')}>{item.id}</td>
                            <td className={cn('activity-data')}>
                                <Activity activity={item.enabled} />
                            </td>
                            <td>
                                <Link to={`/clients/${client.id}/users/${item.id}/detail`}>{item.fullName}</Link>
                            </td>
                            <td>{item.email}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.updatedAt}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first} /* номер элемента для отсчета страниц*/
                    rows={pager.size} /*кол-во элементов на странице*/
                    totalRecords={pager.itemCount} /*всего элементов*/
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    );
};

UsersPage.propTypes = {
    mainStore: PropTypes.any,
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    clientStore: PropTypes.any,
    userStore: PropTypes.any,
}

export default inject('mainStore', 'uiStore', 'breadcrumbsStore', 'clientStore', 'userStore')(observer(UsersPage));
