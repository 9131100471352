import cn from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

import 'styles/titles.scss';


const TitleH2 = ({ children, count, active, color, showBadge, ico, href }) => {
    return (
        <h2 className={cn('title-h2')}>
            <div className={cn('ico', ico)} />
            {href ?
                <Link to={href} className={cn('title-h2__text')}>{children}</Link>
                :
                <span className={cn('title-h2__text')}>{children}</span>
            }
            {count > 0 && <span className={cn('title-h2__count', `title-h2__count_${color}`)}>{count}</span>}
            {showBadge && <span className={cn('badge', `badge_active-${active}`)}>{active ? 'Активен' : 'Не активен'}</span>}
            {href && <Link to={href} className={cn('ico', 'blank-link')} />}
        </h2>
    )
}

TitleH2.propTypes = {
    children: PropTypes.any,
    count: PropTypes.number,
    active: PropTypes.any,
    color: PropTypes.string,
    showBadge: PropTypes.any,
    ico: PropTypes.string,
    href: PropTypes.string
}

export default TitleH2;
