import { action, makeObservable, observable } from "mobx";

class BreadcrumbsStore {
    separator;

    breadcrumbs = [];

    constructor() {
        makeObservable(this, {
            breadcrumbs: observable,
            addItem: action,
        });
    }

    init = () => {
        this.breadcrumbs.length === 0 && this.restoreBreadcrumbs();
    };

    addItem = (elem, pos) => {
        this.breadcrumbs.splice(pos, this.breadcrumbs.length-pos);
        this.breadcrumbs.push(elem);
        this.rememberBreadcrumbs();
    }

    rememberBreadcrumbs = () => {
        localStorage.setItem('breadcrumbs', JSON.stringify(this.breadcrumbs));
    }

    restoreBreadcrumbs = () => {
        this.breadcrumbs = JSON.parse(localStorage.getItem('breadcrumbs')) || [];
    }

}

const breadcrumbsStore = new BreadcrumbsStore();
export default breadcrumbsStore;
