import moment from "moment";

/**
 *
 * @typedef {{
 * id:number,
 * lastname:string,
 * firstname:string,
 * email:string,
 * enabled:boolean,
 * createdAt:object,
 * updatedAt:object }} user
 *
 */
class User {
    #id;
    #lastname;
    #firstname;
    #email;
    #enabled;
    #createdAt;
    #updatedAt;

    constructor(administrator = {}) {
        this.#id = administrator.id || null;
        this.#lastname = administrator.lastname || '';
        this.#firstname = administrator.firstname || '';
        this.#email = administrator.email || '';
        this.#enabled = administrator.enabled || false;
        this.#createdAt = administrator.createdAt || null;
        this.#updatedAt = administrator.updatedAt || null;
    }

    set id(id) {
        this.#id = id;
    }

    get id() {
        return this.#id;
    }

    set lastname(lastname) {
        this.#lastname = lastname;
    }

    get lastname() {
        return this.#lastname;
    }

    set firstname(firstname) {
        this.#firstname = firstname;
    }

    get firstname() {
        return this.#firstname;
    }

    get fullName() {
        return `${this.#firstname} ${this.#lastname}`;
    }

    set email(email) {
        this.#email = email;
    }

    get email() {
        return this.#email;
    }

    set enabled(enabled) {
        this.#enabled = enabled;
    }

    get enabled() {
        return this.#enabled;
    }

    set createdAt(createdAt) {
        this.#createdAt = createdAt;
    }

    get createdAt() {
        return this.#createdAt ? moment(this.#createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    set updatedAt(updatedAt) {
        this.#updatedAt = updatedAt;
    }

    get updatedAt() {
        return this.#updatedAt ? moment(this.#updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    getObjectFromApi() {
        return {
            id: this.id,
            lastname: this.lastname,
            firstname: this.firstname,
            email: this.email,
            enabled: this.enabled,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        }
    }
}

export default User;
