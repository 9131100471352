import moment from "moment/moment";

class CompanyType {
    #code;
    #title;

    constructor(companyType) {
        this.#code = companyType.code || '';
        this.#title = companyType.title || '';
    }

    get code(){
        return this.#code;
    }

    get title() {
        return this.#title;
    }

    getPlainObject() {
        return {
            code: this.code,
            title: this.title,
        };
    }

}

class Company {
    #id;
    #clientId;
    #title;
    #type;
    #enabled;
    #createdAt;
    #updatedAt;


    constructor(company) {
        this.#id = company.id || null;
        this.#clientId = company.clientId || null;
        this.#title = company.title || '';
        this.#type = new CompanyType(company.type || {});
        this.#enabled = company.enabled || false;
        this.#createdAt = company.createdAt || null;
        this.#updatedAt = company.updatedAt || null;
    }

    /**
     *
     * @returns {int|null}
     */
    get id() {
        return this.#id;
    }

    /**
     *
     * @returns {int|null}
     */
    get clientId() {
        return this.#clientId;
    }

    /**
     *
     * @returns {int|string}
     */
    get title() {
        return this.#title;
    }

    /**
     *
     * @returns {CompanyType}
     */
    get type() {
        return this.#type;
    }

    /**
     *
     * @returns {boolean}
     */
    get enabled() {
        return this.#enabled;
    }

    /**
     * @return {string}
     */
    get createdAt() {
        return this.#createdAt ? moment(this.#createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @return {string}
     */
    get updatedAt() {
        return this.#updatedAt ? moment(this.#updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    static getEmptyEntity() {
        return new Company({});
    }

    getPlainObject() {
        return {
            id: this.id,
            clientId: this.clientId,
            type: this.type.getPlainObject(),
            title: this.title,
            enabled: this.enabled,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        };
    }

}

export default Company;